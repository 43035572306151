import React from "react";
import keyImage from "./password.png";

export default function ForgotPasswordInfo({ children }) {
  return (
    <>
      <img
        src={keyImage}
        alt="graphic of a classic key cut from middle"
        width="100%"
        height="auto"
      />
      <br />
      <p>
        Please enter your user id. We will send you OTP to your mobile to reset
        your password.
      </p>
    </>
  );
}
