import React from "react";
import styled from "styled-components";
import { formatters } from "../../../lib";
import { useDefaultCurrency } from "../../Common";
import { getTotals } from "./salesCalculator";

const { formatCurrency } = formatters;

const FooterElement = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
  span {
    display: inline;
    font-size: 16px;
  }
`;

function FooterRow({ label, children }) {
  return (
    <FooterElement>
      <span>{label}</span>
      <span>{children}</span>
    </FooterElement>
  );
}

export default function SalesSubTotalReadOnly({ values }) {
  const defaultCurrency = useDefaultCurrency();
  const {
    beforeDiscount: subTotal,
    productTaxes,
    invoiceTaxes,
    afterInvoiceTax: totalAmount,
    discountPercent,
    discountAmount,
  } = React.useMemo(() => getTotals(values), [values]);

  return (
    <>
      <FooterRow label="Sub Total">
        {formatCurrency(subTotal, defaultCurrency)}
      </FooterRow>
      <FooterRow label="Discount Amount">{discountAmount}</FooterRow>
      <FooterRow label="Discount Percent">{discountPercent}</FooterRow>
      {productTaxes.map((tax) => (
        <FooterRow
          key={tax.tax_id}
          label={`Product Level Tax (${tax.tax_name.toUpperCase()} ${
            tax.tax_percent
          }%)`}
        >
          {formatCurrency(tax.sum, defaultCurrency)}
        </FooterRow>
      ))}
      {invoiceTaxes.map((tax) => (
        <FooterRow
          key={tax.tax_id}
          label={`Invoice Level Tax (${tax.tax_name.toUpperCase()} ${
            tax.tax_percent
          }%)`}
        >
          {formatCurrency(tax.sum, defaultCurrency)}
        </FooterRow>
      ))}
      <FooterRow label={`Total Amount`}>
        {formatCurrency(totalAmount, defaultCurrency)}
      </FooterRow>
    </>
  );
}
