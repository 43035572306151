import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import GridToolbar from "../GridToolbar";
import Confirmation from "../../Common/Confirmation";
import useOpenClose from "../../Hooks/useOpenClose";
import BaseTable from "../../Common/BaseTable";

const tableDataMapper = (e) => ({ ...e, tableData: undefined });

/**
 * @typedef {import('../../Common/BaseTable').MaterialTableColumn} MaterialTableColumn
 */

/**
 * @typedef {Object} CrudGridProps
 * @property {boolean} isLoading
 * @property {string} title the title of the grid which also serves as key for the list query
 * @property {string} filters variables to pass to query function
 * @property {string} idKey string to get the property which is an a unqiue identifier for each row
 * @property {string} nameKey string to get the property that holds the name property of row
 * @property {Array<MaterialTableColumn>} columns valid material-table columns
 * @property {Function} listFunction an async function that returns data for the grid
 * @property {Function} deleteFunction an async function that accepts the rowId and deletes it
 * @property {Array<{text: string, onClick: Function, disabled: boolean}>} gridOptions extra options under button
 * @property {Array<Object>} toolbarProps extra options under button
 */

/**
 *
 * @param {CrudGridProps} props
 */
export default function CrudGrid(props) {
  const {
    title,
    idKey,
    nameKey,
    columns,
    action,
    isLoading,
    gridOptions,
    listFunction,
    deleteFunction,
    filters = {},
    toolbarProps = {},
    ...restProps
  } = props;

  const history = useHistory();
  const { state } = useLocation();
  const { url } = useRouteMatch();

  const [entity, setEntity] = useState({});
  const { handleClose, handleOpen, open } = useOpenClose();

  const handleToolbarAdd = useCallback(() => history.push(`${url}/add`), [
    history,
    url,
  ]);

  const { data, isFetching: dataLoading, refetch } = useQuery(
    [{ title, ...filters }],
    listFunction,
    { staleTime: 1000 * 60 * 5 } // 5 min
  );

  const [
    handleDelete,
    { isLoading: deleteLoading },
  ] = useMutation(deleteFunction, { onSuccess: () => refetch() });

  useEffect(() => {
    if (state && typeof state === "object" && state.refetchGrid) {
      refetch();
    }
  }, [state, refetch]);

  const gridLoading = dataLoading || deleteLoading || isLoading;

  const actions = useMemo(() => {
    const actionsArr = [
      {
        icon: () => <EditIcon color="action" fontSize="small" />,
        tooltip: "Edit",
        onClick: (event, rowData) =>
          history.push(`${url}/edit`, { editId: rowData[idKey], rowData }),
        position: "row",
      },
    ];
    if (typeof deleteFunction === "function") {
      actionsArr.push({
        icon: () => <DeleteIcon color="action" fontSize="small" />,
        tooltip: "Delete",
        onClick: (event, rowData) => {
          setEntity(rowData);
          handleOpen();
        },
        position: "row",
      });
    }
    return actionsArr;
  }, [idKey, history, url, deleteFunction, handleOpen]);

  const tableData = useMemo(
    () => (Array.isArray(data) ? data.map(tableDataMapper) : []),
    [data]
  );

  return (
    <>
      <Confirmation
        open={open}
        title="Confirm Deletion!"
        content={`Are you sure you want to delete ${entity[nameKey]}?`}
        onClose={handleClose}
        onConfirm={async () => {
          if (typeof handleDelete === "function") {
            handleDelete(entity[idKey]);
          }
          handleClose();
        }}
      />
      <BaseTable
        data={tableData}
        title={title}
        columns={columns}
        isLoading={gridLoading}
        components={{
          Toolbar: (props) => (
            <GridToolbar
              onAdd={handleToolbarAdd}
              extraOptions={gridOptions}
              {...props}
              {...toolbarProps}
            />
          ),
        }}
        actions={action ? null :actions}
        {...restProps}
      />
    </>
  );
}
