import React from "react";
import AuthHeading from "../Common/AuthHeading";

export default function OtpInfo() {
  return (
    <>
      <AuthHeading type="left">
        <span>OTP</span> Verification
      </AuthHeading>
      <p>
        A six digit code has been sent to your email/phone. Please enter the
        code to verify.
      </p>
      <br />
      <br />
    </>
  );
}
