import React from "react";
import { Redirect, matchPath } from "react-router-dom";
import useModule from "./useModule";
import useValidPaths from "./useValidPaths";
import useModuleScreens from "./useModuleScreens";
import ScreenNav from "../ScreenRouter/ScreenNav";
import ScreenList from "./ScreenList";
import ScreenRouter from "../ScreenRouter";
import PageLoader from "../../Common/PageLoader";
import ApiErrorAlert from "../../Common/ApiErrorAlert";
import Breadcrumb from "./Breadcrumb";
import NotFound from "../NotFound";

/**
 * @description
 * This component is responsible for rendering the module and its screens (if the user has
 * access to the module). If the module render_format is "Menu Screen" we need to show the
 * links for all the screen. If the module render_format is "Menu Tab" then we need to load
 * the first screen automatically and show a tab on top with all screen links for navigation
 */
export default function ModuleLanding() {
  const {
    pathname,
    activeModule,
    screenPath,
    showScreenLinks,
    reRouteToModule,
    reRouteToScreen,
  } = useModule();

  const moduleId = activeModule ? activeModule.module_id : 0;
  const { screens, status, error } = useModuleScreens(moduleId);
  const validPaths = useValidPaths({ currentModule: activeModule, screens });

  if (status === "loading") {
    return <PageLoader />;
  } else if (status === "error") {
    return <ApiErrorAlert error={error} loading={false} />;
  }

  if (
    !validPaths.some((e) =>
      matchPath(pathname, {
        path: e,
        exact: true,
      })
    )
  ) {
    return <NotFound />;
  }

  if (!activeModule) {
    return <Redirect to="/" />;
  } else if (reRouteToModule) {
    return <Redirect to={`/${activeModule.module_name}`} />;
  } else if (Array.isArray(screens) && screens.length > 0 && reRouteToScreen) {
    const defaultScreen =
      screens.find((e) => e.default_module_screen === 1) || screens[0];
    return <Redirect to={`/${defaultScreen.screen_link.toLowerCase()}`} />;
  }

  const screen = screens.find((e) => e.screen_link === screenPath);

  const crumbs = [];
  crumbs.push({
    name: activeModule.module_name,
    path: `/${activeModule.module_name}`,
  });
  if (screen) crumbs.push({ name: screen.screen_name });

  return (
    <>
      {activeModule.render_format === "Menu Screen" ? (
        <>
          <Breadcrumb crumbs={crumbs} />
          {showScreenLinks ? <ScreenList screens={screens} /> : null}
        </>
      ) : (
        <ScreenNav screens={screens} />
      )}
      {showScreenLinks ? null : <ScreenRouter screens={screens} />}
    </>
  );
}
