import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function PageLoader({ height = "100%" }) {
  return (
    <div style={{ display: "grid", placeItems: "center", height: height }}>
      <CircularProgress
        color="primary"
        size="3rem"
        aria-label="Page loading indicator"
      />
    </div>
  );
}
