export function setAuthHeaders(data) {
  localStorage.setItem("token", data.DB_Details[0]["v_token"]);
  localStorage.setItem("user_id", data.Sproc_OParams.oparam_user_id);
  localStorage.setItem("session_id", data.Sproc_OParams.oparam_session_id);
  localStorage.setItem("debug_sproc", data.Sproc_OParams.oparam_debug_sproc);
  localStorage.setItem("encrypt_db_pwd", data.DB_Details[0]["sql_password"]);
  localStorage.setItem("encrypt_db_user", data.DB_Details[0]["sql_user_id"]);
  localStorage.setItem("encrypt_db_host", data.DB_Details[0]["db_server"]);
  localStorage.setItem("student_entity_id", "0");
  localStorage.setItem(
    "audit_screen_visit",
    data.Sproc_OParams.oparam_audit_screen_visit
  );
  localStorage.setItem(
    "encrypt_db_database",
    data.DB_Details[0]["database_name"]
  );
}

export function clearAuthHeaders() {
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  localStorage.removeItem("session_id");
  localStorage.removeItem("debug_sproc");
  localStorage.removeItem("encrypt_db_pwd");
  localStorage.removeItem("encrypt_db_user");
  localStorage.removeItem("encrypt_db_host");
  localStorage.removeItem("student_entity_id");
  localStorage.removeItem("audit_screen_visit");
  localStorage.removeItem("encrypt_db_database");
}

export function setUser(oparams) {
  const user = {
    id: oparams.oparam_user_id,
    name: oparams.oparam_login_user_name,
    role: oparams.oparam_login_user_role,
    image: oparams.oparam_login_user_image_link,
    defaultModuleId: oparams.oparam_login_launch_module_id,
    defaultCurrency: oparams.oparam_default_currency,
  };
  localStorage.setItem("user", JSON.stringify(user));
  return user;
}

export function getUser() {
  const stringUser = localStorage.getItem("user");
  if (!stringUser) return null;
  else return JSON.parse(stringUser);
}

export function clearUser() {
  localStorage.removeItem("user");
  return null;
}

export function setModules(menuData) {
  const loweredMenuData = menuData.map((e) => ({
    ...e,
    module_name: e.module_name.toLowerCase(),
  }));
  localStorage.setItem("menu", JSON.stringify(loweredMenuData));
  return loweredMenuData;
}

export function getModules() {
  const stringMenu = localStorage.getItem("menu");
  if (!stringMenu) return [];
  else return JSON.parse(stringMenu);
}

export function clearModules() {
  localStorage.removeItem("menu");
  return [];
}

export function checkLoggedIn() {
  return (
    localStorage.getItem("token") !== null &&
    localStorage.getItem("user_id") !== null &&
    localStorage.getItem("session_id") !== null
  );
}
