import React from "react";
import { FMCheckbox } from "../../Form";

const numToContact = (num) => (typeof num === "string" ? ` (${num})` : "");

export default function AccountingEntityField({ sms, email, whatsapp }) {
  return (
    <>
      <FMCheckbox name="send_sms_flag" label={`Send SMS${numToContact(sms)}`} />
      <br />
      <FMCheckbox
        name="send_whatsapp_flag"
        label={`Send Whatsapp${numToContact(whatsapp)}`}
      />
      <br />
      <FMCheckbox
        name="send_email_flag"
        label={`Send Email${numToContact(email)}`}
      />
    </>
  );
}
