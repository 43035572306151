import React from "react";
import { setLocale } from "yup";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import theme from "./theme";

setLocale({
  mixed: {
    required: "This field is required",
  },
});

export default function MaterialUIConfig({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        iconVariant={{
          error: <ErrorIcon style={{ marginRight: 8 }} />,
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        maxSnack={3}
      >
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
}
