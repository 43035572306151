import React from "react";
import {
  Print,
  Header,
  Footer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  HeadCell,
  TableCell,
} from "../../Common/PDF";

import { formatters } from "../../../lib";

const { formatCurrency, formatDate } = formatters;

const typeMap = {
  Payable: {
    title: "Payment Voucher",
    bodyHeader: "Paid To",
    pdKey: "paid_by_person_details",
    pdName: "paid_by_name",
    pdDesignation: "paid_by_designation_name",
  },
  Receivable: {
    title: "Payment Receipt",
    bodyHeader: "Received From",
    pdKey: "received_person_details",
    pdName: "received_by_name",
    pdDesignation: "received_by_designation_name",
  },
};

export default function PrintVoucher({ data }) {
  const footerObj = {};

  data.footer_data.forEach((e) => {
    footerObj[e.label] = {
      text: e.label_value_text || "",
      number: e.label_value_numeric || 0,
    };
  });

  const terms = footerObj["Terms"].text;

  const subTotal = footerObj["Sub Total"];
  const subTotalAmount = formatCurrency(subTotal.number, subTotal.text);

  const total = footerObj["Total Amount"];
  const totalAmount = formatCurrency(total.number, total.text);

  const headerData = Array.isArray(data.header_data) ? data.header_data[0] : {};

  const batch_type = headerData.supplier_name ? "Payable" : "Receivable";

  const { city_name, address1, address2 } = headerData;

  const { title, bodyHeader, pdKey, pdName, pdDesignation } =
    typeMap[batch_type] || {};

  const entityName = headerData.client_name || headerData.supplier_name;
  const name = data[pdKey]?.[0]?.[pdName];
  const designation = data[pdKey]?.[0]?.[pdDesignation];

  return (
    <Print.Page>
      <Header logo={data.logo_path} title={title} />

      <Print.Body>
        <Print.BodyHeader>{bodyHeader} :</Print.BodyHeader>
        <Print.InfoWrap>
          {/* Header Left */}
          <Print.InfoSection>
            <Print.InfoName>{entityName}</Print.InfoName>
            <Print.InfoGrey>{address1}</Print.InfoGrey>
            <Print.InfoGrey>
              {address2 && city_name
                ? `${address2}, ${city_name}`
                : address2 || city_name}
            </Print.InfoGrey>
          </Print.InfoSection>

          {/* Header Right */}
          <Print.InfoSection width="35%">
            <Print.InfoWrap>
              <div style={{ marginBottom: 6 }}>Receipt Number:</div>
              <Print.InfoGrey>{headerData.receipt_number}</Print.InfoGrey>
            </Print.InfoWrap>
            <Print.InfoWrap>
              <div style={{ marginBottom: 6 }}>Paid Date:</div>
              <Print.InfoGrey>
                {formatDate(headerData.payment_date)}
              </Print.InfoGrey>
            </Print.InfoWrap>
          </Print.InfoSection>
        </Print.InfoWrap>

        <Table>
          <TableHead>
            <TableRow>
              <HeadCell width="60px">SL.</HeadCell>
              <HeadCell width="350px">For Payment of:</HeadCell>
              <HeadCell width="200px">Amount</HeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.body_data.map((e) => (
              <TableRow key={e.sequence_number}>
                <TableCell width="50px">{e.sequence_number}</TableCell>
                <TableCell width="350px">{e.invoice_number}</TableCell>
                <TableCell width="200px">
                  {formatCurrency(e.paid_amount, e.default_currency_symbol)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Print.InfoWrap alignItems="flex-start">
          {/* Terms and Payment Details */}
          <Print.InfoSection width="45%">
            <Print.InfoParaHeading>
              Thank you for your business
            </Print.InfoParaHeading>
            <br />
            <Print.InfoParaHeading>Terms & Conditions</Print.InfoParaHeading>
            <Print.InfoParaText>{terms}</Print.InfoParaText>
            <br />
            <Print.InfoParaHeading>
              {batch_type === "Payable" ? "Paid" : "Received"} By:
            </Print.InfoParaHeading>
            <Print.InfoWrap justifyContent="flex-start">
              <Print.InfoParaText width="120px">Name:</Print.InfoParaText>
              <Print.InfoParaText>{name}</Print.InfoParaText>
            </Print.InfoWrap>
            <Print.InfoWrap justifyContent="flex-start">
              <Print.InfoParaText width="120px">
                Designation:
              </Print.InfoParaText>
              <Print.InfoParaText>{designation}</Print.InfoParaText>
            </Print.InfoWrap>
          </Print.InfoSection>

          {/* Sub Totals */}
          <Print.InfoSection width="40%">
            <Print.InfoWrap>
              <div style={{ marginBottom: 6 }}>Sub Total:</div>
              <Print.InfoGrey>{subTotalAmount}</Print.InfoGrey>
            </Print.InfoWrap>
            <br />
            <Print.InfoTotal>
              <Print.InfoWrap>
                <div>Paid Amount</div>
                <div>{totalAmount}</div>
              </Print.InfoWrap>
            </Print.InfoTotal>
          </Print.InfoSection>
        </Print.InfoWrap>
      </Print.Body>
      <br />
      <br />
      <Footer orgData={data.organization_address[0]} />
    </Print.Page>
  );
}
