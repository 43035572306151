import React from "react";
import {
  Header,
  Footer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  HeadCell,
  Print,
} from "../../Common/PDF";
import { getFooterSide, formatCurrency, printInvoiceMappers } from "./helpers";

function mapFooterLabels(footerData = []) {
  const footerObj = {};
  footerData.forEach(
    (e) =>
      (footerObj[e.label] = `${
        e.label_value_numeric ? `${e.label_value_numeric} ` : ""
      }${e.label_value_text}`)
  );
  return footerObj;
}

export default function PrintInvoice({ data }) {
  const footerObj = mapFooterLabels(data.footer_data);
  const terms = footerObj["Terms"];
  const accName = footerObj["Account Name"];
  const accNumber = footerObj["Account Number"];
  const bank = footerObj["Bank Name"];
  const headerData = Array.isArray(data.header_data) ? data.header_data[0] : {};
console.log("header data"+JSON.stringify(headerData))
  const entity_type = headerData?.entity_type;

  const { title, mapper } = printInvoiceMappers[entity_type];

  // Header right
  const headerRight = mapper(headerData);

  // Extract footer data and format
  // Filering out the total amount so that it can be shown blue background */
  const footerRight = getFooterSide(data.footer_data, "right");
  const totalAmount = footerRight.find((e) => e.left === "Total Amount");
  const otherAmounts = footerRight.filter((e) => e.left !== "Total Amount");

  return (
    <Print.Page pdfFont="'Courier New', Courier, monospace">
      <Header logo={data.logo_path} title={title} />

      <Print.Body>
        <Print.BodyHeader>{`${title} to:`}</Print.BodyHeader>
        <Print.InfoWrap>
          {/* Header Left */}
          <Print.InfoSection>
            <Print.InfoName>{headerData.client_name}</Print.InfoName>
            <Print.InfoGrey>{headerData.address1}</Print.InfoGrey>
            <Print.InfoGrey>
              {headerData.address2}
              {headerData.city_name && `, ${headerData.city_name}`}
            </Print.InfoGrey>
          </Print.InfoSection>

          {/* Header Right */}
          <Print.InfoSection width="40%">
            {headerRight.map((header, i) => (
              <Print.InfoWrap key={`${header.left}${header.right}`}>
                <div style={{ marginBottom: 6 }}>{header.left}</div>
                <Print.InfoGrey>{header.right}</Print.InfoGrey>
              </Print.InfoWrap>
            ))}
          </Print.InfoSection>
        </Print.InfoWrap>

        <Table>
          <TableHead>
            <TableRow>
              <HeadCell width="45px">SL.</HeadCell>
              <HeadCell width="165px">Item Description</HeadCell>
              <HeadCell width="120px">Price</HeadCell>
              <HeadCell width="85px">Discount</HeadCell>
              <HeadCell width="85px" align="center">
                Quantity
              </HeadCell>
              <HeadCell width="120px" align="center">
                Total
              </HeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.body_data.map((e) => (
              <TableRow key={e.sequence_number}>
                <TableCell width="50px">{e.sequence_number}</TableCell>
                <TableCell width="170px">{e.item_name}</TableCell>
                <TableCell width="120px">
                  {formatCurrency(e.unit_price, e.default_currency_symbol)}
                </TableCell>
                <TableCell width="90px">
                  {formatCurrency(e.discount_amount, e.default_currency_symbol)}
                </TableCell>
                <TableCell width="60px" align="center">
                  {e.quantity}
                </TableCell>
                <TableCell width="120px" align="center">
                  {formatCurrency(e.total_amount, e.default_currency_symbol)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Print.InfoWrap alignItems="flex-start">
          {/* Terms and Payment Details */}
          <Print.InfoSection width="45%">
            <Print.InfoParaHeading>
              Thank you for your business
            </Print.InfoParaHeading>
            <Print.InfoParaHeading>Terms & Conditions</Print.InfoParaHeading>
            <Print.InfoParaText>{terms}</Print.InfoParaText>
            <br />
            <Print.InfoParaHeading>Payment Info:</Print.InfoParaHeading>
            <Print.InfoWrap justifyContent="flex-start">
              <Print.InfoParaText width="120px">Account #:</Print.InfoParaText>
              <Print.InfoParaText>{accNumber}</Print.InfoParaText>
            </Print.InfoWrap>
            <Print.InfoWrap justifyContent="flex-start">
              <Print.InfoParaText width="120px">A/C Name:</Print.InfoParaText>
              <Print.InfoParaText>{accName}</Print.InfoParaText>
            </Print.InfoWrap>
            <Print.InfoWrap justifyContent="flex-start">
              <Print.InfoParaText width="120px">
                Bank Details:
              </Print.InfoParaText>
              <Print.InfoParaText>{bank}</Print.InfoParaText>
            </Print.InfoWrap>
          </Print.InfoSection>

          {/* Sub Totals */}
          <Print.InfoSection width="45%">
            {otherAmounts.map((e, i) => (
              <Print.InfoWrap key={i}>
                <div style={{ marginBottom: 6 }}>{e.left}</div>
                <Print.InfoGrey>{e.right}</Print.InfoGrey>
              </Print.InfoWrap>
            ))}
            <br />
            <Print.InfoTotal>
              <Print.InfoWrap>
                <div>{totalAmount.left}</div>
                <div>{totalAmount.right}</div>
              </Print.InfoWrap>
            </Print.InfoTotal>
          </Print.InfoSection>
        </Print.InfoWrap>
      </Print.Body>
      <br />
      <br />
      <Footer orgData={data.organization_address[0]} />
    </Print.Page>
  );
}
