import React from "react";
import { makeStyles, fade } from "@material-ui/core";
import MaterialTable from "material-table";
import tableIcons from "./tableIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableHead-root": {
      borderTop: `solid 1px ${theme.palette.background.default}`,
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
    },
    "& tr.MuiTableRow-root": {
      "&:hover": {
        backgroundColor: (props) =>
          props.dataExists ? fade(theme.palette.primary.main, 0.15) : "none",
        "& td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone button": {
          visibility: "visible",
        },
      },
    },
    "& td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone button": {
      visibility: "hidden",
    },

    "& thead th span.MuiButtonBase-root.MuiIconButton-root.MuiCheckbox-colorSecondary": {
      color: theme.palette.common.black,
      "&:hover": {
        backgroundColor: fade(theme.palette.common.black, 0.04),
      },
    },
  },
}));

/**
 * @callback RenderFunction
 * @param {Object} rowData
 * @returns {Object} valid react child
 *
 *
 * @typedef {Object} MaterialTableColumn
 * @property {String} title header title of the column
 * @property {String} field key of the to show inside the table
 * @property {number} width width of column
 * @property {RenderFunction} render function to render a custom component
 *
 */

export default function BaseTable({ data, columns, options, ...restProps }) {
  const dataExists = Array.isArray(data) && data.length > 0;
  const classes = useStyles({ dataExists });

  return (
    <div className={classes.root}>
      <MaterialTable
        data={data}
        columns={columns}
        style={{ borderRadius: 0, boxShadow: "none" }}
        icons={tableIcons}
        options={{
          draggable: false,
          grouping: false,
          pageSize: 10,
          thirdSortClick: false,
          addRowPosition: "first",
          actionsColumnIndex: -1,
          selectionProps: {
            color: "primary",
          },
          ...options,
        }}
        {...restProps}
      />
    </div>
  );
}
