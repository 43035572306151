import { useState, useCallback } from "react";

export default function useOpenClose(initialState = false) {
  const [open, setOpen] = useState(initialState);

  const handleOpen = useCallback(() => setOpen(true), [setOpen]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const handleToggle = useCallback(() => setOpen((prevOpen) => !prevOpen), [
    setOpen,
  ]);

  return {
    open,
    handleOpen,
    handleClose,
    handleToggle,
  };
}
