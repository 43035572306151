import React from "react";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";

export default function Notifications() {
  const notificationCount = 11;

  return (
    <Tooltip title="Notifications">
      <IconButton
        aria-label={`show ${notificationCount} new notifications`}
        color="inherit"
      >
        <Badge badgeContent={notificationCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
