import * as Paths from "../../Routers/Paths";

export const EntityPaths = {
  Employee: Paths.Employee,
  Client: Paths.Client,
  Supplier: Paths.Supplier,
  Lead: Paths.Lead,
  Student: Paths.Student,
  Product: Paths.Product,
  ProductType: Paths.Product_Type,
  Vehicle: Paths.Vehicle,
  "Purchase Invoice": Paths.Purchase_Invoice,
  Quote: Paths.Quote,
  "Sales Invoice": Paths.Sales_Invoice,
  Service: Paths.Service,
  "Service - Car Wash": Paths.Car_Wash,
  "Service - Auto Repair": Paths.Auto_Repair,
  CRM: Paths.Tickets,
  "Delivery Order": Paths.Delivery_Order,
};
