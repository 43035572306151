import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { getLookupType } from "../Form/lookupMap";
import axios3 from "../../lib/axios3";

export async function fetchSingleLookup(
  key,
  path,
  { dataItemKey, textField, nameAsValue }
) {
  const { data } = await axios3.get(`/api/lkp/${path}`);
  return data.data0.map((e) => ({
    ...e,
    label: e[textField],
    value: nameAsValue ? e[textField] : e[dataItemKey],
  }));
}

function noop() {}

export default function useLookup(
  lookupType,
  nameAsValue = false,
  onLookupLoad = noop
) {
  const { lookupPath, dataItemKey, textField } = getLookupType(lookupType);

  const { data, isSuccess, ...queryState } = useQuery(
    ["lookup", lookupPath, { dataItemKey, textField, nameAsValue }],
    fetchSingleLookup,
    { staleTime: 1000 * 60 * 5 } // 5 min
  );

  const lookup = useMemo(() => (Array.isArray(data) ? data : []), [data]);

  useEffect(() => {
    if (isSuccess) {
      onLookupLoad(lookup);
    }
  }, [lookup, isSuccess, onLookupLoad]);

  return {
    lookup,
    dataItemKey: nameAsValue ? textField : dataItemKey,
    textField,
    isSuccess,
    ...queryState,
  };
}
