import moment from "moment";

const formats = {
  date: "DD MMM, YYYY",
  dateTime: "DD MMM, YYYY HH:mm",
  dateTimeAmPm: "DD MMM, YYYY hh:mm a",
  timeDateAmPm: "hh:mm a, DD MMM, YYYY",
  standard: "YYYY-MM-DD",
};

/**
 *
 * @param {string} date - the date to format must be a valid web format
 * @param {"date" | "dateTime" | "dateTimeAmPm" | "timeDateAmPm" | "standard"} format
 */
export function formatDate(date, format = "date") {
  const momentDate = moment(date);
  if (momentDate.isValid()) return momentDate.format(formats[format]);
  else return "Invalid Date";
}

/**
 *
 * @param {string} value the amount
 * @param {string} currency the currency associated with the amount
 * @returns {string} amount formatted with 2 decimal places and currency appended
 */
export function formatCurrency(value = 0, currency = "") {
  const trimmed = typeof currency === "string" ? `${currency.trim()} ` : "";
  return `${trimmed}${(parseFloat(value) || 0).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
}

/**
 *
 * @param {string} value the amount
 * @param {string} currency the currency associated with the amount
 * @returns {string} amount formatted with 2 decimal places and currency appended
 */
export function formatNumber(value = 0) {
  return `${(parseFloat(value) || 0).toLocaleString("en-US")}`;
}

export function formatPhone(code, phone, config = { delimiter: "" }) {
  return code && phone ? `+${code}${config.delimiter}${phone}` : "";
}

export function capitalize(str = "") {
  return `${str.charAt(0).toUpperCase()}${str.substr(1).toLowerCase()}`;
}

export function underscoreToTitle(str = "") {
  return str
    .split("_")
    .map((e) => capitalize(e))
    .join(" ");
}

/**
 *
 * @param {number} num duration to subtract to find start of range
 * @param {"years" | "months" | "days" | "weeks"} durationType type of duration e.g year
 * @param {"date" | "dateTime" | "dateTimeAmPm" | "timeDateAmPm" | "standard"} dateFormat date format to use
 * @returns [start_date, end_date]
 */
export function getDateRange(num, durationType, dateFormat = "standard") {
  return [
    moment().subtract(num, durationType).format(formats[dateFormat]),
    moment().format(formats[dateFormat]),
  ];
}
