import React from "react";
import { FMTextField, FMCheckbox } from "../../Form";
import { AllEntitiesDropdown } from "../../Common";
import AllVehicleDropdown from "../../Common/AllVehicleDropdown";
import { FiltersBox, FiltersDialog } from "../../Grids";
import { formatters, getDateRange } from "../../../lib";

const { formatDate } = formatters;

const [start_date, end_date] = getDateRange(6, "months");


export default function SalesFilter({ title, gridFilters,...filterDialogProps}) {
  // const [name, setName] = React.useState(start_date);
  // const handleChange = (event) => {
  //   setName(event.target.value);
  //   gridFilters.start_date=name
  // };
  // console.log("griddddd>>>"+JSON.stringify(gridFilters))
  return (
    <FiltersDialog title={`${title} Grid Filters`} {...filterDialogProps} >
      <FiltersBox>
        <AllEntitiesDropdown
          disableClearable={false}
          name="subject_entity_id"
          label="Subject"
        />
        {title === "Car Wash" || title ===  "Auto Repair"?<AllVehicleDropdown
          disableClearable={false}
          name="vehicle_entity_id"
          label="Vehicle"
          lookupType="vehicle"
        />:null}
        <FMTextField name="start_date" label="Start Date" type="date" />
        <FMTextField name="end_date" label="End Date" type="date" />
        {title === "Quote"?null:<div style={{float:"right"}}>
          <FMCheckbox
            name="show_full_paid"
            label={`Include Paid`}
          />
        </div>}
      </FiltersBox>
    </FiltersDialog>
  );
}
