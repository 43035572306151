import * as Yup from "yup";
import { axios } from "../../../lib";

export const PaymentSchema = Yup.object().shape({
  checked: Yup.boolean(),
  current_balance: Yup.number(),
  paid_amount: Yup.number().when("checked", {
    is: true,
    then: Yup.number()
      .required("Please enter an amount")
      .moreThan(0, "Amount must be greater than 0")
      .max(Yup.ref("current_balance"), "Amount cannot be greater than balance"),
    otherwise: Yup.number(),
  }),
});

export const PRSchema = Yup.object().shape({
  send_sms_flag: Yup.boolean(),
  send_whatsapp_flag: Yup.boolean(),
  send_email_flag: Yup.boolean(),
  payments: Yup.array()
    .of(PaymentSchema)
    .test({
      message: "Please select at least 1 payment",
      test: (arr) => arr.filter((e) => e.checked === true).length > 0,
    }),
  payment_type: Yup.string(),
  payment_type_id: Yup.number().required("Please select a payment type"),
  check_dd_number: Yup.string().defined("Please enter check number"),
  check_dd_date: Yup.string().defined("Please enter check date"),
  deposited_to_bank_account_entity_id: Yup.number().defined(
    "Please select a bank account"
  ),
});

export const addInitialValues = {
  sms: "",
  email: "",
  whatsapp: "",
  send_sms_flag: false,
  send_whatsapp_flag: false,
  send_email_flag: false,
  payments: [],
  payment_type: "",
  payment_type_id: "",
  check_dd_number: null,
  check_dd_date: null,
  deposited_to_bank_account_entity_id: null,
  paid_from_bank_account_entity_id: null,
  type: "",
};

const pathMap = {
  receivable: "/api/batch-receivable-payment",
  payable: "/api/batch-payable-payment",
};

export async function insertBatchPR(values) {
  const path = pathMap[values.type.toLowerCase()];

  if (!path)
    throw new Error(
      `Please provide a valid type in form values. Allowed Types: ${Object(
        pathMap
      ).keys()}`
    );

  const { payments } = values;
  const filteredPayments = payments.filter(
    (payment) => payment.checked === true
  );
  const total_paid_amount = filteredPayments.reduce(
    (a, b) => a + b.paid_amount,
    0
  );

  const mapped = {
    ...values,
    total_paid_amount: total_paid_amount,
    actIdPaidAmount_json: JSON.stringify(
      filteredPayments.map((payment) => ({
        act_entity_id: payment.act_entity_id,
        paid_amount: payment.paid_amount,
      }))
    ),
  };

  const { data } = await axios.post(path, mapped);

  return data;
}
