import React from "react";
import { Grid } from "@material-ui/core";

export default function FormGridItem({ xs = 12, md = 6, lg = 4, children }) {
  return (
    <Grid item xs={xs} md={md} lg={lg}>
      {children}
    </Grid>
  );
}
