import { useScreenId } from "../Routers";
import useGridOptions from "./useGridOptions";

export default function useCustomScreenOptions(customOptions = []) {
  const screen_id = useScreenId();
  const { rawOptions, isLoading } = useGridOptions(screen_id);

  const mappedOptions = rawOptions.map((rawOpt) => {
    const customOption = customOptions.find(
      (customOpt) => customOpt.id === rawOpt.option_key
    );

    if (customOption) {
      return {
        id: rawOpt.option_key,
        text: rawOpt.option_name,
        onClick: customOption.onClick,
        disabled: customOption.disabled,
      };
    } else {
      return null;
    }
  });

  return {
    options: mappedOptions.filter((e) => e !== null),
    isLoadingOptions: isLoading,
  };
}
