import Axios from "axios";
import {getModuleAPI} from "../components/Routers/ModuleLanding/useModuleScreens"

const axios = Axios.create({
  //baseURL: getModuleAPI
});

function getAuthHeaders() {
  return {
    screen_id: localStorage.getItem("screen_id") || 100002,
    token: localStorage.getItem("token"),
    user_id: localStorage.getItem("user_id"),
    session_id: localStorage.getItem("session_id"),
    debug_sproc: localStorage.getItem("debug_sproc"),
    encrypt_db_pwd: localStorage.getItem("encrypt_db_pwd"),
    encrypt_db_user: localStorage.getItem("encrypt_db_user"),
    encrypt_db_host: localStorage.getItem("encrypt_db_host"),
    student_entity_id: localStorage.getItem("student_entity_id"),
    audit_screen_visit: localStorage.getItem("audit_screen_visit"),
    encrypt_db_database: localStorage.getItem("encrypt_db_database"),
  };
}

// Common authentication header interceptor
axios.interceptors.request.use(async function (config) {
  const authHeaders = getAuthHeaders();
  // config.baseURL = "https://0gmekfbhu5.execute-api.ap-south-1.amazonaws.com/prod";
  config.baseURL = "https://devapi-auth.tansycloud.com";
  // config.baseURL = "http://127.0.0.1:5000/";
  Object.entries(authHeaders).forEach(([key, value]) => {
    if (value) config.headers[key] = value;
  });
  return config;
});

export default axios;
