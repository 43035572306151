import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    fontSize: 20,
    margin: (props) => theme.spacing(...props.margin),
  },
}));

export default function FormHeading({ children, margin = [3, 0, 1, 0] }) {
  const classes = useStyles({
    margin: Array.isArray(margin) ? margin : [margin],
  });
  return (
    <Typography variant="h2" color="primary" className={classes.root}>
      {children}
    </Typography>
  );
}
