import React from "react";
import { Redirect } from "react-router-dom";
import { makeStyles, fade, Grid } from "@material-ui/core";
import loginBg from "./loginBg.jpg";
import download from "./download.png";
import loginBrand from "./loginBrand.png";
import tansyLogo from "./tansyLogo.png";
import { useAuthState } from "../Context/AuthContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    fontSize: "1.2rem",
    minHeight: "100vh",
    placeItems: "center",
    backgroundSize: "cover",
    backgroundImage: `url(${loginBg})`,
    backgroundPosition: "10%",
    backgroundAttachment: "fixed",
    "&::after": {
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 2,
      content: "' '",
      position: "fixed",
      backgroundColor: fade(theme.palette.primary.main, 0.3),
    },
    [theme.breakpoints.down("sm")]: {
     
      backgroundSize: "cover",
      backgroundImage: `url(${download})`,
      backgroundPosition: "10%",
      backgroundAttachment: "fixed",
      "&::after": {
       
        backgroundColor: 'white',
      },
     
    },
    [theme.breakpoints.down("md")]: {
      backgroundSize: "cover",
      backgroundImage: `url(${download})`,
      backgroundPosition: "10%",
      backgroundAttachment: "fixed",
      "&::after": {
       
        backgroundColor: 'whitw',
      },
     
    },

  },
  section: {
    zIndex: 3,
    position: "relative",
  },
  grid: {
    maxWidth: "920px",
    minHeight: "521px",
    backgroundSize: "contain",
    backgroundImage: `url(${loginBrand})`,
    backgroundRepeat: "no-repeat",
    padding: theme.spacing(2, 1),
    [theme.breakpoints.down("sm")]: {
      minHeight:'full',
      padding: theme.spacing(0),
      backgroundImage: "none",
     
    },
  },

  leftGrid: {
    borderRadius: "2rem 0 0 2rem",
    color: theme.palette.common.white,
    lineHeight: 1.7,
    padding: theme.spacing(0, 8),
    minWidth: "445px",
    [theme.breakpoints.down("sm")]: {
      display:'none',
      minWidth: "0px",
      borderRadius: "0",
      padding: theme.spacing(0, 4),
      background: theme.palette.primary.main,
    },
  },
  rightGrid: {
    minWidth: "445px",
    borderRadius: "0 2rem 2rem 0",
    padding: theme.spacing(0, 8),
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      
      minWidth: "45px",
      height:'100%',
      borderRadius: "2rem",
      paddingTop:'40px',
      paddingLeft:'10px',
      paddingRight:'10px',
      paddingBottom:'0px',
      background: '#FFFFFF80',
      marginLeft:'10px',
      marginRight:'10px'
    },
    [theme.breakpoints.down("md")]: {
      
      minWidth: "348px",
      height:'100%',
      borderRadius: "2rem",
      paddingTop:'40px',
      paddingLeft:'20px',
      paddingRight:'20px',
      paddingBottom:'0px',
      background: '#FFFFFF80',
      marginLeft:'10px',
      marginRight:'10px'
    },
  },
  logo: {
    
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(4),
    [theme.breakpoints.down("sm")]:{
      display:'none'
    }
  },
}));

export default function AuthWrap({ Left, Right, alignItems = "center" }) {
  const classes = useStyles();
  const { isLoggedIn } = useAuthState();

  if (isLoggedIn) return <Redirect to="/" />;

  return (
    <main className={classes.root}>
      <section className={classes.section}>
        <Grid
          className={classes.grid}
          container
          alignItems={alignItems}
          justify="center"
        >
          <Grid className={classes.leftGrid} item md={6} xs={12}>
            {Left}
          </Grid>
          <Grid className={classes.rightGrid} item sm={4} md={6} xs={12}>
            {Right}
          </Grid>
        </Grid>
        <div className={classes.logo}>
          <img
            height={60}
            width="auto"
            src={tansyLogo}
            alt="tansy cloud logo"
          />
        </div>
      </section>
    </main>
  );
}
