import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useMutation } from "react-query";
import { Box, Button } from "@material-ui/core";
import { useHistory, useLocation, Redirect } from "react-router-dom";
import ReplayIcon from "@material-ui/icons/Replay";
import AuthHeading from "../Common/AuthHeading";
import TansyButton from "../../Common/TansyButton";
import { FMTextField } from "../../Form";
import { forgotPassword, validateOtp } from "../Common/authQueries";
import ApiErrorAlert from "../../Common/ApiErrorAlert";

const ForgotPasswordSchema = Yup.object().shape({
  otp: Yup.string()
    .required("Please enter a six digit code")
    .length(6, "The otp must be of 6 characters"),
});

export default function OtpForm() {
  const { state } = useLocation();
  const { oparams, domain_name } = state || {
    oparams: null,
    domain_name: null,
  };
  const history = useHistory();

  const [resend] = useMutation(forgotPassword);
  const [validate, { error, isLoading }] = useMutation(validateOtp, {
    onSuccess: () => {
      history.push(`/reset-password`, { otpValidated: true, oparams });
    },
    onSettled: () => null,
  });

  if (!oparams || !parseInt(oparams.user_id)) {
    return <Redirect to="/forgot-password" />;
  }

  const numericUserId = oparams.user_id;

  return (
    <>
      <AuthHeading>Enter Code</AuthHeading>
      <Formik
        initialValues={{ user_id: numericUserId, otp: "" }}
        onSubmit={(values) =>
          validate({ user_id: values.user_id, otp: values.otp, oparams })
        }
        validationSchema={ForgotPasswordSchema}
      >
        {(fkProps) => (
          <Form>
            <Box>
              <ApiErrorAlert error={error} loading={isLoading} />
            </Box>
            <Box my={2}>
              <FMTextField
                size="small"
                name="otp"
                label="Enter OTP"
                variant="outlined"
              />
            </Box>
            <Box
              my={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TansyButton
                disabled={fkProps.isSubmitting}
                margin={0}
                type="submit"
              >
                Verify
              </TansyButton>
              <Button
                startIcon={<ReplayIcon />}
                color="primary"
                size="small"
                variant="text"
                onClick={() => resend({ domain_name })}
              >
                Resend code
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
