import { useQuery } from "react-query";
import axios3 from "../../lib/axios3";
import { getLookupType } from "../Form/lookupMap";

export async function fetchChildLookup(key, path, headers = {}) {
  const { data } = await axios3.get(`/api/lkp/${path}`, { headers });
  return data;
}

export default function useChildLookup({
  lookupType,
  headers = {},
  enabled = false,
}) {
  const { lookupPath, dataItemKey, textField } = getLookupType(lookupType);

  const { data, ...queryState } = useQuery(
    ["lookup", lookupPath, headers],
    fetchChildLookup,
    {
      staleTime: 1000 * 60, // 1 min
      enabled: enabled,
    }
  );
  const lookup =
    data && Array.isArray(data.data0)
      ? data.data0.map((e) => ({
          ...e,
          label: e[textField],
          value: e[dataItemKey],
        }))
      : [];

  return { lookup, dataItemKey, textField, ...queryState };
}
