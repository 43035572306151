import React from "react";
import { FastField } from "formik";
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    display: "block",
  },
  helperText: {
    color: theme.palette.error.main,
  },
}));

export default function FMFastCheckbox(props) {
  const classes = useStyles();

  return (
    <FastField name={props.name}>
      {({ field, meta, form }) => {
        const errorText = meta.error && meta.touched ? meta.error : " ";

        const handleChange = (e) =>
          form.setFieldValue(props.name, e.target.checked);

        return (
          <>
            <FormControlLabel
              labelPlacement="end"
              control={
                <Checkbox
                  name={field.name}
                  color="primary"
                  checked={!!field.value}
                  onChange={handleChange}
                />
              }
              {...props}
            />
            {!!errorText.trim() ? (
              <FormHelperText className={classes.helperText}>
                {errorText}
              </FormHelperText>
            ) : null}
          </>
        );
      }}
    </FastField>
  );
}
