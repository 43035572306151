import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import CopyrightIcon from "@material-ui/icons/Copyright";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

const useStyles = makeStyles((theme) => ({
  footer: {
    zIndex: theme.zIndex.drawer + 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 3),
    color: "rgba(0, 0, 0, 0.6);",
    fontWeight: 500,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box display="flex" alignItems="flex-end">
        <CopyrightIcon />
        &nbsp;&nbsp;
        <span>{new Date().getFullYear()} Tansy Cloud</span>
      </Box>
      <Box display="flex" alignItems="flex-end">
        <LinkedInIcon />
        &nbsp; &nbsp;
        <TwitterIcon />
      </Box>
    </footer>
  );
}
