import { formatters } from "../../../lib";

const { formatDate } = formatters;

export function quoteUpdate(values) {
  const invoice_header_json = JSON.stringify(getInvoiceHeader(values));
  return {




    invoice_type: "Quote",
    invoice_number: values.invoice_number,
    client_entity_id: values.client_entity_id,
    sales_person_entity_id: values.sales_person_entity_id,
    invoice_header_json: invoice_header_json,
    ...getProductsAndTax(values),
    ...getCommunicationFlags(values),
    project_entity_id: values.project_entity_id || null,
    description: values?.description || '',
  };
}

export function getInvoiceHeader(values) {
  const invoice_header = [
    {
      ...values.non_related_fields,
      ...values,
      header_row_id: 1,
      posting_date_id: 0,
      act_invoice_recurring_id: 0,
      client_entity_id: values.client_entity_id,
      invoice_number: values.invoice_number,
      description: values.description || null,
      payment_terms_id: values.payment_terms_id || null,
      invoice_date: formatDate(values.invoice_date, "standard"),
      invoice_due_date: formatDate(new Date(), "standard"),
      quote_expiry_date: formatDate(values.quote_expiry_date, "standard"),
    },
  ];
  return invoice_header;
}

export function getServiceJSON(values) {
  if (!values.service_type_id) return null;

  return JSON.stringify([
    {
      header_row_id: 1,
      completion_date: values.completion_date,
      service_type_id: values.service_type_id,
      service_status_id: values.service_status_id,
      agent_entity_id: values.service_agent_entity_id,
      vehicle_entity_id: values.vehicle_entity_id || 0,
      odometer: values.odometer || 0,
      parent_act_entity_id: 0,
    },
  ]);
}

export function getCommunicationFlags(values) {
  return {
    send_sms_flag: !!values.send_sms_flag ? 1 : 0,
    send_email_flag: !!values.send_email_flag ? 1 : 0,
    send_whatsapp_flag: !!values.send_whatsapp_flag ? 1 : 0,
  };
}

export function getProductsAndTax(values) {
  const products = [];
  const products_tax = [];

  values.products.forEach((p, i) => {
    products.push({
      header_row_id: 1,
      product_entity_id: p.product_entity_id,
      description:p.description,
      product_sequence: i + 1,
      units: p.units,
      rate: p.unitPrice,
    });
    const tax = p.tax.map((e) => ({
      header_row_id: 1,
      product_entity_id: p.product_entity_id,
      tax_id: e.tax_id,
    }));
    products_tax.push(...tax);
  });

  const invoice_level_tax = values.invoiceLevelTax.map((e) => ({
    header_row_id: 1,
    tax_id: e.tax_id,
  }));

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const insert_discount_percent_value = formatter.format(
    values.discountPercent
  );

  return {
    products_json: JSON.stringify(products),
    products_tax_json: JSON.stringify(products_tax),
    invoice_level_tax_json: JSON.stringify(invoice_level_tax),
    discount_amount: parseFloat(values.discountAmount),
    discount_percent: parseFloat(insert_discount_percent_value),
  };
}

export function getSalesInsertValues(values) {
  const invoice_header_json = JSON.stringify(getInvoiceHeader(values));

  return {
    invoice_type: values.invoice_type,
    invoice_header_json: invoice_header_json,
    ...getProductsAndTax(values),
    ...getCommunicationFlags(values),
    full_paid_flag: values.full_paid_flag ? 1 : 0,
    payment_type_id: null,
  };
}

export function getSalesUpdateValues(values) {
  const invHeader = getInvoiceHeader(values);
  return {
    invoice_header_json: JSON.stringify(invHeader),
    invoice_type: "Sales Invoice",
    description: values.description || null,
    send_email_flag: values?.send_email_flag || false,
    send_sms_flag:  values?.send_sms_flag || false,
    send_whatsapp_flag:  values?.send_whatsapp_flag || false
  };
}
