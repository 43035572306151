import { useQuery } from "react-query";
import { axios3 } from "../../../lib";


export default function useFiltersLookup(screen_id) {
  const { data, ...queryState } = axios3.get("/api/lkp/grid-filters-with-field-values", {
    headers: {  screen_id:  screen_id },
    staleTime: 1000 * 60 * 5 

  });
  // useQuery(
  //   ["/api/lkp/grid-filters-with-field-values", { screen_id }],
  //   { staleTime: 1000 * 60 * 5 }
  // );

  const lookup = Array.isArray(data)
    ? data.map(({ filter_name, filter_fields_values }) => {
        return {
          filter_name,
          value: filter_name,
          label: filter_name,
          filter_values: JSON.parse(filter_fields_values)[0],
        };
      })
    : [];

  return {
    lookup: lookup,
    textField: "filter_name",
    dataItemKey: "filter_name",
    ...queryState,
  };
}
