import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    display: "block",
    "& .MuiFilledInput-input": {
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiFilledInput-root": {
      backgroundColor: theme.palette.background.default,
    },
  },
  helperText: {
    marginLeft: 2,
    marginRight: 0,
  },
}));

export default function FMTextField(props) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const classes = useStyles();

  // Don't want null or undefined to be passed
  // Since this field will always be controlled
  // passing null or undefined would raise warnings
  const passedValue = props.value || field.value;
  const finalValue =
    passedValue === null || passedValue === undefined ? "" : passedValue;

  const InputLabelProps = {
    shrink: props.type && props.type === "date" ? true : undefined,
  };

  return (
    <TextField
      FormHelperTextProps={{ className: classes.helperText }}
      className={classes.root}
      error={!!errorText.trim()}
      helperText={errorText}
      fullWidth
      {...field}
      {...props}
      InputLabelProps={{ ...InputLabelProps, ...props.InputLabelProps }}
      value={finalValue}
    />
  );
}
