import React from "react";
import { PDFExport } from "@progress/kendo-react-pdf";

/**
 *
 * @param {Object} props
 * @param {Object} props.printRef
 * @param {Object} props.linkRef
 * @param {string} props.linkData
 * @param {Object} props.config
 * @param {string} props.config.paperSize
 * @param {string} props.config.fileName
 * @param {number} props.config.scale
 * @param {boolean} props.config.repeatHeaders
 * @param {{top: number, bottom: number, left: number, right: number}} props.config.margin
 */
export default function InvisiblePrintWrap({
  linkData,
  printRef,
  linkRef,
  config,
  children,
}) {
  return (
    <div style={{ position: "absolute", left: "-1900px", top: 0 }}>
      <a
        ref={linkRef}
        style={{ display: "none" }}
        href={linkData}
        target="_blank"
        rel="noopener noreferrer"
      >
        File download link
      </a>
      <PDFExport ref={printRef} {...config}>
        {children}
      </PDFExport>
    </div>
  );
}
