import React from "react";
import {
  makeStyles,
  darken,
  Tooltip,
  Typography,
  Box,
} from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 1, 1, 1),
    backgroundColor: (props) => props.backgroundColor,
    color: (props) => props.color,
    "&:hover": {
      backgroundColor: (props) => darken(props.backgroundColor, 0.05),
    },
  },
}));

export default function AddButton({
  label = "",
  title = "Add",
  size = "small",
  onClick,
  disabled,
  Icon = AddIcon,
  backgroundColor = "#fad534",
  color = "#000000",
  ...restProps
}) {
  const classes = useStyles({ backgroundColor, color });

  const labelDiv = label ? (
    <Typography color="primary" variant="caption">
      {label}
    </Typography>
  ) : null;

  const button = (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Fab
        className={classes.root}
        size={size}
        aria-label="add"
        onClick={onClick}
        disabled={disabled}
        {...restProps}
      >
        <Icon />
      </Fab>
      {labelDiv}
    </Box>
  );

  return (
    <Tooltip title={title}>{disabled ? <div>{button}</div> : button}</Tooltip>
  );
}
