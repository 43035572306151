import useFilters from "./useFilters";
import useOpenClose from "../../Hooks/useOpenClose";

export default function useFiltersModal(key, initialValues, map = {}) {
  const { open, handleOpen, handleClose } = useOpenClose();
  const {
    filters,
    gridFilters,
    isFiltered,
    fetch,
    handleFilterReset,
    handleFilterChange,
  } = useFilters(key, initialValues, map);

  return {
    gridFilters,
    filterButtonProps: {
      isFiltered,
      onFilter: handleOpen,
    },
    filterDialogProps: {
      isFilterOpen: open,
      filterValues: filters,
      isFetched:fetch,
      onFilterClose: handleClose,
      onFilterReset: handleFilterReset,
      onFilterSubmit: handleFilterChange,
    },
    
  };
}
