export const TicketsDashboard = "/dashboard/tickets";
export const BusinessDashboard = "/dashboard/business";
export const CRMDashboard = "/dashboard/crm";
export const ClientDashboard = "/dashboard/client";
export const RewardDashboard = "/dashboard/rewards";
export const SalesDashboard = "/dashboard/sales";
export const PurchaseDashboard = "/dashboard/purchase";
export const OrderDashboard = "/dashboard/order";
export const ServiceDashboard = "/dashboard/service";
export const ECommerceDashboard = "/dashboard/ecommerce";

export const User = "/admin/user";
export const Entity = "/admin/entity";
export const Bulk_Load = "/admin/bulk-load";
export const Map_Entity = "/admin/map-entity";
export const Bulk_Update = "/admin/bulk-update";
export const Fiscal_Year = "/admin/fiscal-year"; 
export const Reminder ="/admin/internal-team-reminder";
export const CommunicationAccess ="/admin/communication-access";

export const Tax = "/settings/tax";
export const City = "/settings/city";
export const Bank = "/settings/bank";
export const Lookups = "/settings/lookups";
export const Accounts = "/settings/accounts";
export const SMS_Template = "/settings/sms-template";
export const Organization = "/settings/organization";
export const Custom_Fields = "/settings/custom-fields";
export const Dynamic_Grids = "/settings/dynamic-grids";
export const WorkflowSettings = "/settings/workflow-setup";
export const Email_Template = "/settings/email-template";
export const Service_Status = "/settings/service-status";
export const Whatsapp_Template = "/settings/whatsapp-template";
export const Product_Attribute_Mapping = "/settings/product-attribute-mapping";
export const Entity_type = "/settings/entity-lookup";
export const Invoice_Order_Status = "/settings/invoice-order-status";
export const Bulk_Update_Fields = "/settings/bulk-update-fields";
export const InvoicePrefix= "/settings/invoice-prefix";
export const FacilityDelivery= "/settings/facility-delivery";

export const Product_Type = "/inventory/product-type";
export const Purchase_Invoice = "/inventory/purchase-invoice";
export const Client_Product_Pricing= "/inventory/client-product-pricing";
export const Bulk_Stock = "/inventory/bulk-stock";
export const PurchasePriceCompare = "/inventory/purchase-price-compare";

export const Quote = "/pre-sales/quote";
export const Customer_order = "/pre-sales/customer-order";

export const Service = "/sales/service";
export const Car_Wash = "/sales/car-wash";
export const Sales_Invoice = "/sales/sales-invoice";
export const Auto_Repair = "/sales/auto-repair";
export const Post_Invoice = "/sales/post-invoice";
export const Recurring_Invoice = "/sales/recurring-invoice";
export const Invoice_Template = "/sales/invoice-template";
export const Delivery_Order = "/sales/delivery-order";
export const Delivery_Invoice = "/sales/delivery-invoice";
export const Wallet = "/sales/wallet";

export const Sales_Listing = "/post-sales/sales-listing";
export const Product_Offer = "/post-sales/product-offer";
export const Service_Reminder = "/post-sales/service-reminder";
export const Invoice_Adjustment = "/post-sales/invoice-adjustment";
export const Payment_reminder = "/post-sales/payment-reminder";

export const Attendance = "/hr/attendance";
export const Monthly_Log = "/hr/monthly-log";

export const Tickets = "/crm/ticket";
export const Workflow = "/crm/workflow";

export const Lead = "/organization/lead";
export const Client = "/organization/client";
export const Vehicle = "/organization/vehicle";
export const Product = "/organization/product";
export const Employee = "/organization/employee";
export const Supplier = "/organization/supplier";
export const Tracking = "/organization/tracking";
export const Agent = "/organization/agent";
export const Contacts = "/organization/contacts";
export const Product_Pricing="/organization/product-pricing";
export const NGO_Membership="/organization/ngo-membership";
export const Patient="/organization/patient";
export const Doctor="/organization/doctor";
export const Appointmemt="/organization/appointment";



export const Send_Message = "/communication/send-message";
export const Fee_Reminder = "/communication/fee-reminder";
export const Student_Attendance = "/communication/student-attendance";
export const Exam_Schedule = "/communication/exam-schedule";
export const Exam_Result1  = "/communication/communication";

export const Expense = "/accounting/expense";
export const Payable = "/accounting/payable";
export const Receivable = "/accounting/receivable";
export const Bank_Transaction = "/accounting/bank-transactions";
export const Payable_Due = "/accounting/payable-due";
export const Receivable_Due = "/accounting/receivable-due";
export const Transactions = "/accounting/account-transactions";
export const BankCashTransaction = "/accounting/bank-cash-transactions";
export const EMI= "/accounting/emi";
export const GenerateInvoice="/accounting/generate-invoice";
export const GenerateSoa="/accounting/generate-soa";
export const NGOCollection="/accounting/ngo-collection";

export const File_Upload = "/document/upload-documents";
export const Cloud_Documents = "/document/cloud-documents";
export const File_Upload1 = "/document/system-documents";

export const Subject = "/school/subject";
export const School_Class = "/school/class";
export const ClassSchedule="/school/class-schedule"
export const Teacher_Mapping = "/school/teacher-mapping";
export const Subject_Topics = "/school/subject-topics";

export const Exam = "/exam/exam";
export const Schedule = "/exam/schedule";
export const Mark_Sheet = "/exam/mark-sheet";
export const Hall_Ticket = "/exam/hall-ticket";
export const Exam_Result = "/exam/exam-result";
export const Progress_Report_Version = "/exam/progress-report-version";
export const Student_Remark = "/exam/student-remark";

export const Student = "/student/student";
export const Admission = "/student/admission";
export const Student_Listing = "/student/student-listing";
export const Move_student = "/student/move-student";
export const Subject_Performance = "/student/subject-performance";
export const Assignment = "/student/assignment";

export const product_stock = "/inventory/product-stock";
export const audit_log = "/admin/audit-log";
export const screen_permission = "/settings/screen-permissions";
export const leave_management = "/hr/leave-management";
export const ot_management = "/hr/ot-management";
export const loan_management = "/hr/loan-management";
export const post_salary = "/hr/post-salary";
export const project = "/admin/project";
export const expense_listing = "/accounting/expense-listing";
export const holidays = "/admin/holidays";
export const salaryTax = "/settings/salary-tax";
export const WorkflowType = "/settings/workflow-type";
export const productReview = "/online/product-review";
export const testimonial = "/online/testimonial";
export const syncProcess = "/admin/sync-process";
export const invoiceProductSearch = "/post-sales/invoice-product-search";
export const generateSoaBatch = "/admin/generate-soa";
export const facility = "/organization/facility";

export const offer="/rewards/offer";
export const Rewards="/rewards/rewards";


export const TransportEnquiry='/transport/transport-enquiry';
export const TransportQuote='/transport/transport-quote';
export const TransportRequest='/transport/transport-request';
export const TransportService='/transport/transport-service';
export const VehicleSchedule='/transport/vehicle-schedule';
export const PriceList='/transport/price-list';

export const MyQueue="/profile/my-queue";

export const CollectionsManagement="/ngo/collections-management";
export const PrayerTimings="/ngo/prayer-times";
export const DuaRequest="/ngo/dua-request";
export const Membership="/ngo/membership";
export const Service2="/ngo/services";
export const Articles="/ngo/articles";
export const Events="/ngo/events";
export const Annoucements="/ngo/announcements";
export const LocalBusiness="/ngo/local-business";
export const AcademicPrograms="/ngo/academic-programs";

export const DeliveryRate="/order management/delivery-rate";
export const OrderStatus="/order management/order-status";
export const Order="/order management/order";
export const CartItems="/order management/cart-items";

export const Outpatient="/outpatient/outpatient";

export const TableOrder="/restaurant/table-order";

export const DailyStandup="/pmo/daily-standup";
export const TraineeProgram="/pmo/trainee-program";
export const DailyAchievement="/pmo/daily-achievement";

export const Testimonials="/consumer app/testimonials"
export const EmailSubscription="/consumer app/email-subscription"
export const ContactUs="/consumer app/contact-us"

