import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  authLink: {
    fontSize: 16,
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function AuthLink({ to, text }) {
  const classes = useStyles();

  return (
    <Box
      my={2}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <Link to={to} className={classes.authLink}>
        {text}
      </Link>
    </Box>
  );
}
