import React from "react";
import { FiltersBox, FiltersDialog } from "../../Grids";
import { FMAutoComplete, FMCheckbox, FMTextField } from "../../Form";

export default function AccountingFilter({
  title,
  flags = true,
  ...filterDialogProps
}) {
  return (
    <FiltersDialog title={`${title} Grid Filters`} {...filterDialogProps}>
      <FiltersBox>
        <FMTextField name="start_date" label="Start Date" type="date" />
        <FMTextField name="end_date" label="End Date" type="date" />
        <FMAutoComplete
          name="client_entity_id"
          label="Client"
          lookupType="clientWithCommunication"
          disableClearable={false}
          convertValueToId={true}
        />
        <FMAutoComplete
          name="supplier_entity_id"
          label="Supplier"
          lookupType="supplierWithCommunication"
          disableClearable={false}
          convertValueToId={true}
        />
        {flags ? (
          <>
            <FMCheckbox
              label="Receivable Transactions"
              name="receivable_transaction_flag"
            />
            <FMCheckbox
              label="Payable Transactions"
              name="payable_transaction_flag"
            />
            <FMCheckbox
              label="Both Transactions"
              name="both_transaction_flag"
            />
          </>
        ) : null}
      </FiltersBox>
    </FiltersDialog>
  );
}
