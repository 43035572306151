import React from "react";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core";
import { getApiError } from "../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
}));

export default function ApiErrorAlert({ error, loading }) {
  const classes = useStyles();

  if (!error || loading) return null;

  const errorMessage = getApiError(error);

  return (
    <Alert className={classes.root} severity="error">
      {errorMessage}
    </Alert>
  );
}
