import React from "react";
import * as Print from "./PrintElements";

export default function Header({ logo, title }) {
  return (
    <>
      <Print.Container>
        <Print.Logo
          src={logo}
          alt="Company logo on top of invoice"
          crossOrigin="anonymous"
        />
      </Print.Container>

      <Print.Heading>
        <Print.Container>
          <Print.HeadingText>{title}</Print.HeadingText>
        </Print.Container>
      </Print.Heading>
    </>
  );
}
