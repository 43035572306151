import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 400,
    fontSize: 24,
    display: "none",
    margin: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

export default function Branding() {
  const classes = useStyles();

  return (
    <Typography className={classes.title} variant="h1" noWrap>
      Tansy Cloud
    </Typography>
  );
}
