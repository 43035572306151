import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./components/Auth/Login";
import Otp from "./components/Auth/Otp";
import ForgotPassword from "./components/Auth/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword";
import Layout from "./components/Layout";

export default function MainSwitch() {
  return (
    <Switch>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/otp">
        <Otp />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/">
        <Layout />
      </Route>
    </Switch>
  );
}
