import * as Yup from "yup";
import { groupBy } from "lodash";
import { useMutation, useQuery } from "react-query";
import { axios } from "../../../lib";
import {
  ComponentMap,
  CUSTOM_FIELD_NAME_PREFIX,
  DataTypesMap,
  insertCustomFields,
  isDropdown,
  isTextField,
} from "./customFieldsQueries";

async function getCustomFields(path, { screen_id, entity_id }) {
  const { data } = await axios.get(path, { headers: { screen_id, entity_id } });

  const values = data?.data1?.[0] || {};
  const lookups = groupBy(data.data2, "custom_field_id");

  const getFieldName = (field) =>
    `${CUSTOM_FIELD_NAME_PREFIX}${field.custom_field_id}`;

  const fieldMapper = (field) => {
    const {
      ui_label: label,
      custom_field_id,
      input_type,
      data_type,
      db_column_name,
    } = field;

    const [Component, preDefinedProps] = ComponentMap[input_type];

    const type = isTextField(input_type)
      ? DataTypesMap[data_type] || "text"
      : undefined;

    const options = isDropdown(input_type)
      ? lookups[custom_field_id].map((lkp) => ({
          label: lkp.lookup_list_text,
          value: lkp.lookup_list_text,
        })) || []
      : undefined;

    let value = values[db_column_name] === "null"?"":values[db_column_name] || "";

    if (input_type === "Mulit Select Drop Down") {
      value =
        value.length === 0
          ? []
          : value.split(",").map((e) => ({ label: e, value: e }));
    } else if (input_type === "Checkbox") {
      value = Boolean(Number(value));
    } else if (type === "number") {
      value = Number(value);
    }

    const name = getFieldName(field);
    const required = Boolean(field.mandatory_input);

    return {
      label,
      name,
      value,
      type,
      options,
      Component,
      preDefinedProps,
      required,
    };
  };

  const fieldsWithValues = data.data0
    .sort((a, b) => (a.order_sequence || 0) - (b.order_sequence || 0))
    .map(fieldMapper);

  const mandatoryFields = fieldsWithValues.filter((e) => e.required);

  let Schema = Yup.object().shape({
    abc: Yup.string(),
  });
  const initialValues = {};

  mandatoryFields.forEach(({ name, value }) => {
    if (Array.isArray(value)) {
      Schema = Schema.shape({
        [name]: Yup.array()
          .of(Yup.mixed())
          .test({
            message: "Please select a value",
            test: (arr) => arr.length > 0,
          }),
      });
    } else {
      Schema = Schema.shape({
        [name]: Yup.mixed().required(),
      });
    }
  });

  fieldsWithValues.forEach(({ name, value }) => {
    initialValues[name] = value;
  });

  const fields = fieldsWithValues.map(({ value, required, ...e }) => ({
    ...e,
  }));

  return { fields, initialValues, Schema };
}

export default function useCustomFields(screen_id, entity_id) {
  const { data, isFetching, refetch } = useQuery(
    ["/api/custom-fields-apply", { screen_id, entity_id }],
    getCustomFields,
    { staleTime: 1000 * 60 * 5 }
  );

  const [insertFields, { isLoading }] = useMutation(insertCustomFields, {
    onSuccess: refetch,
  });

  const { fields, Schema, initialValues } = data || {
    fields: [],
    Schema: null,
    initialValues: {},
  };

  return {
    isLoading: isLoading || isFetching,
    insertFields,
    fields,
    Schema,
    initialValues,
    CUSTOM_FIELD_NAME_PREFIX,
  };
}
