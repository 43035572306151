import React from "react";
import AuthHeading from "../Common/AuthHeading";

export default function ResetPasswordInfo() {
  return (
    <>
      <AuthHeading type="left">
        <span>New</span> Password
      </AuthHeading>
      <p>
        Enter new password to reset your password and login to your account.
      </p>
      <br />
      <br />
      <br />
    </>
  );
}
