const screenPathList = {
  "/document/cloud-documents":["entityname","category"],
  "/admin/project":["view"],
  "/student/student":["view"],
  "/restaurant/table-order":["reservation"],
  "/school/class-schedule":["view"],
  "/communication/communication":["receiver"],
  "/rewards/rewards":["products","customers"],
  "/ngo/dua-request":["view"],
  "/organization/appointment":["history"],
  "/ngo/membership":["view"],
  "/ngo/services":["view"],
  "/ngo/academic-programs":["view"],
  "/ngo/prayer-times":["view"],
  "/crm/workflow":["view2"],
  "/accounting/expense":["view"],
  "/accounting/account-transactions":["view"],
  "/sales/recurring-invoice": ["add-subject", "delete-subject"],
  "/inventory/product-stock": ["add-product-adjustment", "adjustment-audit-report"],
  "/settings/organization": ["organization-info", "reports", 
  "web-about-us", "web-about-us/add", "web-about-us/view", "web-about-us/edit",
  "web-faq","web-faq/add","web-faq/edit","web-faq/view",
  "microservices", "quote", "communication", "subscription", "aws-s3","web-consent","web-consent/add","web-consent/edit","web-consent/view",
   "general","invoice","micro-service", "payment", "privacy", "service", "shipping",
    "web-theme", "web-theme/edit","web-theme/add","web-theme/view", "web-privacy", "web-privacy/add",
     "web-privacy/edit", "web-privacy/view", "web-service-highlights",
      "web-caption", "web-caption/add", "web-caption/edit","web-caption/view",
      "web-history", "web-history/add", "web-history/edit","web-history/view",
      "web-goals", "web-goals/add", "web-goals/edit","web-goals/view",
      "web-ourservices", "web-ourservices/add", "web-ourservices/edit","web-ourservices/view",
      "web-service-highlights/add", "web-service-highlights/edit","web-service-highlights/view", "return-policy",
      "vehicle-type", "vehicle-type/add", "vehicle-type/edit","vehicle-type/view",
      "communication-provider", "communication-provider/add", "communication-provider/edit","communication-provider/view"
      ,"search-price-range", "search-price-range/add", "search-price-range/edit","search-price-range/view"
      ,"sort-by", "sort-by/add", "sort-by/edit","sort-by/view",
      ,"profile-tabs", "profile-tabs/add", "profile-tabs/edit","profile-tabs/view","testimonials",
      ,"social-profile", "social-profile/add", "social-profile/edit","social-profile/view",
      ,"web-domain", "web-domain/add", "web-domain/edit","web-domain/view",
      ,"web-payment", "web-payment/add", "web-payment/edit","web-payment/view",
      ,"web-shipping", "web-shipping/add", "web-shipping/edit","web-shipping/view",
      ,"latest-updates", "latest-updates/add", "latest-updates/edit","latest-updates/view",
      ,"products-categories", "products-categories/add", "products-categories/edit","products-categories/view",
      ,"products-filters", "products-filters/add", "products-filters/edit","products-filters/view",
      "map-products", "map-products/add", "map-products/edit","map-products/view",
      ,"return-policy-info", "return-policy-info/add", "return-policy-info/edit","return-policy-info/view",
    ],
    "/organization/product":["product","product/add","product/edit","product/view",
                            "product-pricing","product-pricing/add",
                            "product-attribute-mapping","product-attribute-mapping/add","product-attribute-mapping/edit",
                            "product-attribute","product-attribute/add","product-attribute/edit","product-attribute/view",
                            "attribute-group","attribute-group/add","attribute-group/edit","attribute-group/view",
                            "attribute-map","attribute-map/add","attribute-map/edit","attribute-map/view",
                            "product-type","product-type/add","product-type/edit","product-type/view",
                          ],
  "/student/assignment": ["subject","student"],
};

export default function useValidPaths({ currentModule, screens }) {
  const validPaths = ["/", "/global-search"];

  if (currentModule) {
    validPaths.push(`/${currentModule.module_name.toLowerCase()}`);
  }

  screens.forEach((s) => {
    const baseScreen = `/${s.screen_link.toLowerCase()}`;
    validPaths.push(baseScreen);
    validPaths.push(`${baseScreen}/add`);
    validPaths.push(`${baseScreen}/edit`);
    validPaths.push(`${baseScreen}/view`);

    // If there are some other paths except add and edit push them to valid paths
    if (screenPathList[baseScreen]) {
      screenPathList[baseScreen].forEach((e) =>
        validPaths.push(`${baseScreen}/${e}`)
      );
    }
  });

  return validPaths;
}
