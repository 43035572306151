import React from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    background: (props) =>
      props.active
        ? theme.palette.primary.main
        : theme.palette.background.paper,
    color: (props) =>
      props.active
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: "16px",
    textAlign: "center",
    fontWeight: 500,
    textDecoration: "none",
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(0, 2),
    "&:hover": {
      background: (props) =>
        props.active
          ? theme.palette.primary.main
          : theme.palette.background.default,
    },
  },
}));

export default function ScreenNavLink({ screen }) {
  const { pathname } = useLocation();
  const screenLink = `/${screen.screen_link.toLowerCase()}`;
  const active = pathname.includes(screenLink);
  const classes = useStyles({ active });

  return (
    <Link className={classes.root} to={screenLink}>
      {screen.screen_name}
    </Link>
  );
}
