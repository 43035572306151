import React from "react";
import { Formik } from "formik";
import TansyDialog from "./TansyDialog";
import LinearLoader from "./LinearLoader";
import TansyButton from "./TansyButton";

export default function TansyDialogForm({
  open = false,
  title = "",
  isLoading = false,
  children,
  handleClose,
  handleSubmit,
  initialValues,
  validationSchema,
  addButtonText = "Add",
  cancelButtonText = "Cancel",
  resetOnClose = true,
  resetOnSubmit = true,
  closeOnSubmit = true,
  enableReinitialize = true,
}) {
  const handleFormSubmit = async (values, helpers) => {
    await handleSubmit(values);
    if (resetOnSubmit) helpers.resetForm();
    if (closeOnSubmit) handleClose();
  };

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(fkProps) => {
        const handleFormClose = () => {
          handleClose();
          if (resetOnClose) fkProps.resetForm();
        };

        return (
          <TansyDialog
            fullWidth
            open={open}
            scroll="body"
            maxWidth="sm"
            onClose={handleFormClose}
            title={title}
            actions={
              <>
                <TansyButton onClick={handleFormClose} variant="text">
                  {cancelButtonText}
                </TansyButton>
                <TansyButton
                  onClick={fkProps.handleSubmit}
                  disabled={fkProps.isSubmitting || isLoading}
                >
                  {addButtonText}
                </TansyButton>
              </>
            }
          >
            <LinearLoader loading={fkProps.isSubmitting || isLoading} />
            {typeof children === "function" ? children(fkProps) : children}
          </TansyDialog>
        );
      }}
    </Formik>
  );
}
