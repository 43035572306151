import moment from "moment";
import { axios } from "../../../lib";

export const transactionsInitialFilters = {
  start_date: moment().subtract(2, "months").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  client_entity_id: null,
  supplier_entity_id: null,
  receivable_transaction_flag: true,
  payable_transaction_flag: false,
  both_transaction_flag: false,
  facility_entity_ids:[]
};

export async function getVoucherDetails({ batch_id, batch_type }) {
  const path = batch_type === "Payable" ? "voucher" : "receipt";
  const { data } = await axios.get(`/api/rpt/payment-${path}`, {
    headers: { batch_id },
  });
  return data;
}
