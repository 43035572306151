import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import ScreenLink from "./ScreenLink";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 4),
    background: theme.palette.common.white,
    flexGrow: 1,
  },
}));

export default function ScreenList({ screens = [] }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid aria-label="List of screens to navigate to" container>
        {screens.map((screen) => (
          <Grid key={screen.screen_id} item xl={2} lg={2} md={4} xs={6}>
            <ScreenLink screen={screen} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
