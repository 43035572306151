import React from "react";
import { makeStyles } from "@material-ui/core";
import ScreenNavLink from "./ScreenNavLink";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2),
    boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.15)",
    background: theme.palette.background.paper,
    listStyle: "none",
    margin: 0,
    overflow: "auto",
    whiteSpace: "nowrap",
  },
  listItem: {
    display: "inline-block",
  },
}));

export default function ScreenNav({ screens }) {
  const classes = useStyles();

  return (
    <nav>
      <ul className={classes.root}>
        {screens.map((screen) => (
          <li key={screen.screen_id} className={classes.listItem}>
            <ScreenNavLink screen={screen} />
          </li>
        ))}
      </ul>
    </nav>
  );
}
