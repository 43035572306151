import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3367D6",
    },
    background: {
      default: "#F2F2F2",
    },
    success: {
      main: "#6FCF97",
    },
    error: {
      main: "#D97070",
    },
  },
  shape: {
    borderRadius: "8px",
  },
});

export default theme;
