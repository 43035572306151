import React from "react";
// import usePrint from "./usePrint";
import usePrint from "components/Sales/DeliveryOrder/DeliveryOrderPDF/usePrint"
import { getSalesReport } from "../Sales/SalesCommon";
import PrintInvoice from "../Sales/SalesPDF/PrintInvoice";
import { InvisiblePrintWrap } from "../Common";

const invoicePdfOptions = [
  "export-pdf-quote",
  "export-pdf-invoice",
  "export-pdf-service",
  "export-pdf-purchase_invoice",
  "export-pdf-enquiry"
];

export default function useInvoicePdfOptions(options = [], entityId, fileName) {
  const {
    printRef,
    printData,
    linkRef,
    linkData,
    isLoading,
    fetchReport,
  } = usePrint(getSalesReport);

  const pdfOptions = options
    .filter((e) => invoicePdfOptions.includes(e.option_key))
    .map((e) => {
      const { option_key, option_name } = e;
      return {
        id: option_key,
        text: option_name,
        onClick: () => fetchReport({ entityId }),
        // disabled: !entityId,
      };
    });

  const Component = () => (
    <InvisiblePrintWrap
      printRef={printRef}
      linkRef={linkRef}
      linkData={linkData}
      config={{
        paperSize: "A4",
        scale: 0.7,
        fileName,
        margin: { bottom: 20, top: 0, left: 0, right: 0 },
      }}
    >
      {printData ? <PrintInvoice data={printData} /> : null}
    </InvisiblePrintWrap>
  );

  return {
    invoicePdfOptions: pdfOptions,
    Component: Component,
    isLoading,
  };
}
