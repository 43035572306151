import React from "react";
import { FMAutoComplete } from "../../Form";

export default function ProductSelectWithTax({
  disabled,
  name,
  onChange,
  ...restProps
}) {
  const handleChange = (event, value, lookup) => {
    const product = lookup.find(
      (e) => e.product_entity_id === value.product_entity_id
    );
    const tax = Array.isArray(product?.tax) ? product.tax : [];
    const unit_rate = product?.unit_rate ? product.unit_rate : 0;
    onChange({ ...product, unit_rate, tax });
  };

  return (
    <FMAutoComplete
      disableClearable
      disabled={disabled}
      name={name}
      onChange={handleChange}
      lookupType="productWithTax"
      convertValueToId={true}
      {...restProps}
    />
  );
}
