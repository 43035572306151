import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { useScreenId } from "../../Routers";
import useCustomFields from "./useCustomFields";

export default function useCustomFieldsGridForm({
  gridPath,
  deleteFunction,
  entityIdKey,
}) {
  const history = useHistory();

  // When we are editing, we will always have editId in
  // react-router-dom's location.state
  const entityId = useLocation().state?.editId || 0;
  const screenId = useScreenId();

  const [handleFail, { isLoading: isDeleting }] = useMutation(deleteFunction);

  const {
    insertFields,
    fields,
    Schema,
    initialValues,
    isLoading,
  } = useCustomFields(screenId, entityId);

  const handleSuccess = (data, variables) => {
    const entity_id = entityId > 0 ? entityId : data.api_oparams?.[entityIdKey];
    const wasInserted = entityId <= 0;
    insertFields(
      { entity_id, formValues: variables },
      {
        onSuccess: () => history.push(gridPath, { refetchGrid: true }),
        onError: () => (wasInserted ? handleFail(entity_id) : null),
      }
    );
  };

  return {
    fields,
    Schema,
    initialValues,
    handleSuccess,
    isLoading: isDeleting || isLoading,
  };
}
