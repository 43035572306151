import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path d="M22.406 14.67c-1.419.087-3.285 1.506-5.083 2.32-2.206.999-5.64.196-5.64.196.528-.248 2.681-.762 3.115-.952 2.304-1.013 2.11-3.127 1.012-3.109-1.45.023-2.3.38-5.194.774-2.193.3-4.786.19-6.03.665C2.829 15.234 0 19.747 0 19.747l4.374 4.238s2.708-2.667 4.024-2.667c3.002 0 3.122-.04 5.91-.19 1.185-.065 1.433-.112 2.11-.342 3.613-1.222 7.492-4.475 7.564-4.865.166-.907-.825-1.297-1.576-1.251zM1.594 9.315c1.419-.087 3.285-1.506 5.084-2.32 2.205-.999 5.638-.196 5.639-.196-.528.248-2.681.761-3.115.952-2.304 1.012-2.11 3.126-1.013 3.108 1.451-.023 2.302-.38 5.195-.773 2.193-.3 4.786-.19 6.03-.665C21.171 8.75 24 4.238 24 4.238L19.626 0s-2.707 2.667-4.024 2.667c-3.002 0-3.122.04-5.91.19-1.185.065-1.433.112-2.11.342C3.969 4.42.09 7.673.018 8.063c-.166.908.825 1.297 1.576 1.252z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
