import styled from "styled-components";

export const Page = styled.div`
  padding: 40px 0;
  background-color: white;
  max-width: 230mm;
  margin: 0 auto;
  margin-left: -8px;
  font-family: ${(props) => props.pdfFont || "Arial"};
`;

export const Container = styled.div`
  max-width: 86%;
  margin: 0 auto;
`;

export const Logo = styled.img`
  width: 180px;
  height: auto;
  margin-bottom: 15px;
`;

export const Heading = styled.h1`
  margin: 0px;
  background-color: #215ca0;
  font-size: 32px;
  text-transform: uppercase;
  text-align: right;
  padding-right: 20px;
`;

export const HeadingText = styled.span`
  color: black;
  background-color: white;
  display: inline-block;
  font-size: inherit;
  padding: 0 4px;
`;

export const Body = styled(Container)`
  padding: 15px 0;
  letter-spacing: 0.4px;
`;

export const BodyHeader = styled.div`
  font-weight: bold;
  font-size: 2rem;
`;

export const InfoWrap = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
`;

export const InfoSection = styled.div`
  width: ${(props) => props.width || "40%"};
  font-size: 1rem;
  font-weight: bold;
`;

export const InfoName = styled.div`
  font-size: 1.8rem;
`;

export const InfoGrey = styled.div`
  color: #a2a2a2;
`;

export const InfoParaHeading = styled.h2`
  font-size: 1.2rem;
  margin-top: 0px;
`;

export const InfoParaText = styled.div`
  font-size: 0.92rem;
  color: #a2a2a2;
  font-weight: normal;
  letter-spacing: 0px;
  width: ${(props) => props.width || "auto"};
`;

export const InfoTotal = styled.div`
  color: white;
  margin-left: -20px;
  margin-right: -60px;
  padding: 10px 60px 10px 50px;
  background-color: #215ca0;
  text-align: left;
`;

export const PdfPage = styled.div`
  padding: 20px 0;
  max-width: 230mm;
  font-family: ${(props) => props.pdfFont || "Arial"};
`;

export const PdfContainer = styled.div`
  max-width: 95%;
  margin: 0 auto;
`;

export const PdfLogo = styled.img`
  width: 111px;
  height: 111px;
  padding-top: 8px;
  padding-left: 7.5px;
`;

export const PdfHeading = styled.h1`
  margin: 0;
  margin-top: -5%;
  margin-right: 36px;
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: right;
`;

export const PdfBody = styled(Container)`
  margin: 0 30px;
  letter-spacing: 0.4px;
`;

export const PdfInfoTotal = styled.div`
  padding: 10px 10px 10px 50px;
  text-align: left;

  :last-child {
    background-color: #e0e0e0;
  }
`;
