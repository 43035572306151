import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const history = createBrowserHistory();

let app = document.getElementById('root');
if (app) {
	// const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  // console.log("hash = "+window.location.hash)
  // console.log("path = "+path)
	// if (path) {
	// 	history.replace(path);
  //   console.log("history = "+history)

	// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
