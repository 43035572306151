import { useLocation } from "react-router-dom";
import { useAuthState } from "../../Auth/Context/AuthContext";

export default function useModule() {
  const { pathname } = useLocation();
  const { modules, user } = useAuthState();

  const splitPathname = pathname.split("/");
  const modulePath = splitPathname.length > 1 ? splitPathname[1] : 0;
  const screenPath =
    splitPathname.length > 2 ? splitPathname.slice(1, 3).join("/") : null;

  const defaultModule = user.defaultModuleId;

  const matchedModule = modules.find((e) => e.module_name === modulePath);

  const activeModule = matchedModule
    ? matchedModule
    : modules.find((e) => e.module_id === defaultModule);

  const reRouteToModule = activeModule.module_name !== modulePath;
  const reRouteToScreen =
    activeModule.render_format === "Menu Tab" && splitPathname.length <= 2;
  const showScreenLinks =
    activeModule.render_format === "Menu Screen" && splitPathname.length <= 2;

  return {
    pathname,
    splitPathname,
    activeModule,
    screenPath,
    showScreenLinks,
    reRouteToModule,
    reRouteToScreen,
  };
}
