import React from "react";
import FMAutoComplete from "../FMAutoComplete";
import FMFastTextField from "../FMFastTextField";
import FMLookupField from "../FMLookupField";

const FMComponentMap = {
  Checkbox: [FMFastTextField, {}],
  "Date Picker": [FMFastTextField, {}],
  "Free Text": [FMFastTextField, {}],
  "Optional Drop Down": [
    FMAutoComplete,
    { disableClearable: false, convertValueToId: true },
  ],
  "Searchable Optional Drop Down": [
    FMAutoComplete,
    { disableClearable: false, convertValueToId: true },
  ],
  "Searchable Mandatory Drop Down": [
    FMAutoComplete,
    { disableClearable: true, convertValueToId: true },
  ],
  "Mulit Select Drop Down": [FMLookupField, { disableClearable: true }],
};

export default function FMCustomField({
  name,
  label,
  htmlType,
  lookupType,
  inputType,
  ...restProps
}) {
  const [FieldComponent, preDefinedProps] = FMComponentMap[inputType];

  if (!FieldComponent)
    throw new Error("Invalid inputType passed to FMCustomField");

  if (!["Checkbox", "Free Text", "Date Picker"].includes(inputType)) {
    preDefinedProps.lookupType = lookupType;
  }
  return (
    <FieldComponent
      {...preDefinedProps}
      name={name}
      label={label}
      type={htmlType}
      {...restProps}
    />
  );
}
