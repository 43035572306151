import React from "react";
import { Formik } from "formik";
import DoneIcon from "@material-ui/icons/Done";
import { useMutation, useQueryCache } from "react-query";
import { Box, Button, withStyles, CircularProgress } from "@material-ui/core";
import { FMFastCheckbox, FMFastTextField } from "../../Form";
import { saveFilter } from "./filterQueries";

const SaveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.success.dark,
    fontSize: 11,
    textTransform: "capitalize",
  },
}))(Button);

export default function FiltersSave({ filters }) {
  const cache = useQueryCache();
  const [save, { isLoading }] = useMutation(saveFilter, {
    onSuccess: async (data, variables) => {
      await cache.invalidateQueries("/api/lkp/grid-filters-with-field-values");
      variables.resetForm();
    },
  });

  const handleSave = async (vals, helpers) => {
    save({ ...vals, filter_values: filters, resetForm: helpers.resetForm });
  };

  return (
    <>
      <Formik
        initialValues={{
          filter_name: "",
          make_public: "",
        }}
        onSubmit={handleSave}
      >
        {(fkProps) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <FMFastTextField
              size="small"
              label="New Filter Name"
              name="filter_name"
              fullWidth={false}
            />
            <FMFastCheckbox label="Make Public" name="make_public" />
            <SaveButton
              type="button"
              variant="text"
              disabled={!fkProps.values.filter_name}
              onClick={fkProps.handleSubmit}
              startIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size="20px" />
                ) : (
                  <DoneIcon />
                )
              }
            >
              Save Filter
            </SaveButton>
          </Box>
        )}
      </Formik>
    </>
  );
}
