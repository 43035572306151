import React from "react";
import List from "@material-ui/core/List";
import NavItem from "./NavItem";
import { moduleMap } from "../../Routers";
import { useAuthState } from "../../Auth/Context/AuthContext";

function Module({ module_name, closeSidebar }) {
  const module = moduleMap[module_name];

  if (!module) return null;

  return (
    <NavItem
      to={`/${module_name}`}
      text={module.title}
      Icon={module.Icon}
      closeSidebar={closeSidebar}
    ></NavItem>
  );
}

export default function Nav({ closeSidebar }) {
  const { modules } = useAuthState();

  return (
    <List component="nav" aria-label="main modules">
      {modules.map((m) => (
        <Module key={m.module_name} {...m} closeSidebar={closeSidebar} />
      ))}
    </List>
  );
}
