import React, { createContext, useContext } from "react";
import { useState } from "react";

const ScreenContext = createContext();

export function ScreenProvider({ children }) {
  const screenState = useState({});

  return (
    <ScreenContext.Provider value={screenState}>
      {children}
    </ScreenContext.Provider>
  );
}

export function useScreen() {
  const state = useContext(ScreenContext);
  if (state === undefined) {
    throw new Error("No Screen Provider available for useScreenState");
  }
  return state;
}

export function useScreenId() {
  const state = useContext(ScreenContext);
  if (state === undefined) {
    throw new Error("No Screen Provider available for useScreenState");
  }
  const [{ screen_id }] = state;
  return screen_id;
}
