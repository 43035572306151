import React from "react";
import { Redirect } from "react-router-dom";
import { makeStyles, Backdrop } from "@material-ui/core";
import { useAuthState } from "../Auth/Context/AuthContext";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import LoginVerify from "../Auth/LoginVerify";
import useOpenClose from "../Hooks/useOpenClose";
import { ModuleRouter } from "../Routers";

const useStyles = makeStyles((theme) => ({
  parent: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: "100vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    background: "none",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
}));

export default function Layout() {
  const classes = useStyles();
  const { isLoggedIn } = useAuthState();
  const { open, handleClose, handleToggle } = useOpenClose();

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <div className={classes.parent}>
      {/* This backdrop will close the sidebar on click */}
      <Backdrop
        open={open}
        onClick={handleClose}
        className={classes.backdrop}
      />
      <Header handleMenuToggle={handleToggle} menuOpen={open} />
      <Sidebar onClose={handleClose} open={open} />
      <main className={classes.main}>
        <LoginVerify>
          <ModuleRouter />
        </LoginVerify>
      </main>
      <Footer />
    </div>
  );
}
