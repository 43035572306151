import React, { useCallback, useEffect } from "react";
import { Box, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { formatters } from "../../../lib";
import { getTotals } from "./salesCalculator";
import { FMTextField } from "../../Form";
import { useDefaultCurrency } from "../../Common";

const { formatCurrency } = formatters;

function FooterRow({ label, children }) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      my={2}
      fontSize={16}
    >
      <span>{label}</span>
      <span>{children}</span>
    </Box>
  );
}

const roundOff2 = (p) => Math.round(p * 100) / 100;

export default function SalesSubTotal({
  values,
  setFieldValue,
  disabled = false,
}) {
  const defaultCurrency = useDefaultCurrency();
  const discountPercent = values.discountPercent;

  const {
    beforeDiscount: subTotal,
    productTaxes,
    invoiceTaxes,
    afterInvoiceTax: totalAmount,
  } = getTotals(values);

  const handleDiscountChange = useCallback(
    (e) => {
      let { name, value } = e.target;

      if (value < 0) value = 0;
      if (name === "discountAmount") {
        const amount = value > subTotal ? subTotal : value;
        const percent = (amount / subTotal) * 100;
        setFieldValue("discountPercent", roundOff2(percent));
        setFieldValue(name, amount);
      } else if (name === "discountPercent") {
        const percent = roundOff2(value > 100 ? 100 : value);
        const amount = roundOff2((subTotal * percent) / 100);
        setFieldValue("discountAmount", amount);
        setFieldValue(name, percent);
      }
    },
    [setFieldValue, subTotal]
  );

  useEffect(() => {
    const newDiscountAmount = (subTotal * discountPercent) / 100;
    setFieldValue("discountAmount", roundOff2(newDiscountAmount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotal, setFieldValue]);

  return (
    <>
      <FooterRow label="Sub Total">
        {formatCurrency(subTotal, defaultCurrency)}
      </FooterRow>
      <FooterRow label="Discount Amount">
        <FMTextField
          size="small"
          disabled={subTotal === 0 || disabled}
          name="discountAmount"
          type="number"
          onChange={handleDiscountChange}
        />
      </FooterRow>
      <FooterRow label="Discount Percent">
        <FMTextField
          size="small"
          disabled={subTotal === 0 || disabled}
          name="discountPercent"
          type="number"
          onChange={handleDiscountChange}
        />
      </FooterRow>
      {productTaxes.map((tax) => (
        <FooterRow
          key={tax.tax_id}
          label={`Product Level Tax (${tax?.tax_name?.toUpperCase()} ${
            tax.tax_percent
          }%)`}
        >
          {formatCurrency(tax.sum, defaultCurrency)}
        </FooterRow>
      ))}
      {invoiceTaxes.map((tax) => (
        <FooterRow
          key={tax.tax_id}
          label={`Invoice Level Tax (${tax?.tax_name?.toUpperCase()} ${
            tax.tax_percent
          }%)`}
        >
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <span>{formatCurrency(tax.sum, defaultCurrency)}</span>
            &nbsp;&nbsp;&nbsp;
            <IconButton
              disabled={disabled}
              size="small"
              onClick={() =>
                setFieldValue("invoiceLevelTax", [
                  ...values.invoiceLevelTax.filter(
                    (v) => v.tax_id !== tax.tax_id
                  ),
                ])
              }
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </FooterRow>
      ))}
      <FooterRow label={`Total Amount`}>
        {formatCurrency(totalAmount, defaultCurrency)}
      </FooterRow>
    </>
  );
}
