import { useQuery } from "react-query";
import { axios5, formatters } from "../../lib";

export default function useGridOptions(screen_id) {
  // const { data, ...queryState } = useQuery(["/api/grid-options-menu"], {
  //   staleTime: 1000 * 60 * 5,
  // });

  async function getGridOptionResult(key,) {
    const { data } = await axios5.get("/api/grid-options-menu", {
        // headers: {

        //   search_string, screen_id: 104007, screen_name: "Global Search"


        // }
    });

    console.log("detail", JSON.stringify(data))

    return data.data0;
}
const { data, ...queryState } = useQuery(
    ["SearchDetails"],
    getGridOptionResult,
    { enabled: true, staleTime: 1000 * 60 * 5 }
);


  const apiOptions = Array.isArray(data) ? data : [];
  const rawOptions = apiOptions.filter(
    (e) => e.screen_id === parseInt(screen_id)
  );

  return { rawOptions, ...queryState };
}
