import * as Yup from "yup";
import { productsSubTotal } from "./salesCalculator";

export const invoiceAmountValidationText = "Invoice amount must be greater than 0";

export const ProductSchema = Yup.object().shape({
  product_entity_id: Yup.number().required("Please select a product"),
  units: Yup.number()
    .typeError("Invalid quantity")
    .positive("Must be more than 0"),
  unitPrice: Yup.number()
    .typeError("Invalid price")
    .min(0, "Cannot be less than 0"),
});

export const InvoiceSchema = Yup.object().shape(
  {
//   client_entity_id: Yup.number().required("Please select a client"),
//   invoice_date: Yup.string().required("Please select a date"),
//   payment_terms_id: Yup.number()
//     .required("Please select a payment term")
//     .nullable(),
// 	sales_employee_entity_id: Yup.number()
//     .required("Please select a sales person")
//     .nullable(),
  products: Yup.array()
    .of(ProductSchema)
    .test({ message: "Please add a product", test: (arr) => arr.length > 0 })
    .test({
      message: invoiceAmountValidationText,
      test: function (products) {
        const subTotal = productsSubTotal(products);
        const discountAmount = parseFloat(this.parent.discountAmount);
        return subTotal - discountAmount > 0;
      },
    }),
});

export const ServiceSchema = Yup.object().shape({
  client_entity_id: Yup.number().required("Please select a client"),
  invoice_date: Yup.string().required("Please select an invoice date"),
  completion_date: Yup.string().required("Please select a completion date"),
  service_type_id: Yup.number().required("Please select a service"),
  service_status_id: Yup.number().required("Please select a service status"),
  service_agent_entity_id: Yup.number().required("Please select an agent"),
  products: Yup.array()
    .of(ProductSchema)
    .test({ message: "Please add a product", test: (arr) => arr.length > 0 })
    .test({
      message: invoiceAmountValidationText,
      test: function (products) {
        const subTotal = productsSubTotal(products);
        const discountAmount = parseFloat(this.parent.discountAmount);
        return subTotal - discountAmount > 0;
      },
    }),
  vehicle_entity_id: Yup.string()
    .notRequired()
    .when("invoice_type", {
      is: (val) =>
        val === "Service - Car Wash" || val === "Service - Auto Repair",
      then: Yup.string().required("Please select a vehicle"),
      otherwise: Yup.string().notRequired(),
    }),
});

export const VehicleSchema = Yup.object().shape({
  vehicle_entity_id: Yup.number().required("Please select a vehicle"),
  odometer: Yup.number().required("Please enter a value"),
});
