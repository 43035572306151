import React from "react";
import { useQuery } from "react-query";
import { PageLoader } from "../Common";
import { useAuthState } from "./Context";
import useLogout from "../Hooks/useLogout";
import axios5 from "../../lib/axios5"

export default function LoginVerify({ children }) {
  const handleLogout = useLogout();
  const { isLoggedIn } = useAuthState();
  const { isLoading } = axios5.get("/api/check-screeen-permission", {
    enabled: isLoggedIn,
    onError: handleLogout,
  });

  if (isLoading) return <PageLoader />;

  return children;
}
