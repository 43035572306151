import React from "react";
import { Box } from "@material-ui/core";
import FormHeading from "./FormHeading";
import AddButton from "../Common/AddButton";

export default function FormHeadingWithAdd({ addButtonProps, heading }) {
  return (
    <Box
      mt={3}
      mb={1}
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
    >
      <FormHeading margin={[0]}>{heading}</FormHeading>
      <AddButton {...addButtonProps} />
    </Box>
  );
}
