import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiceD6 } from "@fortawesome/free-solid-svg-icons";
import screenIconMap from "./screenIconMap";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "block",
    height: "100%",
    textAlign: "center",
    textDecoration: "none",
    padding: theme.spacing(3),
    margin: theme.spacing(0, 3),
    marginBottom: theme.spacing(4),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
    borderRadius: theme.shape.borderRadius,
  },
  icon: {
    display: "block",
    color: theme.palette.primary.main,
    fontSize: theme.spacing(6),
    margin: "0 auto",
  },
  screenName: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "16px",
    color: theme.palette.text.primary,
    margin: theme.spacing(2, 0, 1, 0),
    letterSpacing: -0.1,
    lineHeight: 1.3,
  },
}));

export default function ScreenLink({ screen }) {
  const classes = useStyles();
  const screenLink = screen.screen_link.toLowerCase();
  const icon = screenIconMap[`/${screenLink}`] || faDiceD6;

  return (
    <Link className={classes.link} to={`/${screenLink}`}>
      <FontAwesomeIcon className={classes.icon} icon={icon} />
      <h6 className={classes.screenName}>{screen.screen_name}</h6>
    </Link>
  );
}
