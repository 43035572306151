import moment from "moment";
import React from "react";
import {axios,axios2,axios4} from "../../../lib";
import Alert from '@material-ui/lab/Alert';
import { formatters, getDateRange } from "../../../lib";
import {
  quoteUpdate,
  getSalesInsertValues,
  getSalesUpdateValues,
} from "./salesMappers";
import Snackbar from '@material-ui/core/Snackbar';
const { formatDate } = formatters;

const [start_date, end_date] = getDateRange(6, "months");

export const salesGridFilters = {
  subject_entity_id: "",
  vehicle_entity_id: null,
  show_full_paid: 0,
  start_date,
  end_date,
};

export async function getSalesList({ title, path, ...filters }) {
  const { data } = await axios.get(path, {
    headers: filters,
  });
  return data.data0;
}

export async function getSalesReport({ entityId }) {
  console.log("doc id entity=====> "+entityId)
  const { data } = await axios2.get("/api/file-management", {
    headers: { doc_entity_id: entityId },
  });
  return data;
}

export async function salesCreateOrUpdate(gridFormValues) {
  const { isQuote, formikMode, formikEditId } = gridFormValues;

  const mapper = formikMode === "add" ? getSalesInsertValues : isQuote ? quoteUpdate : getSalesUpdateValues;
  const mappedValues = mapper(gridFormValues);
  const method = formikMode === "edit" ? "put" : "post";
  const path = formikMode === "edit" && isQuote ? "quote" : "sales-invoice";

  const body = { act_entity_id: formikEditId, ...mappedValues };

  body.description = gridFormValues.description;
  // body.check_dd_number =gridFormValues.external_enquiry_number;
  // body.check_dd_date = gridFormValues.enquiry_date;
  
  const { data } = await axios[method](`/api/${path}`, body);
  // const newBody={
  // message_type: "Order Status",

  //   act_entity_id: data?.data0[0]?.act_entity_id
  // }
  // console.log("path=====>"+path)
  // if(path== "sales-invoice"){
  //   const { data:data1 } = await axios.post("/api/new-communication", newBody);
  // }

  return data;
}
export async function salesCreateOrUpdateStatus(act_entity_id) {
  const newBody={
    message_type: "Order Status",
    act_entity_id: act_entity_id
  }
 
     const { data:data1 } = await axios4.post("/api/communication-order-status", newBody);
 return data1;
}
export async function salesCreatePDF(id) {
const { data:data1 } = await axios2.get("/api/pdf-sales-invoice", {
         headers:{
          act_entity_id: id
         }
       });
   return data1;
  }
export async function quoteCreatePDF(id) {
    const { data:data1 } = await axios2.get("/api/pdf-quote", {
             headers:{
              act_entity_id: id
             }
           });
       return data1;
      } 
export const salesInitialValues = {
  client: "",
  client_entity_id: "",
  payment_terms_id: "",
  sales_employee_entity_id: "",
  description: "",
  send_sms_flag: false,
  send_email_flag: false,
  send_whatsapp_flag: false,
  invoice_date: formatDate(new Date(), "standard"),
  products: [
    // {
    //   listKey: Math.random(), // This key will be used in product list
    //   product_entity_id: "",
    //   product_name:"",
    //   description:"",
    //   tax: [],
    //   units: 1,
    //   unitPrice: 0,
    // },
  ],
  invoiceLevelTax: [],
  discountAmount: 0,
  discountPercent: 0,
  full_paid_flag: false,
};

export const quoteInitialValues = {
  ...salesInitialValues,
  quote_expiry_date: formatDate(moment().add(1, "month"), "standard"),
};

export const serviceInitialValues = {
  service_agent_entity_id: "",
  service_type_id: "",
  service_status_id: "",
  completion_date: formatDate(moment().add(1, "week"), "standard"),
  vehicle_entity_id: "",
  odometer: 0,
};

export async function getSalesInitialValues(key, entityId) {
  const { data: invoice } = await axios.get("/api/sales-invoice", {
    headers: { act_entity_id: entityId },
  });

  const invHeader = invoice.invoice_header[0];
  const invFooter = invoice.invoice_amount_footer[0];

  const client = null;

  // Extract products
  const products = invoice.products_data.map((product) => {
    const tax = invoice.products_tax_data.filter(
      (t) => t.product_entity_id === product.product_entity_id
    );
    return {
      product_entity_id: product.product_entity_id,
      product_name: product.product_name,
      description:product.description,
      units: product.units,
      unitPrice: product.rate,
      tax,
      listKey: Math.random(),
    };
  });

  const invoiceLevelTax = invoice.invoice_level_tax_data;

  let editValues = {
    ...invHeader,
    act_entity_status: invHeader.act_entity_status_id,
    transaction_date: formatDate(invHeader.date, "standard"),
    lpo_date: formatDate(invHeader.lpo_date, "standard"),
    enquiry_date: formatDate(invHeader.enquiry_date, "standard"),
    products,
    invoiceLevelTax,
    ...invFooter,
    discountAmount: invFooter.discount_value,
    discountPercent: invFooter.discount_percent,
  };

  // Extract specific data for quote and services
  const { quote_info, service_info } = invoice;
  if (Array.isArray(quote_info) && quote_info.length > 0) {
    editValues.quote_expiry_date = formatDate(
      invoice.quote_info[0].expiry_date,
      "standard"
    );
  }
  if (Array.isArray(service_info) && service_info.length > 0) {
    const service = service_info[0];
    editValues.service_agent_entity_id = service.service_agent_entity_id;
    editValues.service_type_id = service.service_type_id;
    editValues.service_status_id = service.service_status_id;
    editValues.completion_date = formatDate(
      service.completion_date,
      "standard"
    );
    editValues.vehicle_entity_id =
      service.vehicle_entity_id === null ? "" : service.vehicle_entity_id;
    editValues.odometer = service.odometer;
  }

  return editValues;
}
