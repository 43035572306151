import React from "react";
import { useFormikContext } from "formik";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  Chip,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FMTextField, FormHeading, FMLookupOnly } from "../../Form";
import AddButton from "../../Common/AddButton";
import { formatters } from "../../../lib";
import ApiErrorAlert from "../../Common/ApiErrorAlert";
import ProductSelectWithTax from "./ProductSelectWithTax";
import { useDefaultCurrency } from "../../Common";
import Tooltip from '@material-ui/core/Tooltip';

const { formatCurrency } = formatters;

export function TaxChips({ tax = [], onDelete, disabled }) {
  return tax.map((e) => (
    <Chip
      disabled={disabled}
      style={{ marginRight: 4 }}
      key={e.tax_id}
      onDelete={() => onDelete(e.tax_id)}
      label={`${e.tax_name} ${e.tax_percent}%`}
    />
  ));
}

const defaultProduct = {
  product_entity_id: "",
  product_name:"",
  description:"",
  tax: [],
  units: 1,
  unitPrice: 0,
};

export default function SalesProductsField({ disabled = false }) {
  const { values, setFieldValue, errors } = useFormikContext();
  const products = Array.isArray(values.products) ? values.products : [];
  const disabledInvoiceEdit = disabled;
  const defaultCurrency = useDefaultCurrency();
console.log("Products"+JSON.stringify(products))
  const handleProductAdd = () =>
    setFieldValue("products", [
      { ...defaultProduct, listKey: Math.random() },
      ...values.products,
    ]);

  return (
    <>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <FormHeading margin={0}>Product</FormHeading>&nbsp;&nbsp;&nbsp;
          <AddButton
            disabled={disabledInvoiceEdit}
            title="Add Product"
            onClick={handleProductAdd}
          />
        </Box>

        <Box minWidth="300px">
          <FMLookupOnly
            disabled={disabledInvoiceEdit}
            focused={false}
            label="Add Invoice Level Tax"
            lookupType="tax"
            value=""
            onChange={(e, lookup) => {
              const taxFinder = ({ tax_id }) => e.target.value === tax_id;
              const tax = lookup.find(taxFinder);
              const taxExists = values.invoiceLevelTax.find(taxFinder);
              if (tax && !taxExists) {
                const otherTaxes = values.invoiceLevelTax.filter(
                  ({ tax_id }) => e.tax_id !== tax_id
                );
                setFieldValue("invoiceLevelTax", [...otherTaxes, tax]);
              }
            }}
          />
        </Box>
      </Box>
      <ApiErrorAlert
        error={typeof errors.products === "string" ? errors.products : null}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={300}>Product</TableCell>
            <TableCell width={300}>Description</TableCell>
            <TableCell width={250}>Tax</TableCell>
            <TableCell width={110}>Units</TableCell>
            <TableCell width={170}>Unit Price</TableCell>
            <TableCell width={170}>Sub Total</TableCell>
            <TableCell align="center" width={90}>
              Del
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, index) => {
            const productId = product.product_entity_id;
            return (
              <TableRow key={product.listKey}>
                <TableCell>
                  <Tooltip title={product.product_name} aria-label="add">
                    <ProductSelectWithTax
                      disabled={disabledInvoiceEdit}
                      name={`products.${index}.product_entity_id`}
                      onChange={({ product_entity_id, product_name, unit_rate, tax }) => {
                        setFieldValue(
                          `products.${index}.product_entity_id`,
                          product_entity_id
                        );
                        setFieldValue(`products.${index}.product_name`, product_name);
                        setFieldValue(`products.${index}.tax`, tax);
                        setFieldValue(`products.${index}.unitPrice`, unit_rate);
                      }}
                    />
                  </Tooltip>
                </TableCell>

                <TableCell>
                <Tooltip title={product.description} aria-label="add">
                  <FMTextField
                    disabled={!productId || disabledInvoiceEdit}
                    name={`products.${index}.description`}
                    hover={"Hello"}
                  />
                </Tooltip>
                </TableCell>
                <TableCell>
                  <TaxChips
                    disabled={disabledInvoiceEdit}
                    tax={product.tax}
                    onDelete={(tax_id) =>
                      setFieldValue(
                        `products.${index}.tax`,
                        product.tax.filter((e) => e.tax_id !== tax_id)
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <FMTextField
                    disabled={!productId || disabledInvoiceEdit}
                    name={`products.${index}.units`}
                    type="number"
                  />
                </TableCell>
                <TableCell>
                  <FMTextField
                    disabled={!productId || disabledInvoiceEdit}
                    name={`products.${index}.unitPrice`}
                  />
                </TableCell>
                <TableCell style={{ fontSize: 16 }}>
                  {formatCurrency(
                    product.unitPrice * product.units,
                    defaultCurrency
                  )}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    disabled={disabledInvoiceEdit}
                    onClick={() => {
                      const newProducts = [...products];
                      newProducts.splice(index, 1);
                      setFieldValue("products", newProducts);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
