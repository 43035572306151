import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "./Menu";
import Toggle from "./Toggle";
import Branding from "./Branding";
import GlobalSearchInput from "../GlobalSearch/GlobalSearchInput";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  aboveDrawer: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export default function Header({ handleMenuToggle, menuOpen }) {
  const classes = useStyles();

  return (
    <AppBar className={classes.aboveDrawer} elevation={0} position="sticky">
      <Toolbar>
        <Toggle onToggle={handleMenuToggle} open={menuOpen} />
        <Branding />
        <div className={classes.grow}></div>
        <GlobalSearchInput />
        <div className={classes.grow}></div>
        <Menu />
      </Toolbar>
    </AppBar>
  );
}
