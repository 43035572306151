import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

export default function GridFilterButton({ handleClick, color = "default" }) {
  return (
    <Tooltip title="Filter Grid">
      <IconButton color={color} onClick={handleClick}>
        <FilterListIcon />
      </IconButton>
    </Tooltip>
  );
}
