import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headingLeft: {
    fontSize: "36px",
    "& span": {
      borderBottom: `solid 3px white`,
    },
    [theme.breakpoints.down("sm")]: {
      display:'none'
    },

  },
  headingRight: {
    color: theme.palette.primary.main,
    margin: theme.spacing(4, 0, 2, 0),
    fontSize: "2.5rem",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      display:'none'
    },
  },
  

}));

export default function AuthHeading({ children, type = "right" }) {
  const classes = useStyles();
  const className =
    type === "left" ? classes.headingLeft : classes.headingRight;

  return <h1 className={classes.headingRight}>{children}</h1>;
}
