import React from "react";
import { formatters } from "../../../lib";

const { formatDate, formatPhone } = formatters;

var orgData = {};

export default function PageTemplate(props) {

  if (props.isInit) {
    if (props.orgData) {
      orgData = props.orgData;
    }

    return null;
  }

  return (
    <div >
      <div
        style={{
          width: "93%",
          position: "absolute",
          bottom: "40px",
          margin: "0 3.5%",
          borderTop: "1px solid #dddddd",
        }}
      />
      <div
        style={{
          position: "absolute",
          fontSize: "12.5px",
          left: "20px",
          bottom: "5px",
          padding: "10px",
        }}
      >
        Printed: {formatDate(new Date(), "dateTimeAmPm")}
      </div>
      {
        orgData && (orgData.website || orgData.work_phone)
          ? SetOrganizationData(orgData)
          : null
      }
      <div
        style={{
          position: "absolute",
          fontSize: "14px",
          right: "20px",
          bottom: "5px",
          padding: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
}

function SetOrganizationData(data) {

  let orgDetails = [];
  if (data.work_phone) {
    orgDetails.push(
      `Contact: ${formatPhone(data.work_phone_country_code, data.work_phone)}`
    );
  }

  if (data.website) {
    orgDetails.push(data.website);
  }

  return (
    <div
      align="center"
      style={{
        width: "100%",
        position: "absolute",
        fontSize: "14px",
        bottom: "15px",
      }}
    >
      {orgDetails.join(" | ")}
    </div>
  )
}
