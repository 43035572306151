import axios from "../../../lib/axios";
import axiosLogin from "../../../lib/axiosLogin";

export async function forgotPassword({ domain_name }) {
  const { data } = await axiosLogin.post("/api/forgot-password-validate-loginID", {
    domain_name,
  });
  return data;
}

const mapOparamsToHeaders = (oparams) => ({
  session_id: oparams.session_id,
  user_id: oparams.user_id,
  token: oparams.v_token,
  encrypt_db_user: oparams.sql_user_id,
  encrypt_db_pwd: oparams.sql_password,
  encrypt_db_host: oparams.db_server,
  encrypt_db_database: oparams.database_name,
});

export async function validateOtp({ user_id, otp, oparams }) {
  const headers = mapOparamsToHeaders(oparams);
  const { data } = await axiosLogin.post(
    "/api/forgot-password-validate-otp",
    { user_id, otp },
    { headers }
  );
  return data;
}

export async function resetPassword({
  oparams,
  oldPassword,
  newPassword,
  resetting,
}) {
  const headers = resetting ? mapOparamsToHeaders(oparams) : {};
  const body = {
    old_password: oldPassword || null,
    new_password: newPassword,
    ignore_old_password: resetting ? 1 : 0,
  };
  const { data } = await axiosLogin.post("/api/change-password", body, { headers });
  return data;
}
