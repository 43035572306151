import styled from "styled-components";

const SectionWrap = styled.div`
  padding: 16px;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export default SectionWrap;
