import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 30px 0;
  border: solid 1px #414141;
`;

export const TableHead = styled.thead`
  background-color: #414141;
  font-weight: bold;
  color: white;
`;

export const HeadCell = styled.th`
  padding: 15px 10px;
  text-align: ${(props) => props.align || "left"};
  width: ${(props) => props.width || "auto"};
`;

export const TableCell = styled.td`
  padding: 15px 10px;
  text-align: ${(props) => props.align || "left"};
  width: ${(props) => props.width || "auto"};
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  :nth-child(even) {
    background-color: #d5d5d5;
  }
`;

export const PdfTable = styled.table`
  width: 100%;
  margin: 10px 0;
`;

export const PdfTableHead = styled.thead`
  background-color: #3c3d3a;
  color: white;
`;

export const PdfHeadCell = styled.th`
  padding: 15px 10px;
  text-align: ${(props) => props.align || "left"};
  width: ${(props) => props.width || "auto"};
`;

export const PdfTableCell = styled.td`
  padding: 15px 10px;
  text-align: ${(props) => props.align || "left"};
  width: ${(props) => props.width || "auto"};

  :nth-child(n+2) {
    border-bottom: 1px solid #dddddd;
  }
`;

export const PdfTableBody = styled.tbody`
`;

export const PdfTableRow = styled.tr`
`;
