import React from "react";
import styled from "styled-components";

const FiltersBoxStyled = styled.div`
  > * {
    margin-bottom: 16px;
    margin-top: 0;
  }
`;

export default function FiltersBox({ children }) {
  return <FiltersBoxStyled>{children}</FiltersBoxStyled>;
}
