export function getUrlParam(key) {
  const params = new URLSearchParams(window.location.search);
  if (!params) return null;
  else return params.get(key);
}

export function getApiSuccess(data) {
  if (data.message) return data.message;
  else if (data.api_status && data.api_status.message) {
    return data.api_status.message;
  } else if (data.api_error && data.api_error.message) {
    return data.api_error.message;
  } else {
    return "Action successful";
  }
}

export function getApiError(error) {
  console.log("errrrrrroooooooooooooor"+error)
  if (!error) return null;

  if (typeof error === "string") return error;
  if (typeof error?.message === "string") return error.message;

  if (error && error.response && error.response.data) {
    const data = error.response.data;

    if (data.message) return data.message;
    else if (
      data.api_status &&
      data.api_status.status === "Failure" &&
      data.api_status.message
    ) {
      return data.api_status.message;
    } else if (data.api_error && data.api_error.message) {
      return data.api_error.message;
    } else {
      return "Internal server error";
    }
  } else {
    return "Internal server error";
  }
}

export function getSprocError(error) {
  if (!error) return null;

  if (typeof error === "string") return error;

  if (error?.response?.data) {
    const data = error.response.data;

    if (data.message) return data.message;
    else if (
      data?.api_status?.status === "Failure" &&
      data?.api_error?.message
    ) {
      return data.api_error.message;
    } else {
      return "Internal server error";
    }
  } else {
    return "Internal server error";
  }
}
