import React from "react";
import { makeStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
}));

export default function LinearLoader({ color, loading = true }) {
  const classes = useStyles();

  if (!loading) return null;
  else return <LinearProgress className={classes.root} color={color} />;
}

LinearLoader.defaultProps = {
  color: "primary",
  loading: false,
};
