import { useLocation } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";

function getEmptyFilters(filters = {}) {
  const emptyFilters = {};
  Object.entries(filters).forEach(([key, val]) => {
    if (Array.isArray(val)) {
      emptyFilters[key] = [];
    } else if (typeof val === "boolean") {
      emptyFilters[key] = val;
    } else if (val !== null && typeof val === "object") {
      emptyFilters[key] = getEmptyFilters(val);
    } else {
      emptyFilters[key] = "";
    }
  });
  return emptyFilters;
}

export default function useFilters(filterKey, initialValue, filterMap = {}) {
  const { state } = useLocation();
  const stateFilters = state?.filters || {};
  const [fetch,setFetch]=useState(false)
  let sessionFilters = sessionStorage.getItem(filterKey);

  if (!sessionFilters) {
    sessionFilters = JSON.stringify({ ...initialValue });
    sessionStorage.setItem(filterKey, sessionFilters);
  }

  const [filters, setFilters] = useState({
    ...JSON.parse(sessionFilters),
    ...stateFilters,
  });

  const isFiltered = Object.values(filters).some((e) => {
    if (Array.isArray(e)) return e.length > 0;
    else return !!e;
  });

  const handleFilterChange = useCallback(
    (values) => {
      sessionStorage.setItem(filterKey, JSON.stringify(values));
      setFilters(values);
    setFetch(true);
    console.log("change=====>"+fetch)
    setTimeout(() => {
      //page=page++
       //refetch();
       //setX(false); 
       // setPosts([...posts, ...mapped])
       // console.log("======Posts==>"+JSON.stringify(posts))
       setFetch(false);
       
     }, 2000);
    },
    [filterKey]

  );

  const handleFilterReset = useCallback(() => {
    const emptyFilters = getEmptyFilters(initialValue);
    sessionStorage.setItem(filterKey, JSON.stringify(emptyFilters));
    setFilters(emptyFilters);
    setFetch(false);
console.log("reset=====>"+fetch)

  }, [initialValue, filterKey]);

  const gridFilters = useMemo(() => {
    const newFilters = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (typeof value === "boolean") {
        newFilters[key] = value ? 1 : 0;
      } else if (typeof value === "string") {
        newFilters[key] = value.length === 0 ? null : value;
      } else if (typeof value === "number") {
        newFilters[key] = value;
      } else if (Array.isArray(value)) {
        if (value.length === 0) {
          newFilters[key] = null;
        } else {
          const [mapKey, valueKey] = filterMap[key];
          newFilters[key] = JSON.stringify(
            value.map((e) => ({ [mapKey]: e[valueKey] }))
          );
        }
      } else {
        newFilters[key] = null;
      }
    });
    return newFilters;
  }, [filters, filterMap]);
 
  return {
    filters,
    filterKey,
    isFiltered,
    gridFilters,
    fetch,
    handleFilterChange,
    handleFilterReset,
  };
}
