import HomeIcon from "@material-ui/icons/Home";
import DashIcon from "@material-ui/icons/Dashboard";
import FolderIcon from "@material-ui/icons/Folder";
import PeopleIcon from "@material-ui/icons/People";
import MessageIcon from "@material-ui/icons/Message";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BusinessIcon from "@material-ui/icons/Business";
import SchoolIcon from "@material-ui/icons/School";
import TransportIcon from "@material-ui/icons/DriveEta"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CrmIcon from "./Icons/Crm";
import SalesIcon from "./Icons/Sales";
import PreSalesIcon from "./Icons/PreSales";
import PostSalesIcon from "./Icons/PostSales";
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

const moduleMap = {
  accounting: {
    title: "Accounting",
    Icon: MonetizationOnIcon,
  },
  admin: { 
    title: "Admin",
    Icon: SettingsIcon,
  },
  restaurant: { 
    title: "Restaurant",
    Icon: SettingsIcon,
  },
 
rewards: {
    title: "Rewards",
    Icon: MonetizationOnIcon,
  }, 
  communication: {
    title: "Communication",
    Icon: MessageIcon,
  },
  crm: {
    title: "CRM",
    Icon: CrmIcon,
  },
  dashboard: {
    title: "Dashboard",
    Icon: DashIcon,
  },
  document: {
    title: "Documents",
    Icon: FolderIcon,
  },
  exam: {
    title: "Exams",
    Icon: AssignmentTurnedInIcon,
  },
  
  hr: {
    title: "HR",
    Icon: PeopleIcon,
  },
  home: {
    title: "Home",
    Icon: HomeIcon,
  },
  inventory: {
    title: "Inventory",
    Icon: AllInboxIcon,
  },
  ngo: {
    title: "NGO",
    Icon: NotificationsIcon,
  },
  notification: {
    title: "Notification",
    Icon: NotificationsIcon,
  },
  organization: {
    title: "Organization",
    Icon: BusinessIcon,
  },
  "post-sales": {
    title: "Post Sales",
    Icon: PostSalesIcon,
  },
  "pre-sales": {
    title: "Pre Sales",
    Icon: PreSalesIcon,
  },
 "order management": {
    title: "Order Management",
    Icon: PreSalesIcon,
  },
  sales: {
    title: "Sales",
    Icon: SalesIcon,
  },
  pmo: {
    title: "PMO",
    Icon: SalesIcon,
  },
  "consumer app": {
    title: "Consumer App",
    Icon: SalesIcon,
  },
  profile: {
    title: "Profile",
    Icon: AccountCircleIcon,
  },
  outpatient: {
    title: "Outpatient",
    Icon: AccountCircleIcon,
  },
  school: {
    title: "School",
    Icon: SchoolIcon,
  },
  settings: {
    title: "Settings",
    Icon: SettingsIcon,
  },
  student: {
    title: "Student",
    Icon: AssignmentIndIcon,
  },
  online: {
    title: "Online",
    Icon: OpenInBrowserIcon,
  },
  transport: { 
    title: "Transport",
    Icon: TransportIcon,
  },
};

export default moduleMap;
