import React from "react";
import TansyButton from "../../Common/TansyButton";

function FilterActions({ disabled = false, onClear, onClose, onApply }) {
  return (
    <>
      {onClear ? (
        <TansyButton onClick={onClear} variant="text" color="default">
          Clear Filters
        </TansyButton>
      ) : null}
      <TansyButton onClick={onClose} variant="text">
        Cancel
      </TansyButton>
      <TansyButton onClick={onApply} disabled={disabled}>
        Apply
      </TansyButton>
    </>
  );
}

export default FilterActions;
