import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    display: "block",
  },
  helperText: {
    marginLeft: 2,
    marginRight: 0,
  },
}));

export default function FMDatePicker(props) {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const classes = useStyles();

  return (
    <DatePicker
      FormHelperTextProps={{ className: classes.helperText }}
      className={classes.root}
      error={!!errorText.trim()}
      helperText={errorText}
      fullWidth
      {...field}
      value={field.value}
      onChange={(date) => helpers.setValue(date)}
      {...props}
    />
  );
}
