import React from "react";
import { useHistory } from "react-router-dom";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    marginLeft: theme.spacing(2),
    fontSize: 20,
    fontWeight: 500,
    textTransform: "capitalize",
  },
}));

export default function GridDetailTitle({ title = "", gridPath }) {
  const history = useHistory();
  const classes = useStyles();

  const goBackToGrid = () => {
    if (Array.isArray(gridPath)) history.replace(...gridPath);
    else history.replace(gridPath);
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={goBackToGrid}>
        <ArrowBackIcon />
      </IconButton>
      <Typography className={classes.title} color="inherit">
        {title}
      </Typography>
    </Box>
  );
}
