import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { useAuthState } from "../../../Auth/Context/AuthContext";

export default function ProfilePicture(props) {
  const { user } = useAuthState();

  return (
    <div style={{ display: "grid", placeItems: "center" }}>
      <Avatar
        style={{
          width: 30,
          height: 30,
          borderRadius: 30,
          margin: "0 16px",
          cursor: "pointer",
        }}
        // src={user.image}
        alt={`Square profile picture of ${user.name}`}
        {...props}
      />
    </div>
  );
}
