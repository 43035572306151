import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CrossIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default function GridSearch({ onSearchChange, searchValue = "" }) {
  const [visible, setVisible] = useState(false);
  const classes = useStyles();

  const toggleVisible = () => {
    if (visible) onSearchChange("");
    setVisible((oldVisible) => !oldVisible);
  };

  const isSearchVisible = visible || searchValue.length > 0;

  return (
    <Box display="flex" mx={2}>
      {isSearchVisible && (
        <TextField
          autoFocus={!!visible}
          onChange={(e) => onSearchChange(e.target.value)}
          value={searchValue}
          placeholder="Search"
        />
      )}
      <IconButton
        className={classes.iconButton}
        onClick={toggleVisible}
        size="small"
      >
        {!isSearchVisible ? <SearchIcon /> : <CrossIcon />}
      </IconButton>
    </Box>
  );
}
