import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FMCheckbox, FMTextField } from "../../Form";

export default function AccountingBatchField({
  paymentColTitle,
  payments = [],
}) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell width={100}></TableCell>
          <TableCell width={140}>Invoice #</TableCell>
          <TableCell width={180}>Due Date</TableCell>
          <TableCell>Total Amount</TableCell>
          <TableCell>Balance Amount</TableCell>
          <TableCell width={350}>{paymentColTitle}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payments.map((e, index) => (
          <TableRow key={e.act_entity_id}>
            <TableCell align="center">
              <FMCheckbox name={`payments.${index}.checked`} />
            </TableCell>
            <TableCell>{e.invoice_number}</TableCell>
            <TableCell>{e.due_date}</TableCell>
            <TableCell>{e.total_amount_formatted}</TableCell>
            <TableCell>{e.current_balance_formatted}</TableCell>
            <TableCell>
              {e.checked ? (
                <FMTextField
                  size="small"
                  type="number"
                  name={`payments.${index}.paid_amount`}
                />
              ) : (
                e.paid_amount
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
