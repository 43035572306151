import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useFormikContext } from "formik";
import { formatters } from "../../../lib";
import { useDefaultCurrency } from "../../Common";
import { FMTextField, FormGridItem, FMLookupField } from "../../Form";

const { formatCurrency } = formatters;

const isTypeCheque = (str = "") =>
  ["cheque", "check"].includes(str.toLowerCase());

export default function AccountingChequeField({ totalLabel, bankFieldName }) {
  const { values, setValues } = useFormikContext();
  const defaultCurrency = useDefaultCurrency();

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <FormGridItem>
          <FMLookupField
            label="Payment Type"
            name="payment_type_id"
            lookupType="paymentType"
            onChange={(event, lookup) => {
              const value = event.target.value;
              const payment = lookup.find((e) => e.payment_type_id === value);
              const paymentType = payment ? payment.payment_type : "";
              const paymentVals = {
                payment_type_id: value,
                payment_type: paymentType,
              };

              // In our validation we only give error if cheque details are undefined
              // if the values are null we will pass validation for cheque details
              // So if the payment type is not cheque or check (you can add more strings)
              // then we set field to null so that we pass validation
              if (isTypeCheque(paymentType)) {
                setValues({
                  ...values,
                  ...paymentVals,
                  check_dd_date: undefined,
                  check_dd_number: undefined,
                  [bankFieldName]: undefined,
                });
              } else {
                setValues({
                  ...values,
                  ...paymentVals,
                  check_dd_date: null,
                  check_dd_number: null,
                  [bankFieldName]: null,
                });
              }
            }}
          />
        </FormGridItem>
        <FormGridItem>
          <Typography align="right" variant="h6" color="primary">
            <strong>
              {totalLabel}{" "}
              {formatCurrency(
                values.payments
                  .filter((e) => e.checked === true)
                  .reduce((a, b) => a + b.paid_amount, 0),
                defaultCurrency
              )}
            </strong>
          </Typography>
        </FormGridItem>
        <FormGridItem />
        {isTypeCheque(values.payment_type) ? (
          <>
            <FormGridItem>
              <FMTextField label="Check # *" name="check_dd_number" />
            </FormGridItem>
            <FormGridItem>
              <FMTextField
                label="Encash Date"
                name="check_dd_date"
                type="date"
              />
            </FormGridItem>
            <FormGridItem>
              <FMLookupField
                label="Select Bank"
                name={bankFieldName}
                lookupType="bank"
              />
            </FormGridItem>
          </>
        ) : null}
      </Grid>
    </>
  );
}
