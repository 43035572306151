import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Breadcrumbs, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
    borderBottom: `solid 2px ${theme.palette.background.default}`,
    background: theme.palette.background.paper,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.secondary,
    textTransform: "capitalize",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  typography: {
    textTransform: "capitalize",
  },
}));

export default function Breadcrumb({ crumbs = [] }) {
  const classes = useStyles();

  return (
    <Breadcrumbs
      className={classes.root}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {crumbs.map((e) =>
        e.path ? (
          <Link key={e.name} className={classes.link} to={e.path}>
            {e.name}
          </Link>
        ) : (
          <Typography
            key={e.name}
            className={classes.typography}
            color="textPrimary"
          >
            {e.name}
          </Typography>
        )
      )}
    </Breadcrumbs>
  );
}
