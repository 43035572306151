import FMFastCheckbox from "../FMFastCheckbox";
import FMFastTextField from "../FMFastTextField";
import FMAutoCompletePlain from "../FMAutoCompletePlain";
import FMMultiDropdown from "../FMMultiDropdown";
import { axios } from "../../../lib";

export const CUSTOM_FIELD_NAME_PREFIX = "custom_field_";

export const isDropdown = (inputType) =>
  [
    "Optional Drop Down",
    "Mulit Select Drop Down",
    "Searchable Optional Drop Down",
    "Searchable Mandatory Drop Down",
  ].includes(inputType);

export const isTextField = (inputType) =>
  ["Free Text", "Date Picker"].includes(inputType);

export const DataTypesMap = {
  Character: "text",
  Currency: "number",
  Date: "date",
  Decimal: "number",
  Number: "number",
};

export const ComponentMap = {
  Checkbox: [FMFastCheckbox, {}],
  "Free Text": [FMFastTextField, {}],
  "Date Picker": [FMFastTextField, {}],
  "Optional Drop Down": [
    FMAutoCompletePlain,
    { disableClearable: false, convertValueToId: true },
  ],
  "Searchable Optional Drop Down": [
    FMAutoCompletePlain,
    { disableClearable: false, convertValueToId: true },
  ],
  "Searchable Mandatory Drop Down": [
    FMAutoCompletePlain,
    { disableClearable: true, convertValueToId: true },
  ],
  "Mulit Select Drop Down": [FMMultiDropdown, { disableClearable: true }],
};

export async function insertCustomFields({ entity_id, formValues }) {
  const fieldValues = Object.entries(formValues)
    .map(([key, value]) => {
      if (key.startsWith(CUSTOM_FIELD_NAME_PREFIX)) {
        return {
          custom_field_id: parseInt(key.replace(CUSTOM_FIELD_NAME_PREFIX, "")),
          field_value: Array.isArray(value)
            ? value.map((e) => e.value).join(",") === ""?null:value.map((e) => e.value).join(",")
            : typeof value === "boolean"
            ? value
              ? 1
              : 0
            : value,
        };
      } else {
        return null;
      }
    })
    .filter((e) => e !== null);

  const { data } = await axios.post("/api/custom-fields-apply", {
    entity_id,
    custom_field_list: JSON.stringify(fieldValues),
  });

  return data;
}
