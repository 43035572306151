import React from "react";
import { Box } from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { theme } from "../../../lib";

const typeMap = {
  Payable: { text: "Out", colorType: "error", Icon: ArrowUpwardIcon },
  Receivable: { text: "In", colorType: "success", Icon: ArrowDownwardIcon },
};

export default function AccountingInOut({ batch_type: type }) {
  const { text, colorType, Icon } = typeMap[type] || {
    text: "-",
    color: "primary",
    Icon: null,
  };

  const color = theme.palette[colorType]?.main;

  return (
    <Box display="flex" alignItems="center" color={color}>
      {Icon && <Icon />}
      {/* &nbsp;
      {text} */}
    </Box>
  );
}
