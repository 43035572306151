export const lookupMap = {
  productType: {
    lookupPath: "v1/product-type",
    textField: "product_type",
    dataItemKey: "product_type_entity_id",
  },
  productTypeId: {
    lookupPath: "v1/product-type",
    textField: "product_type",
    dataItemKey: "product_type_id",
  },
  uiStyle: {
    lookupPath: "org-ui-style",
    textField: "ui_style",
    dataItemKey: "ui_style",
  },
  tabName: {
    lookupPath: "shopping-cart-tab-name",
    textField: "tab_name",
    dataItemKey: "tab_id",
  },
  screen: {
    lookupPath: "screen-name",
    textField: "screen_name",
    dataItemKey: "screen_id",
  },
  specialization: {
    lookupPath: "specialization",
    textField: "specialization_name",
    dataItemKey: "specialization_id",
  },
  statType: {
    lookupPath: "stat-type",
    textField: "stats_name",
    dataItemKey: "stats_id",
  },
  diagnosis: {
    lookupPath: "diagnosis",
    textField: "diagnosis_name",
    dataItemKey: "diagnosis_type_id",
  },
 
  product: {
    lookupPath: "product-detail",
    textField: "product_name",
    dataItemKey: "product_entity_id",
  },
  productName: {
    lookupPath: "product",
    textField: "product_name",
    dataItemKey: "product_entity_id",
  },
  productWithTax: {
    lookupPath: "product-with-tax",
    textField: "product_name",
    dataItemKey: "product_entity_id",
  },
  workflowType: {
    lookupPath: "work-flow-type",
    textField: "workflow_name",
    dataItemKey: "worflow_id",
  },
  workflowTabs: {
    lookupPath: "work-flow-tabs",
    textField: "tab_name",
    dataItemKey: "id",
  },
  entityByEntityType: {
    lookupPath: "entity-by-entity-type",
    textField: "child_entity_name",
    dataItemKey: "child_entity_id",
  },
  productWithTaxClient: {
    lookupPath: "product-with-tax-client",
    textField: "product_name",
    dataItemKey: "product_entity_id",
  },
  manufacturer: {
    lookupPath: "v1/manufacture",
    textField: "manufacturer",
    dataItemKey: "manufacturer_id",
  },
  brand: {
    lookupPath: "v1/brand-items",
    textField: "brand",
    dataItemKey: "brand_id",
  },
  uom: {
    lookupPath: "v1/measure",
    textField: "uom",
    dataItemKey: "uom_type_id",
  },
  member: {
    lookupPath: "member",
    textField: "raw_entity_name",
    dataItemKey: "member_entity_id",
  },
  memberType: {
    lookupPath: "member-type",
    textField: "member_type",
    dataItemKey: "member_type_id",
  },
  socialProfileType: {
    lookupPath: "social-profile-type",
    textField: "profile_type",
    dataItemKey: "profile_type_id",
  },
  tax: {
    lookupPath: "v1/tax",
    textField: "tax_name",
    dataItemKey: "tax_id",
  },
  salesAccount: {
    lookupPath: "ledger-account",
    textField: "entity_name",
    dataItemKey: "entity_id",
  },
  inventoryAccount: {
    lookupPath: "ledger-account",
    textField: "entity_name",
    dataItemKey: "entity_id",
  },
  sysLookup: {
    lookupPath: "sys-lookups",
    textField: "lookup_name",
    dataItemKey: "lookup_id",
  },
  systemFields: {
    lookupPath: "system-fields",
    textField: "field_name",
    dataItemKey: "field_id",
  },
  communicationType: {
    lookupPath: "communication-type",
    textField: "communication_type",
    dataItemKey: "communication_type_id",
  },
  communicationProvider: {
    lookupPath: "provider",
    textField: "provider_name",
    dataItemKey: "provider_entity_id",
  },
  messageType: {
    lookupPath: "communication-message-type",
    textField: "message_type",
    dataItemKey: "message_type_id",
  },
  allEntities: {
    lookupPath: "all-entities",
    textField: "raw_entity_name",
    dataItemKey: "entity_id",
  },
  All: {
    lookupPath: "all-entities",
    textField: "raw_entity_name",
    dataItemKey: "entity_id",
  },
  parentEntity: {
    lookupPath: "parent-entity",
    textField: "parent_entity_name",
    dataItemKey: "parent_entity_id",
  },
  parentEntityV2: {
    lookupPath: "parent-entity-v2",
    textField: "parent_entity_name",
    dataItemKey: "parent_entity_id",
  },
  childEntity: {
    lookupPath: "child-entity",
    textField: "child_entity_name",
    dataItemKey: "child_entity_id",
  },
  OrgWebTemplateType: {
    lookupPath: "org-web-template-type",
    textField: "template_type",
    dataItemKey: "template_type_id",
  },
  smsTemplate: {
    lookupPath: "sms-template",
    textField: "template_name",
    dataItemKey: "sms_template_id",
  },
  emailTemplate: {
    lookupPath: "email-template",
    textField: "template_name",
    dataItemKey: "email_template_id",
  },
  whatsAppTemplate: {
    lookupPath: "whatsapp-template",
    textField: "template_name",
    dataItemKey: "whatsapp_template_id",
  },
  documentCategory: {
    lookupPath: "document-category",
    textField: "document_category",
    dataItemKey: "doc_category_id",
  },
  documentType: {
    lookupPath: "document-type",
    textField: "document_type",
    dataItemKey: "document_type_id",
  },
  documentCategoryCommunication: {
    lookupPath: "document-category-for-communication",
    textField: "document_category",
    dataItemKey: "doc_category_id",
  },
  documentCategoryText: {
    lookupPath: "document-category",
    textField: "document_category",
    dataItemKey: "document_category",
  },
  client: {
    lookupPath: "client",
    textField: "client_name",
    dataItemKey: "client_entity_id",
  },
  lead: {
    lookupPath: "lead",
    textField: "lead_name",
    dataItemKey: "lead_entity_id",
  },
  clientWithCommunication: {
    lookupPath: "client-with-communication",
    textField: "entity_name",
    dataItemKey: "entity_id",
  },
  supplierWithCommunication: {
    lookupPath: "supplier-with-communication",
    textField: "supplier_name",
    dataItemKey: "supplier_entity_id",
  },
  clientWithLead: {
    lookupPath: "client-with-lead",
    textField: "entity_name",
    dataItemKey: "entity_id",
  },
  paymentTerms: {
    lookupPath: "v1/payment-terms",
    textField: "description",
    dataItemKey: "payment_terms_id",
  },
  paymentType: {
    lookupPath: "payment-type",
    textField: "payment_type",
    dataItemKey: "payment_type_id",
  },
  employee: {
    lookupPath: "employee",
    textField: "employee_name",
    dataItemKey: "employee_entity_id",
  },
  countryCode: {
    lookupPath: "country",
    textField: "phone_code",
    dataItemKey: "country_id",
  },
  city: {
    lookupPath: "city",
    textField: "city_name",
    dataItemKey: "city_id",
  },
  industry: {
    lookupPath: "industry",
    textField: "domain",
    dataItemKey: "domain_id",
  },
  state: {
    lookupPath: "state",
    textField: "state",
    dataItemKey: "state_id",
  },
  postal: {
    lookupPath: "postal-code",
    textField: "postal_code",
    dataItemKey: "postal_code_id",
  },
  gender: {
    lookupPath: "gender",
    textField: "gender",
    dataItemKey: "gender_id",
  },
  project: {
    lookupPath: "project",
    textField: "project_name",
    dataItemKey: "project_entity_id",
  },
  projectType: {
    lookupPath: "project-type",
    textField: "project_type",
    dataItemKey: "project_type_id",
  },
  ticketType: {
    lookupPath: "ticket-type",
    textField: "crm_type",
    dataItemKey: "crm_type_id",
  },
  ticketStatus: {
    lookupPath: "ticket-status",
    textField: "crm_status",
    dataItemKey: "crm_status_id",
  },
  ticketPriority: {
    lookupPath: "ticket-priority",
    textField: "crm_priority",
    dataItemKey: "crm_priority_id",
  },
  ticketLabel: {
    lookupPath: "ticket-label",
    textField: "crm_label",
    dataItemKey: "crm_label_id",
  },
  exam: {
    lookupPath: "exam",
    textField: "exam",
    dataItemKey: "exam_entity_id",
  },
  subexam: {
    lookupPath: "sub-exam",
    textField: "exam",
    dataItemKey: "exam_entity_id",
  },
  account: {
    lookupPath: "v1/account",
    textField: "entity_name",
    dataItemKey: "entity_id",
  },
  securityGroup: {
    lookupPath: "securiy-group",
    textField: "security_group",
    dataItemKey: "security_group_entity_id",
  },
  securityGroupV2: {
    lookupPath: "security-group-v2",
    textField: "group_name",
    dataItemKey: "group_entity_id",
  },
  actEntityStatus: {
    lookupPath: "act-entity-status",
    textField: "act_entity_status",
    dataItemKey: "act_entity_status_id",
  },
  serviceStatus: {
    lookupPath: "service-status",
    textField: "service_status",
    dataItemKey: "service_status_id",
  },
  serviceType: {
    lookupPath: "service-type",
    textField: "service_type",
    dataItemKey: "service_type_id",
  },
  vehicle: {
    lookupPath: "vehicle",
    textField: "number_plate",
    dataItemKey: "entity_id",
  },
  vehicleType: {
    lookupPath: "vehicle-type",
    textField: "vehicle_type",
    dataItemKey: "vehicle_type_id",
  },
  vehicleMake: {
    lookupPath: "vehicle-make",
    textField: "vehicle_make",
    dataItemKey: "vehicle_make_id",
  },
  vehicleModel: {
    lookupPath: "vehicle-model",
    textField: "vehicle_model",
    dataItemKey: "vehicle_model_id",
  },
  frequency: {
    lookupPath: "frequency",
    textField: "description",
    dataItemKey: "frequency_id",
  },
  bank: {
    lookupPath: "bank",
    textField: "bank_name",
    dataItemKey: "bank_account_entity_id",
  },
  subjectType: {
    lookupPath: "subject-type",
    textField: "subject_type",
    dataItemKey: "subject_type_id",
  },
  subjectTeacher: {
    lookupPath: "subject-name-with-teacher",
    textField: "subject",
    dataItemKey: "subject_entity_id",
  },
  supplier: {
    lookupPath: "supplier",
    textField: "supplier_name",
    dataItemKey: "supplier_entity_id",
  },
  supplierSearchable: {
    lookupPath: "supplier-searchable",
    textField: "supplier_name",
    dataItemKey: "supplier_entity_id",
  },
  class: {
    lookupPath: "class",
    textField: "class_name",
    dataItemKey: "class_entity_id",
  },
  classWithGroup: {
    lookupPath: "class-group-with-class",
    textField: "class_with_group",
    dataItemKey: "class_entity_id",
  },
  classGroup: {
    lookupPath: "class-group",
    textField: "raw_entity_name",
    dataItemKey: "entity_id",
  },
  classSubject: {
    lookupPath: "class-subjects",
    textField: "class_with_subject",
   // dataItemKey:["class_entity_id"],
    dataItemKey:"class_entity_id",
  
  },
  subject: {
    lookupPath: "subject",
    textField: "subject",
    dataItemKey: "subject_entity_id",
  },
  parentTopic: {
    lookupPath: "parent-topic",
    textField: "topic_name",
    dataItemKey: "parent_topic_id",
  },
  gradeSystem: {
    lookupPath: "exam-grading-system",
    textField: "grade_type",
    dataItemKey: "grade_system_id",
  },
  searchableClient: {
    lookupPath: "client-searchable",
    textField: "entity_name",
    dataItemKey: "entity_id",
  },
  provider: {
    lookupPath: "provider",
    textField: "provider_name",
    dataItemKey: "provider_entity_id",
  },
  customEntity: {
    lookupPath: "custom-field-entity",
    textField: "screen_name",
    dataItemKey: "screen_id",
  },
  cityArea: {
    lookupPath: "city-area",
    textField: "city_area",
    dataItemKey: "city_area",
  },
  currency: {
    lookupPath: "currency",
    textField: "currency_name",
    dataItemKey: "currency_id",
  },
  entityType: {
    lookupPath: "entity-type",
    textField: "entity_type",
    dataItemKey: "entity_type_id",
  },
  bulkLoadEntityType: {
    lookupPath: "bulk-load-entity-type",
    textField: "entity_type",
    dataItemKey: "entity_type_id",
  },
  examType: {
    lookupPath: "exam-type",
    textField: "exam_type",
    dataItemKey: "exam_type_id",
  },
  examReportType: {
    lookupPath: "exam-report-type",
    textField: "student_report_version",
    dataItemKey: "student_report_version",
  },
  examReportVersion: {
    lookupPath: "progress-report-version",
    textField: "student_report_version",
    dataItemKey: "report_version_id",
  },
  expenseType: {
    lookupPath: "expense-type",
    textField: "expense_type",
    dataItemKey: "expense_type_id",
  },
  accountTypeFilter: {
    lookupPath: "account-type-filter",
    textField: "drop_down_list_name",
    dataItemKey: "entity_id",
  },
  facilityTypeV2: {
    lookupPath: "facility-v2",
    textField: "facility_name",
    dataItemKey: "facility_type_id",
  },
  department: {
    lookupPath: "department",
    textField: "department_name",
    dataItemKey: "department_id",
  },
  designation: {
    lookupPath: "designation",
    textField: "designation_name",
    dataItemKey: "designation_id",
  },
  clientStatus: {
    lookupPath: "client-status",
    textField: "client_status",
    dataItemKey: "client_status_id",
  },
  leadStatus: {
    lookupPath: "lead-status",
    textField: "lead_status",
    dataItemKey: "lead_status_id",
  },
  supplierStatus: {
    lookupPath: "supplier-status",
    textField: "supplier_status",
    dataItemKey: "supplier_status_id",
  },
  dateYerField: {
    lookupPath: "date-year-month",
    textField: "month_name",
    dataItemKey: "date_id",
  },
  statsDate: {
    lookupPath: "patient-stats-date",
    textField: "calendar_date",
    dataItemKey: "date_id",
  },
  customFieldDataType: {
    lookupPath: "custom-field-data-type",
    textField: "data_type",
    dataItemKey: "data_type_id",
  },
  customFieldInputType: {
    lookupPath: "custom-field-input-type",
    textField: "input_type",
    dataItemKey: "input_type_id",
  },
  customFieldLinkExistingDropDown: {
    lookupPath: "custom-field-link-existing-dropdown",
    textField: "list_name",
    dataItemKey: "primary_key_id",
  },
  employeeLeaveType: {
    lookupPath: "employee-leave-type",
    textField: "employee_leave_type",
    dataItemKey: "employee_leave_type_id",
  },
  employeeAllowanceType: {
    lookupPath: "employee-allowance-type",
    textField: "employee_allowance_type",
    dataItemKey: "employee_allowance_type_id",
  },
  employeeDeductionType: {
    lookupPath: "employee-deduction-type",
    textField: "employee_deduction_type",
    dataItemKey: "employee_deduction_type_id",
  },
  serviceReminderProducts: {
    lookupPath: "service-reminder-products",
    textField: "product_name",
    dataItemKey: "product_entity_id",
  },
  ProductDealsOffer: {
    lookupPath: "product-deals-offer",
    textField: "product_name",
    dataItemKey: "product_entity_id",
  },
  ProductChildCategory: {
    lookupPath: "inv-product-child-category",
    textField: "category_name",
    dataItemKey: "category_entity_id",
  },
  document: {
    lookupPath: "file-management-list",
    textField: "user_file_name",
    dataItemKey: "doc_entity_id",
  },
  language: {
    lookupPath: "language",
    textField: "language_name",
    dataItemKey: "language_id",
  },
  caste: {
    lookupPath: "caste",
    textField: "caste_name",
    dataItemKey: "caste_id",
  },
  religion: {
    lookupPath: "religion",
    textField: "religion_name",
    dataItemKey: "religion_id",
  },
  relationship: {
    lookupPath: "relationship",
    textField: "relationship_name",
    dataItemKey: "relationship_type_id",
  },
  onlyYear: {
    lookupPath: "only-year",
    textField: "calendar_year",
    dataItemKey: "date_id",
  },
  qualification: {
    lookupPath: "qualification",
    textField: "qualification_name",
    dataItemKey: "qualification_id",
  },
  facilityType: {
    lookupPath: "facility-type",
    textField: "facility_type",
    dataItemKey: "facility_type_id",
  },
  imageDocCategory: {
    lookupPath: "doc_category_add_image",
    textField: "document_category",
    dataItemKey: "doc_category_id",
  },
  categoryProductDeal: {
    lookupPath: "category-product-deal",
    textField: "user_file_name",
    dataItemKey: "doc_entity_id",
  },
  allStudents: {
    lookupPath: "all-students",
    textField: "entity_name",
    dataItemKey: "entity_id",
  },
  vehicleUsage: {
    lookupPath: "vehicle-usage",
    textField: "usage_type",
    dataItemKey: "usage_type_id",
  },
  fiscalYear: {
    lookupPath: "fiscal-year",
    textField: "fiscal_year",
    dataItemKey: "fiscal_year_entity_id",
  },
  casteName: {
    lookupPath: "caste",
    textField: "caste_name",
    dataItemKey: "caste_name",
  },
  religionName: {
    lookupPath: "religion",
    textField: "religion_name",
    dataItemKey: "religion_name",
  },
  qualificationName: {
    lookupPath: "qualification",
    textField: "qualification_name",
    dataItemKey: "qualification_name",
  },
  languageName: {
    lookupPath: "language",
    textField: "language_name",
    dataItemKey: "language_name",
  },
  designationName: {
    lookupPath: "designation",
    textField: "designation_name",
    dataItemKey: "designation_name",
  },
  dynamicGrid: {
    lookupPath: "dynamic-grid",
    textField: "screen_name",
    dataItemKey: "screen_id",
  },
  countryName: {
    lookupPath: "country",
    textField: "country",
    dataItemKey: "country_id",
  },
  module: {
    lookupPath: "module",
    textField: "module_name",
    dataItemKey: "module_id",
  },
  user: {
    lookupPath: "user",
    textField: "login_name",
    dataItemKey: "user_id",
  },
  productBatch: {
    lookupPath: "product-batch",
    textField: "batch_name",
    dataItemKey: "product_batch_id",
  },
  productMaintainInventory: {
    lookupPath: "product-maintain-inventory",
    textField: "product_name",
    dataItemKey: "product_entity_id",
  },
  employeeLoanType: {
    lookupPath: "employee-loan-type",
    textField: "employee_loan_type",
    dataItemKey: "employee_loan_type_id",
  },
  projectStatus: {
    lookupPath: "project-status",
    textField: "project_status",
    dataItemKey: "project_status_id",
  },
  salaryType: {
    lookupPath: "salary-type",
    textField: "salary_type",
    dataItemKey: "salary_type_id",
  },
  templateEntityType: {
    lookupPath: "template-entity-type",
    textField: "act_entity_type",
    dataItemKey: "act_entity_type_id",
  },
  paymentDue: {
    lookupPath: "client-with-payment-due",
    textField: "client_name",
    dataItemKey: "subject_entity_id",
  },
  dateYerMonthFieldV1: {
    lookupPath: "date-year-month-v1",
    textField: "month_name",
    dataItemKey: "date_id",
  },
  productTypeSectionMapping: {
    lookupPath: "v1/product-type-section-mapping",
    textField: "product_section",
    dataItemKey: "product_section",
  },
  productTypeAttribute: {
    lookupPath: "v1/product-type",
    textField: "product_type",
    dataItemKey: "product_type_id",
  },
  domain: {
    lookupPath: "domain",
    textField: "domain_name",
    dataItemKey: "domain_id",
  },
  orgDomain: {
    lookupPath: "org-setup-domain",
    textField: "domain_url",
    dataItemKey: "domain_id",
  },
  attributeName: {
    lookupPath: "attribute-name",
    textField: "attribute_name",
    dataItemKey: "attribute_id",
  },
  attributeType: {
    lookupPath: "attribute-type",
    textField: "attribute_type",
    dataItemKey: "attribute_type_id",
  },
  productSections: {
    lookupPath: "product-sections",
    textField: "product_section",
    dataItemKey: "product_section_id",
  },
  country: {
    lookupPath: "country",
    textField: "country",
    dataItemKey: "country_id",
  },
  colour: {
    lookupPath: "colour",
    textField: "colour_name",
    dataItemKey: "colour_id",
  },
  inventoryProductCategory: {
    lookupPath: "inv-product-category",
    textField: "category_name",
    dataItemKey: "entity_id",
  },
  faqType: {
    lookupPath: "org-web-faq-section",
    textField: "faq_section",
    dataItemKey: "faq_section_id",
  },
  orgParentEntity: {
    lookupPath: "v1/org-parent-entity",
    textField: "parent_entity_name",
    dataItemKey: "parent_entity_id",
  },
  invParentAttribute: {
    lookupPath: "v1/inv-parent-attribute",
    textField: "attribute_name",
    dataItemKey: "parent_attribute_id",
  },
  productDepartment: {
    lookupPath: "product-department",
    textField: "department_name",
    dataItemKey: "entity_id",
  },
  privacyType: {
    lookupPath: "privacy-type",
    textField: "privacy_type",
    dataItemKey: "privacy_type_id",
  },
  bank: {
    lookupPath: "bank",
    textField: "bank_name",
    dataItemKey: "bank_account_entity_id",
  },
  allCommunicationEntities: {
    lookupPath: "all-communication-entities",
    textField: "raw_entity_name",
    dataItemKey: "entity_id",
  },
  capacityUnit: {
    lookupPath: "capacity-unit",
    textField: "capacity_unit",
    dataItemKey: "capacity_unit_id",
  },
  defaultFacility: {
    lookupPath: "facility",
    textField: "facility_name",
    dataItemKey: "facility_entity_id",
  },
  defaultFacilityNew: {
    lookupPath: "org-facility-v1",
    textField: "facility_name",
    dataItemKey: "facility_entity_id",
  },
  timeZone: {
    lookupPath: "org-time-zone",
    textField: "time_zone",
    dataItemKey: "time_zone_id",
  },
  cityLocality: {
    lookupPath: "city-locality",
    textField: "city_locality",
    dataItemKey: "city_locality_type_id",
  },
  cityLocalityV1: {
    lookupPath: "city-locality-v1",
    textField: "city_locality",
    dataItemKey: "city_locality_type_id",
  },
  deliveryType: {
    lookupPath: "delivery-type",
    textField: "delivery_type",
    dataItemKey: "delivery_type_id",
  },
  deliveryAgent: {
    lookupPath: "agent",
    textField: "agent_name",
    dataItemKey: "agent_entity_id",
  },
  deliveryCharges: {
    lookupPath: "delivery-charges",
    textField: "delivery_rate",
    dataItemKey: "delivery_rate_id",
  },
  organizationFacility: {
    lookupPath: "organization-facility",
    textField: "facility_name",
    dataItemKey: "facility_entity_id",
  },
  soaBatch: {
    lookupPath: "soa-batch",
    textField: "batch_date",
    dataItemKey: "soa_batch_id",
  },
  organizationContact: {
    lookupPath: "organization-contact",
    textField: "contact_name",
    dataItemKey: "contact_person_id",
  },
  accountEntityStatus: {
    lookupPath: "account-entity-status",
    textField: "act_entity_status",
    dataItemKey: "act_entity_status_id",
  },
  quotePrefix: {
    lookupPath: "quote-prefix",
    textField: "quote_prefix",
    dataItemKey: "quote_prefix_id",
  },
  deliveryTerms: {
    lookupPath: "delivery-terms",
    textField: "delivery_terms",
    dataItemKey: "delivery_terms_id",
  },
  purchaseInvoicePrefix: {
    lookupPath: "purchase-invoice-prefix",
    textField: "purchase_invoice_prefix",
    dataItemKey: "purchase_invoice_prefix_id",
  },
  salseInvoicePrefix: {
    lookupPath: "sales-invoice-prefix",
    textField: "sales_invoice_prefix",
    dataItemKey: "sales_invoice_prefix_id",
  }, 
  salesInvoiceClientsData: {
    lookupPath: "sales-invioce-clients",
    textField: "client_name",
    dataItemKey: "client_entity_id",
  },
  clientDeliveryOrder: {
    lookupPath: "delivery-order",
    textField: "delivery_order_number",
    dataItemKey: "act_entity_id",
  },
  clientDeliveryLocation: {
    lookupPath: "client-delivery-location",
    textField: "facility_name",
    dataItemKey: "delivery_facility_entity_id",
  },
  deliveryOrderPrefix: {
    lookupPath: "delivery-order-prefix",
    textField: "delivery_order_prefix",
    dataItemKey: "delivery_order_prefix_id",
  },
  reminderTypeId: {
    lookupPath: "reminder-type",
    textField: "reminder_type",
    dataItemKey: "reminder_type_id",
  },
  accessLevel: {
    lookupPath: "communication-level",
    textField: "access_level",
    dataItemKey: "access_level_id",
  },
  eventType: {
    lookupPath: "event-type",
    textField: "event_type",
    dataItemKey: "event_type_id",
  },
  messagePlatform: {
    lookupPath: "communication-platform",
    textField: "message_platform",
    dataItemKey: "message_platform_id",
  },
  facilityClientSite: {
    lookupPath: "facility-client-site",
    textField: "facility_name",
    dataItemKey: "facility_entity_id",
  },
  facilityClientCamp: {
    lookupPath: "facility-client-camp",
    textField: "facility_name",
    dataItemKey: "facility_entity_id",
  },
  attributeGroup: {
    lookupPath: "attribute-group",
    textField: "attribute_group",
    dataItemKey: "attribute_group_id",
  },
  productLabel: {
    lookupPath: "product-label",
    textField: "label_name",
    dataItemKey: "label_id",
  },
  productTypeLabel: {
    lookupPath: "product-type-by-label",
    textField: "label_name",
    dataItemKey: "label_id",
  },
  productAttributeGroup: {
    lookupPath: "product-attribute-group",
    textField: "attribute_group",
    dataItemKey: "attribute_group_id",
  },
  productAttributeGroupType: {
    lookupPath: "product-attribute-group-type",
    textField: "attribute_type",
    dataItemKey: "attribute_type_id",
  },
  parentProduct: {
    lookupPath: "parent-products",
    textField: "parent_product_name",
    dataItemKey: "parent_product_entity_id",
  },
  weekDays: {
    lookupPath: "week-days",
    textField: "week_day",
    dataItemKey: "week_day_number",
  },
  transportService: {
    lookupPath: "transport-service",
    textField: "entity_number",
    dataItemKey: "act_entity_id",
  },
  communicationEntityList: {
    lookupPath: "communication-entity-list",
    textField: "full_name",
    dataItemKey: "entity_id",
  },
  supplierProcess: {
    lookupPath: "supplier-process",
    textField: "raw_entity_name",
    dataItemKey: "entity_id",
  },
  supplierDelivery: {
    lookupPath: "supplier-delivery",
    textField: "raw_entity_name",
    dataItemKey: "entity_id",
  },
  supplierType: {
    lookupPath: "supplier-type",
    textField: "supplier_type",
    dataItemKey: "supplier_type_id",
  },
};

export function getLookupType(lookupType) {
  const lookup = lookupMap[lookupType];

  if (!lookup) throw new Error(`No lookup of type: ${lookupType} found`);

  return lookup;
}

export function getLookupKey(lookupType) {
  const lookup = lookupMap[lookupType];

  if (!lookup) throw new Error(`No lookup of type: ${lookupType} found`);

  return ["lookup", lookup.lookupPath];
}

export default lookupMap;
