import React from "react";
import AuthWrap from "../Common/AuthWrap";
import ResetPasswordInfo from "./ResetPasswordInfo";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPassword() {
  return (
    <AuthWrap Left={<ResetPasswordInfo />} Right={<ResetPasswordForm />} />
  );
}
