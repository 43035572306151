import { queryCache } from "react-query";
import { useAuthDispatch } from "../Auth/Context/AuthContext";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export default function useLogout() {
  const history = useHistory();
  const authDispatch = useAuthDispatch();

  const handleLogout = useCallback(() => {
    history.push("/login");
    queryCache.clear();
    authDispatch({ type: "logout" });
  }, [history, authDispatch]);

  return handleLogout;
}
