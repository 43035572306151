import { useState, useEffect, useCallback } from "react";

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY;
window.onloadCallback = null;

export default function useCaptcha(renderId) {
  const [rendered, setRendered] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [token, setToken] = useState(null);

  const verifyCallback = useCallback((response) => setToken(response), []);

  const expiredCallback = useCallback(() => setToken(null), []);

  const resetCaptcha = useCallback(() => {
    window.grecaptcha.reset();
  }, []);

  window.onloadCallback = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (isLoaded && !rendered) {
      window.grecaptcha.render(renderId, {
        sitekey: recaptchaSiteKey,
        callback: verifyCallback,
        "expired-callback": expiredCallback,
      });
      setRendered(true);
    }
  }, [isLoaded, renderId, rendered, verifyCallback, expiredCallback]);

  return { token, resetCaptcha };
}
