import React from "react";
import { formatters } from "../../../../lib";

const { formatDate } = formatters;

export const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: "20px",
          bottom: "10px",
          padding: "10px",
        }}
      >
        Print:{" "}
        {formatDate(new Date(), { skeleton: "yMMMd" }) +
          " " +
          formatDate(new Date(), { time: "short" })}
      </div>
      <div
        style={{
          position: "absolute",
          right: "20px",
          bottom: "10px",
          padding: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

export { getSalesDeliveryOrderReport } from "./deliveryOrderQueries";
