import React from "react";
import { SectionWrap, ScreenWrap } from "../../Common";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ChangePasswordForm() {
  return (
    <SectionWrap>
      <ScreenWrap>
        <ResetPasswordForm resetting={false} />
      </ScreenWrap>
    </SectionWrap>
  );
}
