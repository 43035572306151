import { useQuery } from "react-query";
import { axios } from "../../lib";

async function getDynamicCols(apiRoute) {
  const { data } = await axios.get(apiRoute,{headers:{entity_id:null}});
  return data.dynamic_grid;
}

export default function useDynamicColumns(apiRoute) {
  const { data, isFetching, ...queryState } = useQuery(
    apiRoute,
    getDynamicCols
  );

  const filtered = Array.isArray(data) ? data.filter((e) => e.visible) : [];

  const columns = filtered
    .map((e) => ({
      title: e.ui_label,
      field: e.ui_field,
      width: e.width ? e.width : undefined,
      order: e.column_position,
    }))
    .sort((a, b) => a.order - b.order);

  return { columns, columnsLoading: isFetching, ...queryState };
}
