import React from "react";
import AuthWrap from "../Common/AuthWrap";
import ForgotPasswordInfo from "./ForgotPasswordInfo";
import ForgotPasswordForm from "./ForgotPasswordForm";

export default function ForgotPassword() {
  return (
    <AuthWrap Left={<ForgotPasswordInfo />} Right={<ForgotPasswordForm />} />
  );
}
