import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { getDateRange } from "../../lib";
import * as Paths from "../Routers/Paths";

const getDates = () => {
  const [start_date, end_date] = getDateRange(1, "years");
  return { start_date, end_date };
};

export const linksMap = {
  "create-purchase_invoice": (supplier_entity_id) => ({
    path: `${Paths.Purchase_Invoice}/add`,
    state: { supplier_entity_id },
  }),
  "create-invoice": (client_entity_id) => ({
    path: `${Paths.Sales_Invoice}/add`,
    state: { client_entity_id },
  }),
  "create-quote": (client_entity_id) => ({
    path: `${Paths.Quote}/add`,
    state: { client_entity_id },
  }),
  "create-service": (client_entity_id) => ({
    path: `${Paths.Service}/add`,
    state: { client_entity_id },
  }),
  "send-message": (entityId) => ({
    path: `${Paths.Exam_Result1}/add`,
    state: { entityId},
  }),
  "send-payment": (editId) => ({
    path: `${Paths.Payable}/edit`,
    state: { editId },
  }),
  "receive-payment": (editId) => ({
    path: `${Paths.Receivable}/edit`,
    state: { editId },
  }),
  "view-invoice-history": (subject_entity_id) => ({
    path: `${Paths.Sales_Invoice}`,
    state: { filters: { subject_entity_id,  show_full_paid:0, ...getDates() } },
  }),
  "view-message-history": (account_entity_id) => ({
    path: `${Paths.Exam_Result1}`,
    state: { filters: { entity_type: "allEntities", account_entity_id, ...getDates() } },
  }),
  "view-purchase_invoice-history": (subject_entity_id) => ({
    path: `${Paths.Purchase_Invoice}`,
    state: { filters: { subject_entity_id, show_full_paid:0, ...getDates() } },
  }),
  "view-quote-history": (subject_entity_id) => ({
    path: `${Paths.Quote}`,
    state: { filters: { subject_entity_id, ...getDates() } },
  }),
  "view-service-history": (subject_entity_id) => ({
    path: `${Paths.Service}`,
    state: { filters: { subject_entity_id, show_full_paid:0, ...getDates() } },
  }),
  "view-bank_cheque-history-client": (client_entity_id) => ({
    path: `${Paths.Bank_Transaction}`,
    state: { filters: { client_entity_id, ...getDates() } },
  }),
  "view-bank_cheque-history-supplier": (supplier_entity_id) => ({
    path: `${Paths.Bank_Transaction}`,
    state: { filters: { supplier_entity_id, ...getDates() } },
  }),
  "view-transactions-history-client": (client_entity_id) => ({
    path: `${Paths.Transactions}`,
    state: { filters: { client_entity_id, ...getDates() } },
  }),
  "view-transactions-history-supplier": (supplier_entity_id) => ({
    path: `${Paths.Transactions}`,
    state: { filters: { supplier_entity_id, ...getDates() } },
  }),
  "track-employee": (childEntityId) => ({
    path: `${Paths.Tracking}`,
    state: { parentEntityName: "All Employees", childEntityId },
  }),
  "add-appointment": (entityId) => ({
    path: `${Paths.Appointmemt}`,
    state: { entityId},
  }),
  "view-auto-repair-history": (subject_entity_id) => ({
    path: `${Paths.Auto_Repair}`,
    state:  { filters: { vehicle_entity_id:null,subject_entity_id,show_full_paid:0, ...getDates() } },
  }),
  "view-car-wash-history": (subject_entity_id) => ({
    path: `${Paths.Car_Wash}`,
    state:  { filters: { vehicle_entity_id:null, subject_entity_id, show_full_paid:0,...getDates() } },
  }),
  "view_auto-repair-history": (subject_entity_id) => ({
    path: `${Paths.Auto_Repair}`,
    state:  { filters: { subject_entity_id:null, vehicle_entity_id:subject_entity_id,show_full_paid:0, ...getDates() } },
  }),
  "view_car-wash-history": (subject_entity_id) => ({
    path: `${Paths.Car_Wash}`,
    state:  { filters: { subject_entity_id:null, vehicle_entity_id:subject_entity_id,show_full_paid:0, ...getDates() } },
  }),
  "view_invoice-history": (subject_entity_id) => ({
    path: `${Paths.Sales_Invoice}`,
    state:  { filters: { subject_entity_id:null, vehicle_entity_id:subject_entity_id,show_full_paid:0, ...getDates() } },
  }),
  "view_expense-history": (vehicle_entity_id) => ({
    path: `${Paths.Expense}`,
    state:  { filters: { subject_entity_id:null, vehicle_entity_id:vehicle_entity_id, ...getDates() } },
  }),
  "view-vehicle_invoice-history": (subject_entity_id) => ({
    path: `${Paths.Auto_Repair}`,
    state:  { filters: { subject_entity_id:subject_entity_id, vehicle_entity_id:null,show_full_paid:0, ...getDates() } },
  }),
};

export default function useLinkOptions(selectedId, options = []) {
  const history = useHistory();

  const linkToScreen = useCallback(
    (id, option) => {
      const { option_key, option_name } = option;
      const linkFunction = linksMap[option_key];

      if (!linkFunction) {
        return null;
      }

      const { path, state } = linkFunction(id);

      return {
        id: option_key,
        text: option_name,
        onClick: () => history.push(path, state),
        disabled: !id,
      };
    },
    [history]
  );

  return options
    .map((option) => linkToScreen(selectedId, option))
    .filter((e) => e !== null);
}
