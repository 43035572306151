import React, { useMemo } from "react";
import CrudGrid from "../../Grids/CrudGrid";
import useSelectionColumn from "../../Hooks/useSelectionColumn";
import { getSalesList, salesGridFilters, getSalesReport } from "./salesQueries";
import { useFiltersModal } from "../../Grids";
import SalesFilter from "./SalesFilter";
import useGridOptions from "../../Hooks/useGridOptions";
import useInvoicePdfOptions from "../../Hooks/useInvoicePdfOptions";
import useCustomScreenOptions from "../../Hooks/useCustomScreenOptions";
import { useScreenId } from "../../Routers/ScreenRouter/ScreenContext";
import usePrint from "../DeliveryOrder/DeliveryOrderPDF/usePrint";
import InvisiblePrintWrap from "components/Common/InvisiblePrintWrap";
import { PageTemplate } from "../DeliveryOrder/DeliveryOrderPDF";

export const salesGridQueryPredicate = (query) =>
  query.queryKey[0]?.isSalesGrid;

export default function SalesGrid({
  idKey,
  nameKey,
  columns,
  filters: propFilters,
  gridTitle,
  gridFilterKey,
  isLoading = false,
  listFunction = getSalesList,
  getGridOptions = () => [],
  ...restProps
}) {
  // Filters
  const { gridFilters, filterButtonProps, filterDialogProps } = useFiltersModal(
    gridFilterKey,
    salesGridFilters
  );

  // Columns
  const { selected, selectedId, selectColumn, isSelected } = useSelectionColumn(
    idKey
  );
  const cols = useMemo(() => [selectColumn, ...columns], [
    selectColumn,
    columns,
  ]);

  // Options
  // Get raw options for current screen id
  const screen_id = useScreenId();
  const { rawOptions, isLoading: ILO } = useGridOptions(screen_id);

  // Get the option for generating invoice pdf
  const {
    invoicePdfOptions,
    Component,
    isLoading: ILPDF,
  } = useInvoicePdfOptions(rawOptions, selected['doc_entity_id'], selected[nameKey]);

  // Get any options from parent components like Convert Quote to Invoice and Delivery order
  // If the option is common between all Sales Grids then add it to this component
  // otherwise use this prop to send that option
  const propAllOptions = isSelected ? getGridOptions(selected) : [];
  const {
    options: propOptions,
    isLoadingOptions: ILCO,
  } = useCustomScreenOptions(
    Array.isArray(propAllOptions) ? propAllOptions : []
  );

  // const {
  //   linkRef,
  //   printRef,
  //   linkData,
  //   printData,
  //   isLoading: pdfloading,
  //   fetchReport,
  // } = usePrint(getSalesReport);


  // const gridOptionsCallback = React.useCallback(
  //   (selected) => [
  //     {
  //       id: "print_delivery-order", // the id for option in database. Must match exactly
  //       disabled: !selected?.act_entity_id,
  //       onClick: () => fetchReport({ entityId: selected.doc_entity_id }),
  //     }
  //   ],
  //   []
  // );

  // const propAllOpt = isSelected ? gridOptionsCallback(selected) : [];
  // const {
  //   options,
  //   isLoadingOptions: ILCO,
  // } = useCustomScreenOptions(
  //   Array.isArray(propAllOptions) ? propAllOptions : []
  // );

  return (
    <>
      <Component />
      <SalesFilter title={gridTitle} {...filterDialogProps} />
      <CrudGrid
        gridOptions={[...invoicePdfOptions, ...propOptions]}
        title={`${gridTitle} List`}
        idKey={idKey}
        nameKey={nameKey}
        filters={{ ...gridFilters, ...propFilters, isSalesGrid: true }}
        isLoading={isLoading || ILPDF || ILO || ILCO}
        listFunction={listFunction}
        columns={cols.map((e) => ({ ...e, tableData: undefined }))}
        {...restProps}
        toolbarProps={{
          ...filterButtonProps,
          ...restProps.toolbarProps,
        }}
      />
    </>
  );
}
