import React, { Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import PageLoader from "../../Common/PageLoader";
import SectionWrap from "../../Common/SectionWrap";
import { ScreenProvider, useScreen } from "./ScreenContext";
import ScreenComponentMap from "./ScreenComponentMap";

function DefaultScreenComponent({ gridPath }) {
  return <div>Screen: {gridPath}</div>;
}

function SetScreenId({ screen }) {
  const [, setScreen] = useScreen();

  useEffect(() => {
    localStorage.setItem("screen_id", screen.screen_id);
    setScreen({ ...screen });
    return () => localStorage.removeItem("screen_id");
  }, [setScreen, screen]);

  return null;
}

export default function ScreenRouter({ screens }) {
  return (
    <Suspense fallback={<PageLoader />}>
      <SectionWrap>
        <ScreenProvider>
          <Switch>
            {screens.map((e) => {
              const path = `/${e.screen_link.toLowerCase()}`;
              const Component = ScreenComponentMap[path]
                ? ScreenComponentMap[path]
                : DefaultScreenComponent;
              return (
                <Route key={e.screen_link} path={path}>
                  <SetScreenId screen={e} />
                  <Component gridPath={path} />
                </Route>
              );
            })}
          </Switch>
        </ScreenProvider>
      </SectionWrap>
    </Suspense>
  );
}
