import React from "react";
import { FMAutoCompletePlain } from ".";
import useChildLookup from "../Hooks/useChildLookup";

export default function FMChildAutoComplete({
  name,
  label,
  parentId,
  parentKey,
  lookupType,
  convertValueToId = false,
  ...restProps
}) {
  const { lookup, textField, dataItemKey, isFetching } = useChildLookup({
    lookupType: lookupType || "childEntity",
    headers: { [parentKey]: parentId },
    enabled: !!parentId,
  });

  return (
    <FMAutoCompletePlain
      disabled={!parentId}
      name={name}
      label={label}
      options={lookup}
      textField={textField}
      dataItemKey={dataItemKey}
      loading={isFetching}
      convertValueToId={convertValueToId}
      {...restProps}
    />
  );
}
