import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import Typography from "@material-ui/core/Typography";

export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <FontAwesomeIcon icon={faBan} style={{ fontSize: 32 }} />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <Typography style={{ fontWeight: 500 }} variant="h5">
        Invalid URL. Page Not Found
      </Typography>
    </div>
  );
}
