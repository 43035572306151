import React from "react";
import { useTheme } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export default function BooleanFlag({ flag }) {
  const theme = useTheme();
  const success = theme.palette.success.main;
  if (flag) return <CheckCircleIcon style={{ color: success }} />;
  return <CancelIcon color="error" />;
}
