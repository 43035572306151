import React, { useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { makeStyles, Grid } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { FMTextField } from "../../Form";
import LinearLoader from "../../Common/LinearLoader";
import AuthHeading from "../Common/AuthHeading";
import AuthLink from "../Common/AuthLink";
import useLogin from "./useLogin";
import useCaptcha from "./useCaptcha";
import ApiErrorAlert from "../../Common/ApiErrorAlert";
import logo from './TansyCloud.png'

const LoginSchema = Yup.object().shape({
  login_name: Yup.string().required("Login ID is required"),
  login_password: Yup.string()
    .required("Password is required")
    .min(6, "Password should be at least 6 characters"),
  remember: Yup.boolean(),
});

const useStyles = makeStyles((theme) => ({
  showPassButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: "50%",
    transform: "translateY(-50%)",
  },
  hide:{
    [theme.breakpoints.down("sm")]: {
      display:'none'
    },

  },
  input:{
    
    [theme.breakpoints.up("md")]: {
      display:'none'
    },
  },
  img_div:{
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    flexBasis:'100%',
    width:'full',
    justifyContent:'center',
    [theme.breakpoints.up("md")]: {
      display:'none'
    },
    
  },
  copyright:{
    fontSize:'15px',
    textAlign:'center',
    color:'black',
    paddingTop:'20px',
    paddingBottom:'20px',
    [theme.breakpoints.up("md")]: {
      display:'none'
    },
    
  },
  logo:{
    width:'120px',
    height:'150px',
    justifyContent:'center',
    textAlign:'center',
    alignItems:'center',
    paddingBottom:'30px'
  },
  showPassIcon: {
    fontSize: 18,
  },
  inputRoot: {
    paddingRight: 0,
  },
}));

export default function LoginForm() {
  const classes = useStyles();
  const [showPassword, setShowPassword] = React.useState(false);
  const [login, { error, isLoading, isError, reset }] = useLogin();

  const toggleVisibility = () => setShowPassword((v) => !v);

  const { token, resetCaptcha } = useCaptcha("login-captcha");

  useEffect(() => {
    if (isError) resetCaptcha();
  }, [isError, resetCaptcha]);

  return (
    <Formik
      validationSchema={LoginSchema}
      initialValues={{ login_name: "", login_password: "" }}
      onSubmit={(values) =>
        login({ ...values, login_media: "webapp", captcha_token: token })
      }
    >
      {(fkProps) => (
        <>
        <Form>
          <AuthHeading>Sign In</AuthHeading>
          <div className={classes.img_div}>
          <img src={logo} className={classes.logo} alt="heading"/>
          </div>
          <ApiErrorAlert error={error} loading={isLoading} />
          <LinearLoader loading={isLoading} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
            <InputLabel className={classes.input}>
                  Username
                  <FMTextField
                onChange={(e) => {
                  fkProps.handleChange(e);
                  if (isError) reset();
                }}
                
                variant="outlined"
                size="small"
                name="login_name"
                aria-required="true"
              />
                  </InputLabel>
                  <div className={classes.hide}>
                  <FMTextField
                onChange={(e) => {
                  fkProps.handleChange(e);
                  if (isError) reset();
                }}
                label="Login ID"
                variant="outlined"
                size="small"
                name="login_name"
                aria-required="true"
              />
                  </div>
              
            </Grid>
            <Grid item xs={12}>
              <ClickAwayListener onClickAway={() => setShowPassword(false)}>
                <div>
                  <InputLabel className={classes.input}>
                  Password
                  <FMTextField
                    onChange={(e) => {
                      fkProps.handleChange(e);
                      if (isError) reset();
                    }}
                    
                    variant="outlined"
                    size="small"
                    name="login_password"
                    type={showPassword ? "text" : "password"}
                    aria-required="true"
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                      },
                      endAdornment: (
                        <IconButton
                          size="small"
                          className={classes.showPassButton}
                          onClick={toggleVisibility}
                        >
                          {showPassword ? (
                            <VisibilityOff className={classes.showPassIcon} />
                          ) : (
                            <Visibility className={classes.showPassIcon} />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  </InputLabel>
                  <div className={classes.hide}>
                  <FMTextField
                    onChange={(e) => {
                      fkProps.handleChange(e);
                      if (isError) reset();
                    }}
                    label="Password"
                    variant="outlined"
                    size="small"
                    name="login_password"
                    type={showPassword ? "text" : "password"}
                    aria-required="true"
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                      },
                      endAdornment: (
                        <IconButton
                          size="small"
                          className={classes.showPassButton}
                          onClick={toggleVisibility}
                        >
                          {showPassword ? (
                            <VisibilityOff className={classes.showPassIcon} />
                          ) : (
                            <Visibility className={classes.showPassIcon} />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  </div>
                </div>
              </ClickAwayListener>
            </Grid>
            <Grid item xs={12}>
              <AuthLink to="/forgot-password" text="Forgot Password?" />
            </Grid>
            <Grid item xs={12}>
              <div id="login-captcha"></div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                disableElevation
                disabled={isLoading || isError || !token}
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
              >
                Login
              </Button>
            </Grid>
           
          </Grid>
        </Form>
        
        <p className={classes.copyright}>All rights reserved 2022  |  <span style={{color:'blue'}}>www.tancycloud.com</span></p>
        
        </>
      )}
    </Formik>
  );
}
