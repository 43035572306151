import React from "react";
import usePrint from "./usePrint";
import {
  getVoucherDetails,
  PrintVoucher,
} from "../Accounting/AccountingCommon";
import { InvisiblePrintWrap } from "../Common";

const voucherPdfOptions = ["export-pdf-voucher"];

export default function useVoucherPdfOptions(
  options = [],
  { batch_id, batch_type },
  fileName
) {
  const {
    printRef,
    printData,
    linkRef,
    linkData,
    isLoading,
    fetchReport,
  } = usePrint(getVoucherDetails);

  const pdfOptions = options
    .filter((e) => voucherPdfOptions.includes(e.option_key))
    .map((e) => {
      const { option_key, option_name } = e;
      return {
        id: option_key,
        text: option_name,
        onClick: () => fetchReport({ batch_id, batch_type }),
        disabled: !(batch_id || batch_type),
      };
    });

  const Component = () => (
    <InvisiblePrintWrap
      printRef={printRef}
      linkRef={linkRef}
      linkData={linkData}
      config={{
        paperSize: "A4",
        scale: 0.7,
        fileName,
        margin: { bottom: 20, top: 0, left: 0, right: 0 },
      }}
    >
      {printData ? <PrintVoucher data={printData} /> : null}
    </InvisiblePrintWrap>
  );

  return {
    voucherPdfOptions: pdfOptions,
    Component: Component,
    isLoading,
  };
}
