import {
  faBoxes,
  faCogs,
  faDiceD6,
  faFileInvoice,
  faUserTie,
  faUsers,
  faUserCircle,
  faMapMarkerAlt,
  faEnvelope,
  faSms, 
  faEdit,
  faListOl,
  faFileInvoiceDollar,
  faFileImport,
  faSyncAlt,
  faSlidersH,
  faFilePdf,
  faUserClock,
  faDollarSign,
  faWallet,
  faBook,
  faTasks,
  faBullhorn,
  faCarAlt,
  faTools,
  faFunnelDollar,
  faGavel,
  faFileUpload,
  faPaste,
  faBell,
  faChevronCircleDown,
  faBuilding,
  faCity,
  faUniversity,
  faMap,
  faUpload,
  faUser,
  faLayerGroup,
  faCalendarCheck,
  faCalendar,
  faHandHoldingUsd,
  faChartPie,
  faMoneyBillAlt,
  faTable,
  faClipboardList,
  faMoneyBillWave,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import * as Paths from "../Paths";
import { Path } from "@progress/kendo-drawing";

const screenIconMap = {
  [Paths.BusinessDashboard]: faChartPie,
  [Paths.TicketsDashboard]: faChartPie,
  [Paths.Accounts]: faBook,
  [Paths.Bank]: faUniversity,
  [Paths.City]: faCity,
  [Paths.Custom_Fields]: faCogs,
  [Paths.Email_Template]: faPaste,
  [Paths.Lookups]: faChevronCircleDown,
  [Paths.Organization]: faBuilding,
  [Paths.Service_Status]: faCogs,
  [Paths.SMS_Template]: faSms,
  [Paths.Tax]: faGavel,
  [Paths.Dynamic_Grids]: faTable,
  [Paths.Whatsapp_Template]: faWhatsapp,
  [Paths.Bulk_Load]: faUpload,
  [Paths.Entity]: faLayerGroup,
  [Paths.Map_Entity]: faMap,
  [Paths.User]: faUser,
  [Paths.Bulk_Update]: faEdit,
  [Paths.Employee]: faUserTie,
  [Paths.Client]: faUsers,
  [Paths.Supplier]: faUserCircle,
  [Paths.Tracking]: faMapMarkerAlt,
  [Paths.Attendance]: faCalendarCheck,
  [Paths.Monthly_Log]: faCalendar,
  [Paths.Product]: faBoxes,
  [Paths.Product_Type]: faCogs,
  [Paths.Purchase_Invoice]: faFileInvoice,
  [Paths.Lead]: faFunnelDollar,
  [Paths.offer]:faDollarSign,
  [Paths.Quote]: faListOl,
  [Paths.Auto_Repair]: faTools,
  [Paths.Car_Wash]: faCarAlt,
  [Paths.Post_Invoice]: faFileImport,
  [Paths.Recurring_Invoice]: faSyncAlt,
  [Paths.Sales_Invoice]: faFileInvoiceDollar,
  [Paths.Service]: faHandHoldingUsd,
  [Paths.Invoice_Adjustment]: faSlidersH,
  [Paths.Product_Offer]: faBell,
  [Paths.Sales_Listing]: faFilePdf,
  [Paths.Service_Reminder]: faUserClock,
  [Paths.Reminder]:faUserClock,
  [Paths.Invoice_Template]: faClipboardList,
  [Paths.Expense]: faMoneyBillAlt,
  [Paths.EMI]:faMoneyBillWave,
  
  [Paths.Payable]: faWallet,
  [Paths.Payable_Due]: faFilePdf,
  [Paths.Receivable]: faDollarSign,
  [Paths.Receivable_Due]: faFilePdf,
  [Paths.File_Upload]: faFileUpload,
  [Paths.Tickets]: faTasks,
  [Paths.Email_Template]: faEnvelope,
  [Paths.Send_Message]: faBullhorn,
  [Paths.School_Class]: faDiceD6,
  [Paths.Subject]: faDiceD6,
  [Paths.Teacher_Mapping]: faDiceD6,
  [Paths.Admission]: faDiceD6,
  [Paths.Student]: faDiceD6,
  [Paths.Student_Listing]: faFilePdf,
  [Paths.Exam]: faDiceD6,
  [Paths.Exam_Result]: faDiceD6,
  [Paths.Hall_Ticket]: faFilePdf,
  [Paths.Mark_Sheet]: faDiceD6,
  [Paths.Schedule]: faDiceD6,
  [Paths.Membership]: faDiceD6,
};

export default screenIconMap;
