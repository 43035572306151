import React from "react";
import { useField } from "formik";
import {
  makeStyles,
  TextField,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    display: "block",
  },
  helperText: {
    marginLeft: 2,
    marginRight: 0,
  },
}));

export default function FMSelectField({ options, lookup, loading, ...props }) {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const classes = useStyles();

  const { value, ...fieldProps } = field;

  return (
    <TextField
      select
      InputProps={{
        endAdornment: loading ? <CircularProgress size="24px" /> : null,
      }}
      FormHelperTextProps={{ className: classes.helperText }}
      className={classes.root}
      error={!!errorText.trim()}
      helperText={errorText.trim()}
      fullWidth
      value={loading ? "" : value || ""}
      {...fieldProps}
      {...props}
      onChange={(e) => {
        // If we passed a custom onChange then give it the lookup / options data as well
        if (props.onChange) props.onChange(e, lookup || options);
        else field.onChange(e);
      }}
    >
      {options.map((e) => (
        <MenuItem key={e.value} value={e.value}>
          {e.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
