import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, Typography } from "@material-ui/core";
import { useAuthState } from "../../Auth/Context/AuthContext";
import Nav from "./Nav";

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
  },
  paper: {
    width: drawerWidth,
    padding: theme.spacing(9, 0, 6, 0),
    boxShadow: "4px 0px 4px rgba(0, 0, 0, 0.15)",
  },
  backdrop: {
    background: "none",
  },
  userName: {
    fontSize: "20px",
    fontWeight: 500,
    padding: theme.spacing(0, 2),
    margin: theme.spacing(1, 0, 0.5, 0),
  },
  userRole: {
    fontSize: "14px",
    padding: theme.spacing(0, 2),
    fontWeight: 500,
  },
}));

export default function Sidebar({ onClose, open }) {
  const classes = useStyles();
  const { user } = useAuthState();

  return (
    <Drawer
      className={classes.root}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      onClose={onClose}
      anchor="left"
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      variant="persistent"
    >
      <Typography className={classes.userName} variant="h2">
        {user.name}
      </Typography>
      <Typography
        className={classes.userRole}
        color="textSecondary"
        variant="h4"
      >
        {user.role}
      </Typography>
      <Nav closeSidebar={onClose} />
    </Drawer>
  );
}

Sidebar.defaultProps = {
  open: false,
};
