import React from "react";
import { TextField, CircularProgress, MenuItem } from "@material-ui/core";
import useLookup from "../Hooks/useLookup";

export default function FMLookupOnly({
  lookupType,
  lookupNameAsValue = false,
  onLookupLoad,
  value,
  onChange,
  ...restProps
}) {
  const { lookup, dataItemKey, textField, isFetching } = useLookup(
    lookupType,
    lookupNameAsValue,
    onLookupLoad
  );

  const handleChange = (e) => {
    e.persist();
    if (typeof onChange === "function") onChange(e, lookup);
  };

  return (
    <TextField
      select
      fullWidth
      InputProps={{
        endAdornment: isFetching ? <CircularProgress size="24px" /> : null,
      }}
      value={isFetching ? "" : value}
      onChange={handleChange}
      {...restProps}
    >
      {lookup.map((e) => {
        return (
          <MenuItem key={e[dataItemKey]} value={e[dataItemKey]}>
            {e[textField]}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
