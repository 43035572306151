export default function customSearch(data={},term){
console.log("custom search data====> "+JSON.stringify(data))
//console.log("custom search data====> "+JSON.stringify(Object.values(data[0])))
const z=Object.values(data)
const x=z.map((e)=> 
(typeof e == "string" && e != null)?
(e.toLowerCase()).indexOf(term.toLowerCase()) != -1 :null ||
(typeof e != "string" && e != null)?(e.toString()).indexOf(term) != -1:null)
let y=false

x.filter(person => person == true).map(filteredPerson => y=true)
console.log(y+"return values  "+ x)
    return y}