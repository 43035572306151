import React, { useMemo } from "react";
import { FMAutoCompletePlain } from "../Form";
import useLookup from "../Hooks/useLookup";

export const optionMaker = (opt) =>
  // `${opt.raw_entity_name}, +${opt.mobile_phone_country_code}${opt.mobile_phone}, ${opt.email}`;
  `${opt.number_plate}`;

export default function AllEntitiesDropdown({
  name,
  label,
  lookupType = "allEntities",
  ...restProps
}) {
  const { lookup, dataItemKey, textField, isFetching } = useLookup(lookupType);

  const options = useMemo(
    () => lookup.map((lkp) => ({ ...lkp, [textField]: optionMaker(lkp) })),
    [lookup, textField]
  );

  return (
    <FMAutoCompletePlain
      disableClearable
      name={name}
      label={label}
      loading={isFetching}
      options={options}
      dataItemKey={dataItemKey}
      textField={textField}
      lookupType="allEntities"
      convertValueToId={true}
      {...restProps}
    />
  );
}
