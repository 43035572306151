import React from "react";
import FiltersBox from "./FiltersBox";
import FiltersDialog from "./FiltersDialog";
import { FMAutoComplete } from "../../Form";

export default function EntityFilter({
  name = "entity_id",
  label = "Entity",
  lookupType,
  dialogProps,
  ...restProps
}) {
  return (
    <FiltersDialog {...dialogProps}>
      <FiltersBox>
        <FMAutoComplete
          name={name}
          label={label}
          lookupType={lookupType}
          convertValueToId={true}
          {...restProps}
        />
      </FiltersBox>
    </FiltersDialog>
  );
}
