import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(-0.75, -2),
    padding: theme.spacing(0.75, 2),
    textDecoration: "none",
    color: "inherit",
  },
}));

export default function GridCSVExport({ filename, columns = [], data = [] }) {
  const classes = useStyles();
  const headers = useMemo(
    () => columns.map((col) => ({ label: col.title, key: col.field })),
    [columns]
  );

  return (
    <CSVLink
      className={classes.link}
      role="button"
      filename={`${filename}.csv`}
      data={data}
      headers={headers}
    >
      Export as CSV
    </CSVLink>
  );
}
