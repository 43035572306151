import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./components/Auth/Context";
import ReactQueryConfigProvider from "./lib/ReactQueryConfig";
import MaterialUIConfigProvider from "./lib/MaterialUIConfig";
import MainSwitch from "./MainSwitch";
import { createBrowserHistory } from 'history';

// this css file was downloaded from kendo theme builder by only selecting charts
// component. Did not select any other components to keep css file size small
// If you ever import complete css from a kendo theme you may remove this import
import "./lib/chart.css";
const history = createBrowserHistory();
function App() {
  
  return (
    <MaterialUIConfigProvider>
      <BrowserRouter>
        <AuthProvider>
          <ReactQueryConfigProvider>
            <MainSwitch />
          </ReactQueryConfigProvider>
        </AuthProvider>
      </BrowserRouter>
    </MaterialUIConfigProvider>
  );
}
export default App;
