import React, { createContext, useContext, useReducer } from "react";
import {
  setAuthHeaders,
  clearAuthHeaders,
  setUser,
  clearUser,
  getUser,
  setModules,
  getModules,
  clearModules,
  checkLoggedIn,
} from "./storageHelpers";

// Separate because https://kentcdodds.com/blog/how-to-use-react-context-effectively/
const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

function authReducer(state, action) {
  switch (action.type) {
    case "login": {
      setAuthHeaders(action.payload);
      const user = setUser(action.payload.Sproc_OParams);
      const modules = setModules(action.payload.Menu_Data);
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        user: user,
        modules: modules,
      };
    }
    case "logout": {
      clearAuthHeaders();
      clearUser();
      clearModules();
      sessionStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        user: null,
        modules: [],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, {
    isLoggedIn: checkLoggedIn(),
    isLoading: false,
    user: getUser(),
    modules: getModules(),
  });

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export function useAuthState() {
  const state = useContext(AuthStateContext);
  if (state === undefined) {
    throw new Error("No AuthProvider available for useAuthState");
  }
  return state;
}

export function useAuthDispatch() {
  const dispatch = useContext(AuthDispatchContext);
  if (dispatch === undefined) {
    throw new Error("No AuthProvider available for useAuthDispatch");
  }
  return dispatch;
}

export function useAuthStore() {
  return [useAuthState(), useAuthDispatch()];
}
