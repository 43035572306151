import React from "react";
import { makeStyles } from "@material-ui/core";
import AuthHeading from "../Common/AuthHeading";

const useStyles = makeStyles(() => ({
  link: {
    color: "white",
    display: "block",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function LoginInfo() {
  const classes = useStyles();

  return (
    <>
      <AuthHeading type="left">
        <span>Welcome</span>
      </AuthHeading>
      <p>
        Cloud based business application that includes business operations,
        accounting, document management, client communication, CRM, Analytics
        (Data Science & Data Warehouse) and Blockchain technology.
      </p>
      <br />
      <br />
      <a
        className={classes.link}
        href="https://tansycloud.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        www.tansycloud.com
      </a>
      <br />
    </>
  );
}
