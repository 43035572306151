import React from "react";
import Grid from "@material-ui/core/Grid";
import GridItem from "../FormGridItem";
import { LinearLoader } from "../../Common";

export default function CustomFields({ fields, isLoadingFields }) {
  if (isLoadingFields) {
    return <LinearLoader />;
  }

  return (
    <Grid container spacing={2} alignItems="center">
      {fields.map(({ Component, preDefinedProps, ...field }) => (
        <GridItem key={field.name}>
          <Component {...preDefinedProps} {...field} />
        </GridItem>
      ))}
    </Grid>
  );
}
