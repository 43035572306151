import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MenuClosedIcon from "@material-ui/icons/Menu";

export default function Toggle({ onToggle, open }) {
  return (
    <IconButton
      onClick={onToggle}
      edge="start"
      color="inherit"
      aria-label="open drawer"
    >
      {open ? <MenuOpenIcon /> : <MenuClosedIcon />}
    </IconButton>
  );
}

Toggle.defaultProps = {
  open: false,
  onToggle: () => null,
};
