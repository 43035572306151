import React from "react";
import LoginForm from "./LoginForm";
import LoginInfo from "./LoginInfo";
import AuthWrap from "../Common/AuthWrap";

export default function Login() {
  return (
    <AuthWrap
      alignItems="flex-start"
      Left={<LoginInfo />}
      Right={<LoginForm />}
    />
  );
}
