import React from "react";
import { Formik } from "formik";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TansyDialog from "../../Common/TansyDialog";
import FiltersActions from "./FiltersActions";
import FiltersSave from "./FiltersSave";
import FiltersDropdown from "./FiltersDropdown";
import moment from "moment";

function getEmptyFilters(filters = {}) {
  const emptyFilters = {};
  let str="start_date"
  let str1="end_date"
  console.log("filters>>>"+JSON.stringify(filters))
  Object.entries(filters).forEach(([key, val]) => {
    console.log(key+"<<<<key-----val>>>>"+val+"---------"+JSON.stringify(key))
    console.log(key==str)
    if (Array.isArray(val)) {
      emptyFilters[key] = [];
      console.log("pehla"+JSON.stringify(emptyFilters))
    } 
    // else if (key == str || key == str1) {
    //   emptyFilters[key] = val; 
    //   console.log("dusra"+JSON.stringify(emptyFilters))

    // }
     else if (key == str1) {
      emptyFilters[key] = moment().format("YYYY-MM-DD") 
      console.log("dusra222"+JSON.stringify(emptyFilters))

    } 
    else if (key == str) {
      emptyFilters[key] = moment().subtract(6,'months').format("YYYY-MM-DD") 
      console.log("dusra222"+JSON.stringify(emptyFilters))

    } 
    else if (typeof val === "boolean") {
      emptyFilters[key] = val;
    } 
    else if (val !== null && typeof val === "object") {
      emptyFilters[key] = getEmptyFilters(val);
    } else {
      emptyFilters[key] = "";
    }
  });
  console.log("final"+JSON.stringify(emptyFilters))
  return emptyFilters;
}

export default function FiltersDialog({
  title = "Grid Filters",
  isFilterOpen,
  filterValues,
  onFilterReset,
  onFilterClose,
  onFilterSubmit,
  validationSchema,
  children,
  clearable = true,
}) {
  const theme = useTheme();
  return (
    <Formik
      onSubmit={async (values) => {
        onFilterSubmit(values);
        onFilterClose();
      }}
      initialValues={filterValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
    >
      {(fkProps) => {
        const { resetForm, isSubmitting, handleSubmit, setValues } = fkProps;
        const handleClose = () => {
          onFilterClose();
          resetForm();
        };

        const handleClear = () => {
          // if (typeof onFilterReset === "function") {
          //   onFilterReset();
          // }
          setValues(getEmptyFilters(filterValues));
        };

        return (
          <TansyDialog
            fullWidth
            maxWidth="sm"
            title={title}
            open={isFilterOpen}
            onClose={handleClose}
            scroll="body"
            actions={
              <FiltersActions
                disabled={isSubmitting}
                onClear={handleClear}
                onClose={handleClose}
                onApply={handleSubmit}
              />
            }
          >
            <Accordion
              style={{ backgroundColor: theme.palette.background.default }}
              elevation={0}
              square
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Filter Settings</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box width="100%">
                  <FiltersDropdown
                    onFilterSelect={(vals) => fkProps.setValues({ ...vals })}
                  />
                  <FiltersSave filters={fkProps.values} />
                </Box>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <br />

            {typeof children === "function" ? children(fkProps) : children}
          </TansyDialog>
        );
      }}
    </Formik>
  );
}
