import React from "react";
import { Switch, Route } from "react-router-dom";
import { useAuthState } from "../../Auth/Context/AuthContext";
import ModuleLanding from "../ModuleLanding";
import NotFound from "../NotFound";
import GlobalSearch from "../../Layout/GlobalSearch";
import ChangePasswordPage from "../../Auth/ResetPassword/ChangePasswordForm";

export default function ModuleRouter() {
  const { modules } = useAuthState();

  return (
    <Switch>
      {modules.map((m) => (
        <Route key={m.module_id} path={`/${m.module_name}`}>
          <ModuleLanding />
        </Route>
      ))}
      <Route exact path="/global-search">
        <GlobalSearch />
      </Route>
      <Route exact path="/change-password">
        <ChangePasswordPage />
      </Route>
      <Route exact path="/">
        <ModuleLanding />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}
