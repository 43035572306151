import { useState, useRef, useCallback, useEffect } from "react";
import { useMutation } from "react-query";

export default function usePrint(getPrintReport) {
  const linkRef = useRef();
  const printRef = useRef();
  const [data, setPrint] = useState(null);
  const [generating, setGenerating] = useState(false);

  const [fetchReport, { isLoading }] = useMutation(getPrintReport, {
    onSuccess: (data) => {
      setGenerating(true);
      setPrint(data);
    },
  });

  const s3Flag =
    data && data.api_oparams ? !!data.api_oparams.signed_url : false;

  const handlePrint = useCallback(() => {
    if (s3Flag && linkRef.current) {
      linkRef.current.click();
      setGenerating(false);
    } else if (printRef.current && printRef.current.save) {
      printRef.current.save(() => setGenerating(false));
    }
  }, [s3Flag]);

  useEffect(() => {
    if (data !== null) {
      handlePrint();
    }
  }, [data, handlePrint]);

  const linkData = s3Flag ? data.api_oparams.signed_url : "";
  const printData = s3Flag ? null : data;

  return {
    linkRef,
    printRef,
    linkData,
    printData,
    fetchReport,
    isLoading: isLoading || generating,
  };
}
