import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

export default function Help() {
  return (
    <Tooltip title="Help">
      <IconButton aria-label="help for application" color="inherit">
        <HelpOutlineOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}
