import React from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles, fade } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      backgroundColor: fade("#000", 0.2),
    },
  },
  listItem: {
    background: (props) => (props.active ? "#ddd" : "none"),
    transition: theme.transitions.create("background"),
    "&:hover": {
      backgroundColor: (props) => (props.active ? "#ddd" : "#eee"),
    },
  },
  itemText: {
    color: fade("#000", 0.87),
    fontWeight: 500,
    fontSize: "14px",
  },
}));

export default function NavItem({ to, text, Icon, closeSidebar }) {
  const { pathname } = useLocation();
  const active = pathname === to;
  const classes = useStyles({ active });

  return (
    <Link className={classes.link} to={to} onClick={closeSidebar}>
      <ListItem className={classes.listItem}>
        <ListItemIcon>
          <Icon fill={fade("#000", 0.6)} />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.itemText }} primary={text} />
      </ListItem>
    </Link>
  );
}
