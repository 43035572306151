import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path d="M22.406 14.67c-1.419.087-3.285 1.506-5.083 2.32-2.206.999-5.64.196-5.64.196.528-.248 2.681-.762 3.115-.952 2.304-1.013 2.11-3.127 1.012-3.109-1.45.023-2.3.38-5.194.774-2.193.3-4.786.19-6.03.665C2.829 15.234 0 19.747 0 19.747l4.374 4.238s2.708-2.667 4.024-2.667c3.002 0 3.122-.04 5.91-.19 1.185-.065 1.433-.112 2.11-.342 3.613-1.222 7.492-4.475 7.564-4.865.166-.907-.825-1.297-1.576-1.251z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM7.417 8a4.583 4.583 0 119.166 0 4.583 4.583 0 01-9.166 0z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5.083c.23 0 .417.187.417.417v2.242l1.436.719a.417.417 0 11-.373.745l-1.666-.833a.417.417 0 01-.23-.373V5.5c0-.23.186-.417.416-.417z"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
