import { lazy } from "react";
import * as Paths from "../Paths";

const getScreen = (path) => lazy(() => import(`../../${path}`));

const ScreenComponentMap = {
  [Paths.TicketsDashboard]: getScreen("Dashboard/Ticket"),
  [Paths.BusinessDashboard]: getScreen("Dashboard/Business"),
  [Paths.CRMDashboard]:getScreen("Dashboard/CRM"),
  [Paths.ClientDashboard]:getScreen("Dashboard/ClientDashboard"),
  [Paths.RewardDashboard]:getScreen("Dashboard/RewardDashboard"),
  [Paths.SalesDashboard]:getScreen("Dashboard/Sales"),
  [Paths.PurchaseDashboard]:getScreen("Dashboard/Purchase"),
  [Paths.OrderDashboard]:getScreen("Dashboard/Order"),
  [Paths.ServiceDashboard]:getScreen("Dashboard/Service"),
  [Paths.ECommerceDashboard]:getScreen("Dashboard/ECommerce"),
  

  [Paths.User]: getScreen("Admin/User"),
  [Paths.Entity]: getScreen("Admin/Entity"),
  [Paths.Bulk_Load]: getScreen("Admin/BulkLoad"),
  [Paths.Map_Entity]: getScreen("Admin/MapEntity"),
  [Paths.Bulk_Update]: getScreen("Admin/BulkUpdate"),
  [Paths.Fiscal_Year]: getScreen("Admin/FiscalYear"),
  [Paths.Reminder]: getScreen("Admin/Reminder2"),
  [Paths.CommunicationAccess]:getScreen("Admin/CommunicationAccess"),

  [Paths.Tax]: getScreen("Settings/Tax"),
  [Paths.City]: getScreen("Settings/City"),
  [Paths.Bank]: getScreen("Settings/Bank"),
  [Paths.Lookups]: getScreen("Settings/Lookups"),
  [Paths.Accounts]: getScreen("Settings/Accounts"),
  [Paths.WorkflowType]: getScreen("Settings/WorkflowType"),
  [Paths.SMS_Template]: getScreen("Settings/SmsTemplate"),
  [Paths.Organization]: getScreen("Settings/Organization"),
  [Paths.Custom_Fields]: getScreen("Settings/CustomFields"),
  [Paths.Email_Template]: getScreen("Settings/EmailTemplate"),
  [Paths.Service_Status]: getScreen("Settings/ServiceStatus"),
  [Paths.Whatsapp_Template]: getScreen("Settings/WhatsappTemplate"),
  [Paths.Dynamic_Grids]: getScreen("Settings/DynamicGrids"),
  [Paths.Product_Attribute_Mapping]: getScreen("Settings/ProductAttributeMapping"),
  [Paths.Entity_type]: getScreen("Settings/EntityLookup"),
  [Paths.Invoice_Order_Status]:getScreen("Settings/InvoiceOrderStatus"),
  [Paths.Bulk_Update_Fields]:getScreen("Settings/BulkUpdateFields"),
  [Paths.InvoicePrefix]: getScreen("Settings/InvoicePrefix"),
  [Paths.FacilityDelivery]: getScreen("Settings/FacilityDelivery"),

  
  [Paths.Product_Type]: getScreen("Inventory/ProductTypeV2"),
  [Paths.Purchase_Invoice]: getScreen("Inventory/PurchaseInvoice"),
  [Paths.Client_Product_Pricing]: getScreen("Inventory/ProductPricing"),
  [Paths.Bulk_Stock]:getScreen("Inventory/BulkStock"),
  [Paths.PurchasePriceCompare]:getScreen("Inventory/PurchasePriceCompare"),

  [Paths.Quote]: getScreen("PreSales/Quote"),
  [Paths.Customer_order]: getScreen("PreSales/CustomerOrder"),

  [Paths.Service]: getScreen("Sales/Service"),
  [Paths.Car_Wash]: getScreen("Sales/CarWash"),
  [Paths.Sales_Invoice]: getScreen("Sales/Invoice"),
  [Paths.Auto_Repair]: getScreen("Sales/AutoRepair"),
  [Paths.Post_Invoice]: getScreen("Sales/PostInvoice"),
  [Paths.Recurring_Invoice]: getScreen("Sales/RecurringInvoice"),
  [Paths.Invoice_Template]: getScreen("Sales/InvoiceTemplate"),
  [Paths.Delivery_Order]: getScreen("Sales/DeliveryOrder"),
  [Paths.Delivery_Invoice]: getScreen("Sales/DeliveryInvoice"),
  [Paths.Wallet]: getScreen("Sales/Wallet"),

  [Paths.Sales_Listing]: getScreen("PostSales/SalesListing"),
  [Paths.Product_Offer]: getScreen("PostSales/ProductOffer"),
  [Paths.Service_Reminder]: getScreen("PostSales/ServiceReminder"),
  [Paths.Invoice_Adjustment]: getScreen("PostSales/InvoiceAdjustment"),
  [Paths.Payment_reminder]: getScreen("PostSales/PaymentReminder"),

  [Paths.Lead]: getScreen("Organization/Lead"),
  [Paths.Client]: getScreen("Organization/Client"),
  [Paths.Vehicle]: getScreen("Organization/Vehicle"),
  [Paths.Product]: getScreen("Organization/ProductScreens"),
  [Paths.Employee]: getScreen("Organization/Employee"),
  [Paths.Supplier]: getScreen("Organization/Supplier"),
  [Paths.Tracking]: getScreen("Organization/Tracking"),
  [Paths.Agent]: getScreen("Organization/Agent"),
  [Paths.Contacts]: getScreen("Organization/Contacts"),
  [Paths.Product_Pricing]:getScreen("Organization/ProductPricing"),
  [Paths.NGO_Membership]:getScreen("Organization/NGOMembership"),
  [Paths.Patient]:getScreen("Organization/Patient"),
  [Paths.Doctor]:getScreen("Organization/Doctor"),
  [Paths.Appointmemt]:getScreen("Organization/Appointment"),

 

  [Paths.Attendance]: getScreen("Hr/Attendance"),
  [Paths.Monthly_Log]: getScreen("Hr/MonthlyLog"),

  [Paths.Tickets]: getScreen("CRM/Tickets"),
  [Paths.Workflow]: getScreen("CRM/Workflow"),

  [Paths.Send_Message]: getScreen("Communication/MultiMessage"),
  [Paths.Fee_Reminder]: getScreen("Communication/FeeReminder"),
  [Paths.Student_Attendance]: getScreen("Communication/StudentAttendance"),
  [Paths.Exam_Schedule]: getScreen("Communication/ExamSchedule"),
  [Paths.Exam_Result1]: getScreen("Communication/Communications"),

  [Paths.Expense]: getScreen("Accounting/Expense"),
  [Paths.Payable]: getScreen("Accounting/Payable"),
  [Paths.Receivable]: getScreen("Accounting/Receivable"),
  [Paths.Bank_Transaction]: getScreen("Accounting/BankTransactions"),
  [Paths.Payable_Due]: getScreen("Accounting/PayableDue"),
  [Paths.Receivable_Due]: getScreen("Accounting/ReceivableDue"),
  [Paths.Transactions]: getScreen("Accounting/Transactions"),
  [Paths.BankCashTransaction]: getScreen("Accounting/BankCashTransactions"),
  [Paths.EMI]: getScreen("Accounting/EMI"),
  [Paths.GenerateInvoice]:getScreen("Accounting/GenerateInvoice"),
  [Paths.GenerateSoa]:getScreen("Accounting/GenerateSoa"),
  [Paths.NGOCollection]:getScreen("Accounting/NGOCollection"),

  [Paths.File_Upload]: getScreen("Document/FileUpload"),
  [Paths.Cloud_Documents]: getScreen("Document/CloudDocuments"),
  [Paths.File_Upload1]: getScreen("Document/FileUpload"),

  [Paths.Subject]: getScreen("School/Subject"),
  [Paths.School_Class]: getScreen("School/Class"),
  [Paths.ClassSchedule]:getScreen("School/ClassSchedule"),
  [Paths.Teacher_Mapping]: getScreen("School/TeacherMapping"),
  [Paths.Subject_Topics]: getScreen("School/SubjectTopics"),

  [Paths.Exam]: getScreen("Exam/Exam"),
  [Paths.Mark_Sheet]: getScreen("Exam/MarkSheet"),
  [Paths.Schedule]: getScreen("Exam/ExamSchedule"),
  [Paths.Hall_Ticket]: getScreen("Exam/HallTicket"),
  [Paths.Exam_Result]: getScreen("Exam/ExamResult"),
  [Paths.Progress_Report_Version]: getScreen("Exam/ProgressReportVersion"),
  [Paths.Student_Remark]: getScreen("Exam/StudentRemark"),


  [Paths.Student]: getScreen("Student/StudentData"),
  [Paths.Admission]: getScreen("Student/Admission"),
  [Paths.Student_Listing]: getScreen("Student/StudentListing"),
  [Paths.Move_student]: getScreen("Student/MoveStudent"),
  [Paths.Subject_Performance]:getScreen("Student/SubjectPerformance"),
  [Paths.Assignment]: getScreen("Student/Assignment"),
  
  [Paths.product_stock]: getScreen("Inventory/ProductStock2"),
  [Paths.audit_log]: getScreen("Admin/AuditLog"),
  [Paths.screen_permission]: getScreen("Settings/ScreenPermission"),
  [Paths.leave_management]: getScreen("Hr/LeaveManagement"),
  [Paths.ot_management]: getScreen("Hr/OTManagement"),
  [Paths.loan_management]: getScreen("Hr/LoanManagement"),
  [Paths.post_salary]: getScreen("Hr/PostSalary"),
  [Paths.project]: getScreen("Admin/Project"),
  [Paths.expense_listing]: getScreen("Accounting/ExpenseListing"),
  [Paths.holidays]: getScreen("Admin/Holiday"),
  [Paths.salaryTax]: getScreen("Settings/SalaryTax"),
  [Paths.WorkflowSettings]: getScreen("Settings/WorkflowSettings2"),
  [Paths.productReview]: getScreen("Online/ProductReview"),
  [Paths.syncProcess]: getScreen("Admin/SyncProcess"),
  [Paths.invoiceProductSearch]: getScreen("PostSales/InvoiceProductSearch"),
  [Paths.generateSoaBatch]: getScreen("Admin/GenerateSoa"),
  [Paths.facility]: getScreen("Organization/Facility"),

  [Paths.offer]:getScreen("Rewards/Offer"),
  [Paths.Rewards]:getScreen("Rewards/Rewards"),

 
  [Paths.TransportEnquiry]: getScreen("Transport/TransportEnquiry"),
  [Paths.TransportQuote]: getScreen("Transport/TransportQuote"),
  [Paths.TransportRequest]: getScreen("Transport/TransportRequest"),
  [Paths.TransportService]: getScreen("Transport/TransportService"),
  [Paths.VehicleSchedule]: getScreen("Transport/VehicleSchedule"),
  [Paths.PriceList]: getScreen("Transport/PriceList"),

  [Paths.MyQueue]: getScreen("Profile/MyQueue"),

  [Paths.CollectionsManagement]:getScreen("NGO/CollectionsManagement"),
  [Paths.PrayerTimings]:getScreen("NGO/PrayerTimings"),
  [Paths.DuaRequest]:getScreen("NGO/DuaRequest"),
  [Paths.Membership]:getScreen("NGO/Membership"),
  [Paths.Service2]:getScreen("NGO/Service"),
  [Paths.Articles]:getScreen("NGO/Articles"),
  [Paths.Events]:getScreen("NGO/Events"),
  [Paths.Annoucements]:getScreen("NGO/Annoucements"),
  [Paths.LocalBusiness]:getScreen("NGO/LocalBusiness"),
  [Paths.AcademicPrograms]:getScreen("NGO/AcademicPrograms"),

  [Paths.DeliveryRate]:getScreen("OrderManagement/DeliveryRate"),
  [Paths.OrderStatus]:getScreen("OrderManagement/OrderStatus"),
  [Paths.Order]:getScreen("OrderManagement/Order"),
  [Paths.CartItems]:getScreen("OrderManagement/CartItems"),

  [Paths.Outpatient]:getScreen("Outpatient/Outpatient"),

  [Paths.TableOrder]:getScreen("Restaurant/TableOrder"),

  [Paths.DailyStandup]:getScreen("PMO/DailyStandup"),
  [Paths.TraineeProgram]:getScreen("PMO/TraineeProgram"),
  [Paths.DailyAchievement]:getScreen("PMO/DailyAchievement"),

  [Paths.Testimonials]:getScreen("ConsumerApp/Testimonials"),
  [Paths.EmailSubscription]:getScreen("ConsumerApp/EmailSubscription"),
  [Paths.ContactUs]:getScreen("ConsumerApp/ContactUs"),

};
 
export default ScreenComponentMap;
