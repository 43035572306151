import { formatters } from "../../../lib";

const { formatDate, formatCurrency } = formatters;

export { formatDate, formatCurrency };

/**
 * 
 * @param {"right" | "left"} position which side of footer to get
 * @param {Array<{
    position: "left" | "right", 
    label: string, 
    label_value_text: string, 
    label_value_numeric: string
  }>} footerData array of footer data
 * @returns {Array<{
   left: string, 
   right: string
  }>} an array of extracted left and right values in same order that was recieved
 */
export function getFooterSide(footerData = [], position = "right") {
  const rightSide = footerData.filter((e) => e.position === position);
  return rightSide.map((obj) => ({
    left: obj.label,
    right: formatCurrency(obj.label_value_numeric, obj.label_value_text),
  }));
}

/**
 *
 * @param {Array} hd array with a single element
 * @returns {Array<{left: string, right: string}>} labels and keys for info,
 */
export function getQuoteHeader(hd = []) {
  return [
    { left: "Quote #", right: hd.quote_number },
    { left: "Quote Date", right: formatDate(hd.transaction_date, "date") },
    { left: "Quote Expiry", right: formatDate(hd.quote_expiry_date, "date") },
  ];
}

/**
 *
 * @param {Array} hd array with a single element
 * @returns {Array<{left: string, right: string}>} labels and keys for info,
 */
export function getInvoiceHeader(hd = []) {
  return [
    { left: "Quote #", right: hd.quote_number },
    { left: "Invoice #", right: hd.invoice_number },
    { left: "Invoice Date", right: formatDate(hd.transaction_date, "date") },
    { left: "Payment Due", right: formatDate(hd.payment_due_date, "date") },
  ];
}

export function getServiceHeader({
  invoice_number,
  transaction_date,
  completion_date,
  service_agent_name,
}) {
  return [
    { left: "Service #", right: invoice_number },
    { left: "Booking Date", right: formatDate(transaction_date, "date") },
    { left: "Completion Date", right: formatDate(completion_date, "date") },
    { left: "Service Agent", right: service_agent_name },
  ];
}

export function getServiceAutoHeader(hd) {
  return [
    ...getServiceHeader(hd),
    { left: "Vehicle #", right: hd.vehicle_number_plate },
  ];
}

export function getPurchaseInvoiceHeader(hd) {
  return [
    { left: "Reference #", right: hd.invoice_number },
    {
      left: "Transaction Date",
      right: formatDate(hd.transaction_date, "date"),
    },
    { left: "Due Date", right: formatDate(hd.payment_due_date, "date") },
  ];
}

export const printInvoiceMappers = {
  "Sales Invoice": {
    title: "Sales Invoice",
    mapper: getInvoiceHeader,
  },
  Quote: {
    title: "Quote",
    mapper: getQuoteHeader,
  },
  Service: {
    title: "Service",
    mapper: getServiceHeader,
  },
  "Service - Car Wash": {
    title: "Car Wash",
    mapper: getServiceHeader,
  },
  "Service - Auto Repair": {
    mapper: getServiceAutoHeader,
    title: "Auto Repair",
  },
  "Purchase Invoice": {
    title: "Purchase Invoice",
    mapper: getPurchaseInvoiceHeader,
  },
  "Tranport Enquiry": {
    title: "Purchase Invoice",
    mapper: getPurchaseInvoiceHeader,
  },
};
