import React from "react";
import styled from "styled-components";
import { getAddress } from "./helpers";

export const Wrap = styled.div`
  display: flex;
  font-weight: bold;
  letter-spacing: 0.4px;
`;

export const Left = styled.div`
  padding-top: 6px;
  border-top: solid 4px #215ca0;
  width: calc(65% - 25px);
  margin-right: 25px;
`;

export const LeftInfo = styled.div`
  padding-left: 10%;
  font-size: 0.7rem;
`;

export const Center = styled.div`
  padding-top: 6px;
  text-align: center;
  width: 25%;
  border-top: solid 2px #414141;
`;

export const Right = styled.div`
  border-top: solid 4px #215ca0;
  margin-left: 25px;
  width: calc(10% - 25px);
`;

export default function Footer({ orgData }) {
  const phoneOnly = orgData.work_phone || "";
  const countryCode = orgData.work_phone_country_code || "";
  const phone = `+${countryCode} ${phoneOnly}`;
  const address = getAddress(orgData);
  const website = orgData && orgData.website ? orgData.website : "";
  return (
    <Wrap>
      <Left>
        <LeftInfo>
          {phone} | {address} | {website}
        </LeftInfo>
      </Left>
      <Center>Authorized Sign</Center>
      <Right></Right>
    </Wrap>
  );
}

Footer.defaultProps = {
  orgData: {},
};
