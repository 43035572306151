import { axios } from "../../../lib";

export async function saveFilter({ filter_name, make_public, filter_values }) {
  const { data } = await axios.post("/api/grid-filters", {
    filter_name,
    filter_fields_values: JSON.stringify([filter_values]),
    make_public: make_public ? 1 : 0,
  });
  return data;
}

export async function deleteFilter({ screen_id, filter_name }) {
  if (!filter_name) throw new Error("No filter selected");
  const { data } = await axios.delete("/api/grid-filters", {
    headers: { screen_id, filter_name },
  });
  return data;
}
