import React from "react";
import { useField } from "formik";
import { CircularProgress, makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import useLookup from "../Hooks/useLookup";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
}));

export default function FMAutoComplete({
  name,
  label,
  value,
  onChange,
  lookupType,
  filterOptions,
  convertValueToId = false,
  lookupNameAsValue = false,
  ChipProps = {},
  onLookupLoad,
  ...restProps
}) {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name });
  const { lookup, textField, dataItemKey, isFetching } = useLookup(
    lookupType,
    lookupNameAsValue,
    onLookupLoad
  );

  // If an onChange was specified then send lookup data as well
  // otherwise just set the field value based on autocomplete mode
  const handleChange = (event, value) => {
    if (onChange) {
      onChange(event, value, lookup);
    } else {
      const selectVal = convertValueToId
        ? value !== null
          ? value[dataItemKey]
          : ""
        : value;
      helpers.setValue(selectVal);
    }
  };

  const errorText = meta.error && meta.touched ? meta.error : "";

  // We don't want initial value to be "" that raises warnings
  // if no value selected yet then it should be null
  const fieldValue = value || field.value || null;
  const finalValue = convertValueToId
    ? lookup.find((lkp) => lkp[dataItemKey] === fieldValue) || null
    : fieldValue;

  const textFieldProps = isFetching
    ? { InputProps: { endAdornment: <CircularProgress size="24px" /> } }
    : {};

  return (
    <Autocomplete
      fullWidth
      openOnFocus
      ChipProps={{
        size: "small",
        ...ChipProps,
      }}
      className={classes.root}
      loading={isFetching}
      options={lookup}
      value={finalValue}
      onBlur={field.onBlur}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!errorText}
          helperText={errorText}
          {...textFieldProps}
        />
      )}
      getOptionLabel={(option) => `${option[textField] || option.label || ""}`}
      getOptionSelected={(opt, val) =>
        opt[dataItemKey] === val[dataItemKey] || opt.value === val.value
      }
      filterOptions={
        filterOptions ? createFilterOptions(filterOptions) : undefined
      }
      {...restProps}
    />
  );
}
