import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
}));

export default function FMMultiDropdown({
  name,
  label,
  value,
  onChange,
  options = [],
  loading = false,
  textField = "label",
  dataItemKey = "value",
  filterOptions,
  ...restProps
}) {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name });

  // If an onChange was specified then send lookup data as well
  // otherwise just set the field value based on autocomplete mode
  const handleChange = (event, value) => {
    if (onChange) {
      onChange(event, value, options);
    } else {
      helpers.setValue(value);
    }
  };

  const errorText = meta.error && meta.touched ? meta.error : "";

  // We don't want initial value to be anything except array that raises errors
  // if no value selected yet then it should be empty array
  const fieldValue = Array.isArray(value)
    ? value
    : Array.isArray(field.value)
    ? field.value
    : [];

  return (
    <Autocomplete
      fullWidth
      multiple={true}
      openOnFocus
      className={classes.root}
      loading={loading}
      options={options}
      value={fieldValue}
      onBlur={field.onBlur}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          error={!!errorText}
          helperText={errorText}
        />
      )}
      getOptionLabel={(option) => option[textField] || option.label}
      getOptionSelected={(opt, val) =>
        opt[dataItemKey] === val[dataItemKey] || opt.value === val.value
      }
      filterOptions={
        filterOptions ? createFilterOptions(filterOptions) : undefined
      }
      {...restProps}
    />
  );
}
