import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 122.88 97.5" width="24px" height="24px" {...props}>
      <path
        d="M8.32 0h106.24c4.58 0 8.32 3.74 8.32 8.32v80.86c0 4.58-3.74 8.32-8.32 8.32H8.32C3.74 97.5 0 93.76 0 89.18V8.32C0 3.74 3.74 0 8.32 0zm15.53 81.86c-.95 0-1.71-.84-1.71-1.88s.77-1.88 1.71-1.88h42.54c.95 0 1.71.84 1.71 1.88s-.77 1.88-1.71 1.88H23.85zm0-8.92c-.95 0-1.71-.84-1.71-1.88s.77-1.88 1.71-1.88h42.54c.95 0 1.71.84 1.71 1.88s-.77 1.88-1.71 1.88H23.85zm56.8 8.92c-.95 0-1.71-.84-1.71-1.88s.77-1.88 1.71-1.88h18.38c.95 0 1.71.84 1.71 1.88s-.77 1.88-1.71 1.88H80.65zm-.68-8.92c-.95 0-1.71-.84-1.71-1.88s.77-1.88 1.71-1.88h18.38c.95 0 1.71.84 1.71 1.88s-.77 1.88-1.71 1.88H79.97zm-5.98-37.63h9.41l3.58 14.05 3.6-14.05h9.37V58.4h-5.84V40.8l-4.5 17.6h-5.29l-4.49-17.6v17.6h-5.84V35.31zM49.01 58.39V35.31H60.9c2.2 0 3.89.19 5.05.57 1.17.38 2.11 1.08 2.82 2.1.71 1.03 1.07 2.28 1.07 3.75 0 1.28-.27 2.39-.82 3.32-.54.93-1.29 1.69-2.26 2.27-.61.37-1.45.67-2.5.91.85.28 1.47.56 1.85.85.26.19.64.6 1.14 1.21.49.62.83 1.1.99 1.43l3.47 6.67h-8.06l-3.81-7.04c-.48-.91-.91-1.5-1.29-1.78a3.06 3.06 0 00-1.75-.53h-.63v9.35h-7.16zm7.16-13.7h3.01c.32 0 .96-.11 1.89-.32.47-.09.86-.33 1.16-.73.3-.39.45-.84.45-1.34 0-.75-.24-1.32-.71-1.72-.47-.4-1.36-.6-2.67-.6h-3.14v4.71h.01zm-17.01 4.26l6.25 1.89c-.42 1.75-1.08 3.22-1.99 4.39a8.547 8.547 0 01-3.36 2.66c-1.34.6-3.04.9-5.11.9-2.51 0-4.56-.37-6.16-1.09-1.59-.73-2.96-2.02-4.12-3.85-1.16-1.83-1.74-4.19-1.74-7.04 0-3.82 1.02-6.75 3.05-8.8 2.04-2.05 4.91-3.07 8.63-3.07 2.91 0 5.2.59 6.86 1.76 1.66 1.18 2.9 2.98 3.71 5.42L38.9 43.5c-.22-.7-.45-1.21-.69-1.53a4.1 4.1 0 00-1.47-1.25c-.58-.3-1.23-.44-1.94-.44-1.62 0-2.86.65-3.72 1.95-.65.96-.98 2.48-.98 4.54 0 2.56.39 4.3 1.17 5.26.78.95 1.87 1.42 3.28 1.42 1.36 0 2.4-.38 3.1-1.15.68-.78 1.19-1.9 1.51-3.35zm78.81-25.66H5.29v66.7c0 .64.25 1.2.67 1.63.42.43.99.67 1.63.67h108.04c.64 0 1.2-.25 1.63-.67.43-.43.67-.99.67-1.63v-66.7h.04zM106.64 9.35a4.11 4.11 0 110 8.22 4.11 4.11 0 010-8.22zm-27.84 0a4.11 4.11 0 110 8.22 4.11 4.11 0 010-8.22zm13.92 0a4.11 4.11 0 110 8.22 4.11 4.11 0 010-8.22z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
