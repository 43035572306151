import React from "react";
import { useState } from "react";
import { fade, IconButton, makeStyles } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  search: {
    display: "flex",
    alignItems: "center",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.25),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.36),
    },
    width: "100%",
    maxWidth: "70ch",
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.75, 2),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0.25, 2),
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 2),
    transition: theme.transitions.create("width"),
  },
}));

export default function Search() {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = useState("");

  const handleSubmit = () => {
    console.log("going")
    history.push("/global-search", { globalSearchValue: value });
  };

  return (
    <div className={classes.search}>
      <IconButton size="small" color="inherit">
        <SearchIcon />
      </IconButton>
      <InputBase
        placeholder="Search for clients, suppliers, leads, invoices and support tickets"
        fullWidth
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") handleSubmit();
        }}
      />
    </div>
  );
}
