import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path d="M22.406 14.67c-1.419.087-3.285 1.506-5.083 2.32-2.206.999-5.64.196-5.64.196.528-.248 2.681-.762 3.115-.952 2.304-1.013 2.11-3.127 1.012-3.109-1.45.023-2.3.38-5.194.774-2.193.3-4.786.19-6.03.665C2.829 15.234 0 19.747 0 19.747l4.374 4.238s2.708-2.667 4.024-2.667c3.002 0 3.122-.04 5.91-.19 1.185-.065 1.433-.112 2.11-.342 3.613-1.222 7.492-4.475 7.564-4.865.166-.907-.825-1.297-1.576-1.251z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.833 8C7.833 5.7 9.7 3.833 12 3.833S16.167 5.7 16.167 8 14.3 12.167 12 12.167A4.168 4.168 0 017.833 8zm4.755 3.333v-.796c.725-.137 1.295-.554 1.3-1.316 0-1.05-.9-1.413-1.742-1.63-.842-.216-1.113-.445-1.113-.795 0-.4.375-.683 1-.683.659 0 .905.316.925.779h.817c-.025-.638-.412-1.225-1.188-1.413v-.812h-1.112v.804c-.717.154-1.296.62-1.296 1.337 0 .855.708 1.28 1.742 1.53.929.22 1.112.545 1.112.891 0 .254-.183.663-1 .663-.762 0-1.062-.342-1.104-.78h-.816c.045.809.65 1.267 1.362 1.417v.804h1.113z"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
