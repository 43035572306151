import React from "react";
import * as Yup from "yup";
import { Box } from "@material-ui/core";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import { useLocation, Redirect } from "react-router-dom";
import { FMTextField } from "../../Form";
import TansyButton from "../../Common/TansyButton";
import AuthHeading from "../Common/AuthHeading";
import { resetPassword } from "../Common/authQueries";
import useLogout from "../../Hooks/useLogout";
import ApiErrorAlert from "../../Common/ApiErrorAlert";
import { getSprocError } from "../../../lib/helpers";

const passwordField = Yup.string()
  .required("Please enter a password")
  .min(6, "Password must be at least 6 characters");
const confirmPasswordField = Yup.string()
  .required("Please confirm password")
  .oneOf([Yup.ref("newPassword"), null], "Passwords do no match");

const ResetSchema = Yup.object().shape({
  newPassword: passwordField,
  confirmPassword: confirmPasswordField,
});

const NewSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Please enter your old password")
    .min(6, "Password mut be at east 6 characters"),
  newPassword: passwordField,
  confirmPassword: confirmPasswordField,
});

export default function ResetPasswordForm({ resetting = true }) {
  const { state } = useLocation();
  const handleLogout = useLogout();
  const { otpValidated, oparams } = state || {
    otpValidated: false,
    oparams: null,
  };
  const Schema = resetting ? ResetSchema : NewSchema;
  const title = resetting ? "Reset" : "Change";
  const variant = resetting ? "outlined" : "standard";
  const size = resetting ? "small" : "medium";
  const allProps = {
    size,
    variant,
    type: "password",
    style: { maxWidth: 500 },
  };

  const handleSettled = resetting ? () => null : undefined;

  const [reset, { error, isLoading }] = useMutation(resetPassword, {
    onSuccess: handleLogout,
    onSettled: handleSettled,
  });

  if (resetting && !otpValidated) {
    return <Redirect to="/forgot-password" />;
  }
  return (
    <Formik
      onSubmit={(values) => reset({ ...values, oparams, resetting })}
      initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "" }}
      validationSchema={Schema}
    >
      {(fkProps) => (
        <Form>
          <AuthHeading>{title} Password</AuthHeading>
          <ApiErrorAlert error={getSprocError(error)} loading={isLoading} /> 
          {resetting ? null: (
            <Box my={2}>
              <FMTextField
                {...allProps}
                label="Old Password"
                name="oldPassword"
              />
            </Box>
          )}
          <Box my={2}>
            <FMTextField
              {...allProps}
              label="New Password"
              name="newPassword"
            />
          </Box>
          <Box my={2}>
            <FMTextField
              {...allProps}
              label="Confirm Password"
              name="confirmPassword"
            />
          </Box>
          <Box my={2}>
            <TansyButton
              margin={0}
              type="submit"
              disabled={fkProps.isSubmitting}
            >
              {title}
            </TansyButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
