import _ from "lodash";

export function productSubTotal({ units, unitPrice }) {
  return (units ?? 0) * (unitPrice ?? 0);
}

export function productsSubTotal(products = []) {
  return products.reduce((a, b) => a + productSubTotal(b), 0);
}

export function getTotals({
  products = [],
  invoiceLevelTax = [],
  discountAmount = 0,
  discountPercent = 0,
}) {
  const subTotal = productsSubTotal(products);

  const taxes = products.reduce((a, b) => [...a, ...b.tax], []);
  const uniqTaxes = _.uniqBy(taxes, "tax_id").map((t) => ({
    tax_id: t.tax_id,
    tax_name: t.tax_name,
    tax_percent: t.tax_percent,
    sum: 0,
  }));
  const uniqTaxObject = _.keyBy(uniqTaxes, "tax_id");
  products.forEach((p) => {
    p.tax.forEach((t) => {
      uniqTaxObject[t.tax_id].sum +=
        p.units *
        p.unitPrice *
        (1 - discountPercent / 100) *
        (t.tax_percent / 100);
    });
  });
  const productTaxes = _.values(uniqTaxObject);

  const discounted = subTotal - discountAmount;
  const afterProductTax = productTaxes.reduce((a, b) => a + b.sum, discounted);

  const invoiceTaxes = invoiceLevelTax.map((e) => ({
    ...e,
    sum: discounted * (e.tax_percent / 100),
  }));
  const totalAmount = invoiceTaxes.reduce((a, b) => a + b.sum, afterProductTax);

  return {
    discountAmount: discountAmount,
    discountPercent: discountPercent,
    beforeDiscount: subTotal,
    afterDiscount: subTotal - discountAmount,
    productTaxes: productTaxes,
    afterProductTax: afterProductTax,
    invoiceTaxes: invoiceTaxes,
    afterInvoiceTax: totalAmount,
  };
}
