import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { ReadOnlyGrid } from "../../Grids";
import { axios5, formatters } from "../../../lib";
import useGridOptions from "../../Hooks/useGridOptions";
import useLinkOptions from "../../Hooks/useLinkOptions";
import useSelectionColumn from "../../Hooks/useSelectionColumn";
import useInvoicePdfOptions from "../../Hooks/useInvoicePdfOptions";
import useVoucherPdfOptions from "../../Hooks/useVoucherPdfOptions";
import { EntityPaths } from "./GlobalSearchMap";
import { IconButton } from "@material-ui/core";
import { FormHeading } from "components/Form";

const { formatDate } = formatters;

export default function GlobalSearchResult() {
  const history = useHistory();
  const state=useLocation();
  const search_string = useLocation().state?.globalSearchValue;
  async function getSearchResult(key,) {
    const { data } = await axios5.get("/api/global-search-list", {
        headers: {

          search_string, screen_id: 104007, screen_name: "Global Search"


        }
    });

    console.log("detail", JSON.stringify(data))

    return data.data0;
}
const { data, isFetching, refetch } = useQuery(
    ["SearchDetails"],
    getSearchResult,
    { enabled: !!search_string, staleTime: 1000 * 60 * 5 }
);

useEffect(() => {
  if (search_string) {
    refetch();
  }
}, [search_string, refetch]);
  // const { data, isFetching } = useQuery(
  //   [
  //     "/api/global-search-list",
  //     { search_string, screen_id: 104007, screen_name: "Global Search" },
  //   ],
  //   { enabled: !!search_string, staleTime: 1000 * 60 * 5 }
  // );

  const {
    selectColumn,
    selected,
    selectedId,
    setSelected,
  } = useSelectionColumn("entity_id");

  useEffect(() => {
    setSelected({ entity_id: null });
  }, [search_string, setSelected]);

  const { rawOptions, isLoading } = useGridOptions(selected?.screen_id);

  const {
    invoicePdfOptions,
    Component: InvoicePlaceholder,
    isLoading: isLoadingInvoiceOpts,
  } = useInvoicePdfOptions(rawOptions, selectedId, selected.entity_name);

  const batch_id = selected.entity_id;
  const batch_type =
    selected.entity_type === "Transactions - IN"
      ? "Receivable"
      : selected.entity_type === "Transactions - OUT"
      ? "Payable"
      : "";

  const {
    voucherPdfOptions,
    Component: VocuherPlaceholder,
    isLoading: isLoadingVoucherOpts,
  } = useVoucherPdfOptions(
    rawOptions,
    { batch_id, batch_type },
    selected.entity_name
  );

  const linkOptions = useLinkOptions(selectedId, rawOptions);

  return (
    <>
      <InvoicePlaceholder />
      <VocuherPlaceholder />
      {/* <FormHeading>Global Search</FormHeading> */}
      <ReadOnlyGrid
        title="Global Search Results"
        data={data}
        loading={
          isFetching ||
          isLoading ||
          isLoadingInvoiceOpts ||
          isLoadingVoucherOpts
        }
        onRowClick={(event, row) => {
          // console.log(rowData);
           event.stopPropagation();
           const path = EntityPaths[row.entity_type];
           const str=row.entity_type.toLowerCase()
           const str1=row.entity_type=="Sales Invoice" || row.entity_type=="Purchase Invoice"
           ?"act_entity_id":str+"_entity_id"
              
              if (path) {
                history.push(`${path=="/organization/product"?"/organization/product/product":path}/view`,
                 { [str1]: row.entity_id })
                    }
         }}
         options={{  paging: false,selection: false, header: false }}
        columns={[
          //selectColumn,
          // { title: "Entity Type", field: "entity_type" },
          { title: "Entity Name", field: "entity_name",
          render: (row) => <>
          <span style={{ fontWeight: "bolder" }}>
            {row.entity_name} <br />
            <span style={{ color: "rgb(118, 118, 118)", fontWeight: "normal" }}>
              {row.entity_type}
            </span> </span>
        </>
         },

          {
            title: "Created",
            field: "create_date",
            render: (row) =>  <span style={{ color: "rgb(118, 118, 118)", fontWeight: "normal" }}>
              {formatDate(row.create_date, "date")}</span>,
          },
          
          // {
          //   title: "Action",
          //   field: "entity_type",
          //   width: 120,
          //   align: "center",
          //   render: (row) => {
          //     const path = EntityPaths[row.entity_type];
              
          //     if (path) {
          //       console.log(path)
          //       return (
          //         <IconButton
          //           color="default"
          //           onClick={() =>
          //             history.push(`${path=="/organization/product"?"/organization/product/product":path}/edit`, { editId: row.entity_id })
          //           }
          //         >
          //           <EditIcon />
          //         </IconButton>
          //       );
          //     } else {
          //       return null;
          //     }
          //   },
          // },
        ]}
        toolbarProps={{
          showAdd: false,
          extraOptions: [
            ...voucherPdfOptions,
            ...invoicePdfOptions,
            ...linkOptions,
          ],
        }}
      />
    </>
  );
}
