import React from "react";
import { useField } from "formik";
import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    display: "block",
  },
  helperText: {
    color: theme.palette.error.main,
  },
}));

export default function FMCheckbox(props) {
  const [field, meta, helpers] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : " ";
  const classes = useStyles();

  const handleChange = React.useCallback(
    (e) => helpers.setValue(e.target.checked),
    [helpers]
  );

  return (
    <>
      <FormControlLabel
        labelPlacement="end"
        control={
          <Checkbox
            name={field.name}
            color="primary"
            checked={!!field.value}
            onChange={handleChange}
          />
        }
        {...props}
      />
      {!!errorText.trim() ? (
        <FormHelperText className={classes.helperText}>
          {errorText}
        </FormHelperText>
      ) : null}
    </>
  );
}
