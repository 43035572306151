import React, { useState } from "react";
import { Checkbox } from "@material-ui/core";

export default function useSelectionColumn(
	idKey = "act_entity_id",
	isMultiSelection = false,
	config = { disabled: false, }
) {
	const [selected, setSelected] = useState({ [idKey]: null });
	const [selectedIds, setSelectedIds] = useState([]);
	const selectedId = selected[idKey];
	const selectColumn = {
		title: "",
		field: idKey,
		searchable: false,
		width: 20,
		editable:"never",
		render: (row) => (
			<Checkbox
				disabled={config.disabled}
				size="small"
				color="primary"
				checked={isMultiSelection ? selectedIds.includes(row) : selectedId === row[idKey]}
				onChange={(event) => {
					if (!isMultiSelection) setSelected({ ...row });
					else {
						if (event.target.checked) {
							setSelectedIds([...selectedIds, row])
						}
						else {
							let filtered = selectedIds.filter(item => item !== row)
							setSelectedIds(filtered)
						}
					}
				}}
			/>
		),
	};

	return {
		selected,
		selectedId,
		setSelected,
		selectColumn,
		selectedIds, isMultiSelection,
		isSelected: selectedId !== null,
	};
}
