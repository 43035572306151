import React from "react";
import { useSnackbar } from "notistack";
import { ReactQueryConfigProvider } from "react-query";
import { useTheme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/CancelOutlined";
import { getApiError, getApiSuccess } from "./helpers";
import axios from "./axios";
import useLogout from "../components/Hooks/useLogout";

async function defaultQueryFn(path, headers = {}) {
  const { data } = await axios.get(path, { headers });
  return data.data0;
}

function Action({ onClose }) {
  const theme = useTheme();

  return (
    <IconButton
      size="small"
      style={{ color: theme.palette.error.contrastText }}
      onClick={onClose}
    >
      <CancelIcon />
    </IconButton>
  );
}

export default function ReactQueryConfig({ children }) {
  const handleLogout = useLogout();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = React.useCallback((key) => () => closeSnackbar(key), [
    closeSnackbar,
  ]);

  const handleError = (error) => {
    const parsedErr = getApiError(error);
    if (
      parsedErr.toLowerCase().includes("invalid credentials") ||
      parsedErr.toLowerCase().includes("token expired")
    ) {
      handleLogout();
    }
    enqueueSnackbar(parsedErr, {
      autoHideDuration: null,
      variant: "error",
      action: (key) => <Action onClose={handleClose(key)} />,
    });
  };

  return (
    <ReactQueryConfigProvider
      config={{
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
          onError: handleError,
          queryFn: defaultQueryFn,
        },
        mutations: {
          onSettled: (data, err) => {
            console.log("dataaaaaaaa"+err+data)
            if (data && !err) {
              const parsedMsg = getApiSuccess(data);
              enqueueSnackbar(parsedMsg, {
                variant: "success",
                autoHideDuration: 5000,
              });
            } else if (!data && err) {
              handleError(err);
            }
          },
          onError: (data, err) => {
            console.log("zooooooooooooooo"+JSON.stringify(err)+data)
            if (data && !err) {
              const parsedMsg = getApiSuccess(data);
              enqueueSnackbar(parsedMsg, {
                variant: "success",
                autoHideDuration: 5000,
              });
            } else if (!data && err) {
              handleError(err);
            }
          },
        },
      }}
    >
      {children}
    </ReactQueryConfigProvider>
  );
}
