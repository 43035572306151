import React from "react";
import * as Yup from "yup";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import AuthHeading from "../Common/AuthHeading";
import TansyButton from "../../Common/TansyButton";
import { FMTextField } from "../../Form";
import { forgotPassword } from "../Common/authQueries";
import ApiErrorAlert from "../../Common/ApiErrorAlert";

const ForgotPasswordSchema = Yup.object().shape({
  login_id: Yup.string().required("Plese enter a user id"),
});

export default function ForgotPasswordForm() {
  const history = useHistory();
  const [mutate, { error, isLoading }] = useMutation(forgotPassword, {
    onSuccess: (data, { domain_name }) => {
      history.push(`/otp`, { oparams: data.api_oparams, domain_name });
    },
    onSettled: () => null,
  });

  return (
    <>
      <AuthHeading>Reset Password</AuthHeading>
      <Formik
        initialValues={{ login_id: "" }}
        onSubmit={(values) => mutate({ domain_name: values.login_id })}
        validationSchema={ForgotPasswordSchema}
      >
        {(fkProps) => (
          <Form>
            <Box my={2}>
              <ApiErrorAlert error={error} loading={isLoading} />
            </Box>
            <Box my={2}>
              <FMTextField
                size="small"
                name="login_id"
                label="Enter Registered User Id"
                variant="outlined"
              />
            </Box>
            <Box my={2}>
              <TansyButton
                disabled={fkProps.isSubmitting}
                margin={0}
                type="submit"
                fullWidth
              >
                Reset
              </TansyButton>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
