import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GridCSVExport from "./GridCSVExport";

export default function GridOptions({
  columns,
  data,
  fileName,
  extraOptions = [],
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        color="primary"
        endIcon={<MoreVertIcon />}
        onClick={handleClick}
      >
      
      </Button>
      <Menu
        id="grid-export"
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <GridCSVExport filename={fileName} columns={columns} data={data} />
        </MenuItem>
        {extraOptions.map((e) => (
          <MenuItem disabled={e.disabled} key={e.text} onClick={e.onClick}>
            {e.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
