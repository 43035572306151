import React from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

export const DialogBox = styled.div`
  > * {
    margin-bottom: 16px;
    margin-top: 0;
  }
`;

export default function TansyDialog({
  open,
  onClose,
  title,
  children,
  actions,
  ...restProps
}) {
  return (
    <Dialog open={open} onClose={onClose} {...restProps}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>{title}</Box>
          <Box>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions style={{ marginBottom: 16 }}>{actions}</DialogActions>
    </Dialog>
  );
}
