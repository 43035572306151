import React from "react";
import { Button, useTheme } from "@material-ui/core";

export default function TansyButton({
  margin = [0, 1],
  children,
  ...restProps
}) {
  const theme = useTheme();

  const spacing = Array.isArray(margin) ? margin : [margin];

  return (
    <Button
      style={{ margin: theme.spacing(...spacing) }}
      color="primary"
      variant="contained"
      disableElevation
      {...restProps}
    >
      {children}
    </Button>
  );
}
