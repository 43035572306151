import { useQuery } from "react-query";
import axios5 from "../../../lib/axios5";
import React, { useEffect } from "react";
import { axios } from "../../../lib";

async function getModuleScreens(key, moduleId) {
  if (typeof moduleId !== "number" || moduleId < 1) {
    return [];
  }
  const { data } = await axios5.get("/api/module-launch-list", {
    headers: {
      module_id: moduleId,
    },
  });
  return data;
}
export async function getModuleAPI() {
  console.log("getModuleAPI.jsx "+ localStorage.getItem("module_api_path"))
  return await localStorage.getItem("module_api_path")
}
export async function getSettingAPI() {
  console.log("getSettingAPI.jsx "+ localStorage.getItem("settings_api_path"))
  return await localStorage.getItem("settings_api_path")
}
export async function getSalesAPI() {
  console.log("getSalesAPI.jsx "+ localStorage.getItem("sales_api_path"))
  return await localStorage.getItem("sales_api_path")
}
export async function getLookupAPI() {
  console.log("getLookupAPI.jsx "+ localStorage.getItem("lookup_api_path"))
  return await localStorage.getItem("lookup_api_path")
}
export async function getDocumentAPI() {
  console.log("getDocumentAPI.jsx "+ localStorage.getItem("document_api_path"))
  return await localStorage.getItem("document_api_path")
}
export async function getCommunicationAPI() {
  console.log("getCommunicationAPI.jsx "+ localStorage.getItem("communication_api_path"))
  return await localStorage.getItem("communication_api_path")
}
export async function getAuthAPI() {
  console.log("getAuthAPI.jsx "+ localStorage.getItem("auth_api_path"))
  return await localStorage.getItem("auth_api_path")
}
export async function getReportsAPI() {
  console.log("getReportsAPI.jsx "+ localStorage.getItem("reports_api_path"))
  return await localStorage.getItem("reports_api_path")
}
export default function useModuleScreens(moduleId) {
  localStorage.setItem("current_module_id",moduleId );
  
  localStorage.setItem("settings_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_name == "settings").map(mode => 
    mode.module_api_path === undefined?process.env.REACT_APP_HOST:mode.module_api_path

    )
  );
  localStorage.setItem("sales_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_name == "sales").map(mode => 
    mode.module_api_path === undefined?process.env.REACT_APP_HOST:mode.module_api_path

    )
  );
  //console.log("getSettingAPI.jsx "+ localStorage.getItem("settings_api_path"))
  localStorage.setItem("module_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_id == localStorage.getItem("current_module_id")).map(mode => 
    mode.module_api_path === undefined?process.env.REACT_APP_HOST:mode.module_api_path

    )
  );
  localStorage.setItem("lookup_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_id == localStorage.getItem("current_module_id")).map(mode => 
    mode.lookup_api_path === undefined?process.env.REACT_APP_HOST:mode.lookup_api_path

    )
  );
  localStorage.setItem("communication_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_id == localStorage.getItem("current_module_id")).map(mode => 
    mode.communication_api_path === undefined?process.env.REACT_APP_HOST:mode.communication_api_path

    )
  );
  localStorage.setItem("document_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_id == localStorage.getItem("current_module_id")).map(mode => 
    mode.document_api_path === undefined?process.env.REACT_APP_HOST:mode.document_api_path

    )
  );
  localStorage.setItem("auth_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_id == localStorage.getItem("current_module_id")).map(mode => 
    mode.auth_api_path === undefined?process.env.REACT_APP_HOST:mode.auth_api_path

    )
  );
  localStorage.setItem("reports_api_path",
  JSON.parse(localStorage.getItem("menu")).filter(mode => mode.module_id == localStorage.getItem("current_module_id")).map(mode => 
    mode.reports_api_path === undefined?process.env.REACT_APP_HOST:mode.reports_api_path

    )
  );
//console.log("useModuleSreens.jsx"+ localStorage.getItem("module_api_path"))
  const { data, status, error } = useQuery(
    ["moduleScreens", moduleId],
    getModuleScreens,
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );
 
  const screens = data
    ? Array.isArray(data.data0)
      ? data.data0.map((e) => ({
          ...e,
          screen_link: e.screen_link.toLowerCase(),
        }))
      : data.data0
    : [];
    useEffect(() => {
      localStorage.setItem("current_module_id",moduleId );
    }, [moduleId]); 
  return { screens, status, error };
}
