import React from "react";
import { Box } from "@material-ui/core";
import { useFormikContext } from "formik";

export default function FMDebug() {
  const { values, errors } = useFormikContext();

  return (
    <Box mx="auto" bgcolor="white" padding="4em">
      <h2>Values</h2>
      <pre>{JSON.stringify(values, null, 2)}</pre>
      <h2>Errors</h2>
      <pre>{JSON.stringify(errors, null, 2)}</pre>
    </Box>
  );
}
