import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useAuthDispatch } from "../Context/AuthContext";
import axios from "../../../lib/axiosLogin";
import { useSnackbar } from "notistack";

async function loginUser(credentials) {
  
  const { data } = await axios.post("/api/v1/login1", credentials);
  return data;
}

export default function useLogin() {
  const history = useHistory();
  const authDispatch = useAuthDispatch();

  const { closeSnackbar } = useSnackbar();

  const mutation = useMutation(loginUser, {
    onSuccess: (data) => {
      const defaultModuleId = data.Sproc_OParams.oparam_login_launch_module_id;
      const defaultModule = data.Menu_Data.find(
        (m) => m.module_id === defaultModuleId
      );

      const redirectPath = defaultModule
        ? `/${defaultModule.module_name.toLowerCase()}`
        : "/";

      authDispatch({ type: "login", payload: data });
      closeSnackbar();
      history.push(redirectPath);
    },
    onSettled: () => null,
  });

  return mutation;
}
