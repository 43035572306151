import React from "react";
import { Formik } from "formik";
import { useMutation } from "react-query";
import { Box, Button, withStyles } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useScreenId } from "../../Routers";
import { FMAutoCompletePlain } from "../../Form";
import { deleteFilter } from "./filterQueries";
import useFiltersLookup from "./useFiltersLookup";

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    fontSize: 11,
    textTransform: "capitalize",
  },
}))(Button);

export default function FiltersDropdown({ onFilterSelect }) {
  const screen_id = useScreenId();

  const { lookup, isFetching, refetch } = useFiltersLookup(screen_id);

  const [del, { isLoading: isDeleting }] = useMutation(deleteFilter, {
    onSuccess: (data, vars) => {
      vars.resetForm();
      refetch();
    },
  });

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      <Formik
        initialValues={{ filter: null, filter_name: "" }}
        onSubmit={(vals, helpers) =>
          del({ ...vals, resetForm: helpers.resetForm })
        }
      >
        {(fkProps) => {
          const handleFilterSelect = (event, filter) => {
            if (!filter) {
              fkProps.setFieldValue("filter", "");
              fkProps.setFieldValue("filter_name", null);
            } else {
              fkProps.setFieldValue("filter", filter);
              fkProps.setFieldValue("filter_name", filter.filter_name);
              onFilterSelect(filter.filter_values);
            }
          };

          return (
            <>
              <Box width="60%">
                <FMAutoCompletePlain
                  disabled={lookup.length === 0}
                  disableClearable={false}
                  label="Load Saved Filter"
                  name="filter"
                  textField="filter_name"
                  dataItemKey="filter_name"
                  options={lookup}
                  loading={isFetching || isDeleting}
                  onChange={handleFilterSelect}
                />
              </Box>

              <DeleteButton
                variant="text"
                disabled={
                  !fkProps.values.filter_name || isDeleting || isFetching
                }
                onClick={fkProps.handleSubmit}
                startIcon={<DeleteOutlineIcon />}
              >
                Delete Filter
              </DeleteButton>
            </>
          );
        }}
      </Formik>
    </Box>
  );
}
