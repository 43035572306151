import React from "react";
import FMSelectField from "./FMSelectField";
import useLookup from "../Hooks/useLookup";

export default function FMLookupField({
  lookupType,
  lookupNameAsValue = false,
  onLookupLoad,
  ...restProps
}) {
  const { lookup, isLoading } = useLookup(
    lookupType,
    lookupNameAsValue,
    onLookupLoad
  );

  return (
    <FMSelectField
      loading={isLoading}
      lookup={lookup} // pass the lookup data for custom use cases
      options={lookup}
      {...restProps}
    />
  );
}
