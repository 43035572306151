import React from "react";
import { useTheme } from "@material-ui/core";

export default function ScreenWrap({ children, padding = [2, 4, 6, 4] }) {
  const theme = useTheme();
  return (
    <div
      style={{
        background: theme.palette.background.paper,
        padding: theme.spacing(...padding),
      }}
    >
      {children}
    </div>
  );
}
