import React from "react";
import { makeStyles, Toolbar, Box } from "@material-ui/core";
import AddButton from "../Common/AddButton";
import GridSearch from "./GridSearch";
import GridOptions from "./GridOptions";
import GridFilterButton from "./GridFilterButton";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    minHeight: 0,
  },
}));

export default function GridToolbar({
  onAdd,
  onFilter,
  isFiltered,
  searchText,
  dataManager,
  onSearchChanged,
  addDisabled = false,
  showAdd = true,
  showExport = true,
  showSearch = true,
  extraOptions,
  children,
  ...restProps
}) {
  const classes = useStyles();
  const [text, setText] = React.useState(searchText);

  const handleSearchChange = (newText) => {
    dataManager.changeSearchText(newText);
    setText(newText);
    onSearchChanged(newText);
  };
 

  return (
    <Toolbar className={classes.toolbar}>
      {showAdd ? <AddButton onClick={onAdd} disabled={addDisabled} /> : null}
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        {onFilter ? (
          <GridFilterButton
            handleClick={onFilter}
            color={isFiltered ? "primary" : "default"}
          />
        ) : null}
        {showSearch ? (
          <GridSearch onSearchChange={handleSearchChange} searchValue={text} />
        ) : null}
        {showExport ? (
          <GridOptions
            fileName={restProps.exportFileName || restProps.title}
            columns={restProps.columns}
            data={restProps.data}
            extraOptions={extraOptions}
          />
        ) : null}
        {children}
      </Box>
    </Toolbar>
  );
}
