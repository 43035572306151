import React from "react";
import GlobalSearchResult from "./GlobalSearchResult";
import { ScreenWrap, SectionWrap } from "../../Common";

export default function SearchPage() {
  return (
    <SectionWrap>
      <ScreenWrap>
        <GlobalSearchResult />
      </ScreenWrap>
    </SectionWrap>
  );
}
