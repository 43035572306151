import React from "react";
import { Grid } from "@material-ui/core";
import { FMTextField } from "../../Form";
import SalesSubTotal from "./SalesSubTotal";
import { useFormikContext } from "formik";

export default function SalesFooter({ disabled = false }) {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={5}>
        <FMTextField
          label="Remarks"
          name="description"
          multiline
          rows={6}
        />
      </Grid>
      <Grid item lg={2}></Grid>
      <Grid item xs={12} lg={5} xl={4}>
        <SalesSubTotal
          values={values}
          disabled={disabled}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </Grid>
  );
}
