import React from "react";
import { useFormikContext } from "formik";
import { formatters } from "../../../lib";
import { FMAutoComplete, FMCheckbox } from "../../Form";

const { formatPhone } = formatters;

const renderOption = (opt) =>
  `${opt.entity_name}, +${opt.mobile_phone_country_code}${opt.mobile_phone}, ${opt.email}`;

export default function SalesClientField({
  lookupType,
  disabled,
  disableClient = false,
}) {
  const { values, setFieldValue } = useFormikContext();
  const { client, client_entity_id } = values;

  const {
    sms_phone,
    sms_phone_country_code,
    whatsapp_phone,
    whatsapp_phone_country_code,
    email,
  } = values.client || {};

  const smsNum = formatPhone(sms_phone_country_code, sms_phone);
  const whatsAppNum = formatPhone(whatsapp_phone_country_code, whatsapp_phone);

  const onLookupLoad = (lookup) => {
    if (client_entity_id && !client) {
      const found = lookup.find((e) => e.entity_id === client_entity_id);
      if (found) setFieldValue("client", found);
    }
  };

  return (
    <>
      <FMAutoComplete
        disabled={disabled || disableClient}
        disableClearable
        name="client_entity_id"
        label="Client *"
        lookupType={lookupType}
        value={values.client}
        onChange={(_event, selected) => {
          setFieldValue("client", selected);
          setFieldValue("client_entity_id", selected.entity_id);
        }}
        renderOption={renderOption}
        filterOptions={{ stringify: renderOption }}
        onLookupLoad={onLookupLoad}
      />
      <FMCheckbox
        disabled={disabled}
        name="send_sms_flag"
        label={`Send Sms ${smsNum ? `(${smsNum})` : ""}`}
      />
      <br />
      <FMCheckbox
        disabled={disabled}
        name="send_whatsapp_flag"
        label={`Send WhatsApp ${whatsAppNum ? `(${whatsAppNum})` : ""}`}
      />
      <br />
      {email ? (
        <>
          <FMCheckbox
            disabled={disabled}
            name="send_email_flag"
            label={`Send Email (${email})`}
          />
          <br />
        </>
      ) : null}
    </>
  );
}
