import React from "react";
import BaseTable from "../../Common/BaseTable";
import GridToolbar from "../GridToolbar";

export default function ReadOnlyGrid({
  data,
  loading,
  columns,
  toolbarProps = {},
  components = {},
  ...restProps
}) {
  return (
    <BaseTable
      components={{
        Toolbar: (props) => <GridToolbar {...props} {...toolbarProps} />,
        ...components,
      }}
      data={data}
      columns={columns}
      isLoading={loading}
      {...restProps}
    />
  );
}
